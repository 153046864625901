@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#security-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#security-page {
  min-width: 100%;
  padding-bottom: 20px;
  .security-page-sections-container-enabled {
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .selected-methods {
      font-weight: 500;
      font-size: 14px;
    }
    .two-factor-authentation-method {
      padding: 12px;
    }

    .security-feature-divider {
      margin-block: 16px;
    }
    .feature-details {
      display: flex;
      flex-direction: row;
      gap: 30px;
      align-items: center;
      justify-content: space-between;
      .feature-buttons {
        display: flex;
        gap: 10px;
        justify-content: center;
      }
      .details-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .feature-name {
          color: rgba(0, 0, 0, 0.87);
          font-weight: 500;
          font-size: 16px;
        }
        .description-container {
          .description-details {
            font-weight: 400;
            font-size: 14px;
            color: #00000099;
          }
        }
      }
    }
    .remember-me {
      display: flex;
      align-items: center;
      gap: 10px;
      .remember-me-title {
        font-weight: 500;
        font-size: 14px;
      }
    }
    .remember-me-desc-feature-enabled {
      font-weight: 400;
      color: #00000099;
      font-size: 14px;
    }
  }
  .security-page-sections-container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    p.Mui-error {
      @include ar {
        text-align: start;
      }
    }
    .security-page-section-box {
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid #e2e8f0;
      border-radius: 6px;
      padding: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      gap: 20px;

      .section-description {
        font-size: 14px;
        color: #0009;
      }
      .input-label {
        color: rgba(0, 0, 0, 0.87);
        font-weight: 400;
        font-size: 14px;
        margin: 10px 0px;
      }

      .details-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .feature-name {
          color: rgba(0, 0, 0, 0.87);
          font-weight: 500;
        }
        .not-active-feature {
          font-size: small;
          font-weight: 500;
          background-color: #fdf0e6;
          color: #c77700;
          height: 20px;
          .MuiChip-label {
            padding-inline: 5px;
          }
          margin-left: 8px;
          @include ar {
            margin-right: 8px;
          }
        }
        .description-container {
          display: flex;
          align-items: center;
          gap: 24px;
          .description-details {
            display: flex;
            align-items: center;
            gap: 5px;
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }
}

.unblock-email-popup {
  .MuiDialog-paper {
    max-width: 602px;
  }
  .MuiDialogContent-root {
    max-height: 364px;
  }
  .MuiDialogActions-root {
    padding: 16px 24px 24px 24px;
  }
}

#two-factor-authentication-popup {
  .snackbar-for-security-page {
    width: 320px;
  }
  .two-factor-authentation-method {
    display: flex;
    padding: 12px;
    .MuiCheckbox-root {
      top: -9px;
      align-items: start;
    }
    .request-btn {
      font-weight: 500;
      margin-inline-start: auto;
      size: small;
      &.requested.Mui-disabled {
        opacity: 100%;
        color: #3b873e;
      }
    }
  }
  .security-desc {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 16px;
  }

  .remember-me {
    display: flex;
    align-items: center;
    margin-inline-start: 12px;
    margin-top: 16px;
    .remember-me-title {
      font-weight: 500;
    }
  }
  .remember-me-desc {
    margin-inline-start: 54px;
    font-weight: 400;
  }
}

.feature-status-chip.MuiChip-root {
  margin-left: 8px;
  text-transform: capitalize;
  @include ar {
    margin-right: 8px;
  }
  font-size: small;
  font-weight: 500;
  height: 20px;
  .MuiChip-label {
    padding-inline: 5px;
  }
  &.active_feature,
  &.paid_two {
    background-color: #edf7ed;
    color: #3b873e;
  }
  &.inactive {
    background-color: #efefef;
    color: #666666;
  }
}

.unblock-email-popup-paper {
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;

  &.reasons-paper {
    background-color: #fef7f1;
  }
  &.suggested-paper {
    background-color: #f4faf4;
  }

  .paper-title {
    margin-bottom: 10px;
    font-weight: 600;
  }
  .paper-list {
    list-style-type: disc;
    padding-left: 20px;
    .paper-list-item {
      display: list-item;
      padding-bottom: 0px;
      .paper-list-item-text {
        padding-left: 0px;
        margin: 0px -10px;
      }
    }
  }
}
