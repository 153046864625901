@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.theme-tab {
  display: inline-block;
  cursor: pointer;
  clear: both;
  margin-bottom: 20px;
  @include en {
    text-align: left;
  }
  @include ar {
    text-align: right;
  }
  border-bottom: 1px solid #eaeaea;
  span {
    color: #2c2e31;
    display: inline-block;
    font-size: 12px;
    padding: 10px 10px;
    font-weight: 400 !important;
    min-width: 100px;
    text-align: center;
    transition: 0.3s;
    &.active,
    &:hover {
      color: #009cdc !important;
      border-bottom: 3px solid #009cdc;
    }
    &.active {
      font-weight: 500 !important;
    }
  }
}

#themes_trend {
  .echarts-for-react {
    margin-top: 15px;
  }
}

#themes-trend-widget-themes-tabs {
  margin-bottom: 20px;
  .MuiTabScrollButton-root.Mui-disabled {
    display: none;
  }
  .MuiButtonBase-root.MuiTab-root {
    border-bottom: 1px solid #f1f5f9;
    &.Mui-selected {
      border-bottom: 3px solid #456eb2;
    }
  }
}
