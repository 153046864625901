@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.MuiContainer-root.no-data-details-container {
  padding: 20px !important;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.no-data-details-wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-grow: 1;
  .pagination-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    color: #000000;
    font-size: 15px;
    font-weight: 500;
    margin: 20px 0;
    svg {
      color: #456eb2;
      width: 36px;
      height: 36px;
    }
  }
  .no-data-text {
    max-width: 236.66px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.animate-data-text {
      -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
      animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }
    .user-avatar-status-indicator {
      width: 14px;
      height: 14px;
      border: 6px solid #fff;
    }
    .user-avatar-animation-container {
      position: relative;

      .success-assign-animation {
        position: absolute;
        bottom: -20px;
        right: 0;
        left: 50%;
        width: 35.5px;
        height: 35.5px;
        background-color: #fff;
        transform: translateX(-50%);
        border-radius: 50%;
        @include ar {
          transform: translateX(50%);
          right: 50%;
          left: 0;
        }
        .no-data-engagement-available-animation {
          max-width: 100%;
          max-height: 100%;
          border-radius: 50%;
        }
      }
    }
  }
  .text-heading {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #1e293b;
    margin: 30px 0;
    &.available {
      @include ar {
        width: 212px;
      }
    }
    .text-heading-title {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 6px;
    }
  }
  .text-description {
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
  }
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
