@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.complete-body {
  > div {
    width: 100%;
  }
  .check-survey-engag {
    hr {
      margin-bottom: 7px;
      border-color: #e4e4e4 !important;
    }
    label {
      padding: 0 8px;
      @include ar {
        margin: 0;
      }
      .MuiFormControlLabel-label {
        font-size: 14px;
        font-weight: 400;
        color: #000000de;
      }
    }
  }
  .engagement-marked {
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
    font-family: "Figtree" !important;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    @include ar {
      font-family: "IBM Plex Sans Arabic", sans-serif !important;
    }
  }
  .radio-buttons-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 14px;
    padding-top: 16px;
    margin-bottom: 16px;
    .item-box {
      background: #fff;
      color: rgba(0, 0, 0, 0.38);
      font-size: 14px;
      font-family: "Figtree" !important;
      @include ar {
        font-family: "IBM Plex Sans Arabic", sans-serif !important;
      }
      font-weight: 600;
      line-height: 24px;
      min-width: 170px;
      label {
        margin: 0;
      }
      svg {
        margin-inline-end: 5px;
        color: #626cab;
      }
    }
  }
  .MuiTypography-root.MuiFormControlLabel-label {
    display: flex;
  }
  .item-check {
    width: 100%;
    height: 100%;
    > span {
      width: 100%;
      height: 100%;
    }
    .Mui-checked ~ span.MuiFormControlLabel-label {
      background: rgba(230, 243, 252, 0.5);
      border: 1px solid #456eb2;
      .tooltip-btn {
        color: #456eb2 !important;
      }
    }
    span.MuiFormControlLabel-label {
      border: 1px solid rgba(0, 0, 0, 0.23);
      height: max-content;
      border-radius: 4px;
      .tooltip-btn {
        color: rgba(0, 0, 0, 0.6) !important;
        font-family: "Figtree";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.15px;
        @include ar {
          font-family: "IBM Plex Sans Arabic", sans-serif !important;
        }
      }
    }
  }
  .item-content.MuiBox-root {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px 14px;
  }
  .radio-icon {
    display: none;
  }
  .radio-button {
    text-align: start;

    span {
      width: 100%;
    }
  }
  .tooltip-btn {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.38);
  }
  .type-reason {
    margin-bottom: 16px;
    width: 100%;
    max-width: 100%;
    border-radius: 4px;
    border-color: #456eb2;
    height: 38px;
    @include ar {
      label {
        transform: translate(440px, 10px) scale(1);
        &.Mui-focused,
        &.MuiFormLabel-filled {
          transform: translate(465px, -11px) scale(0.75);
          background: #ffffff;
          padding: 3px;
          display: block;
          font-size: 12px;
        }
      }
      fieldset {
        legend {
          span {
            display: none;
          }
        }
      }
    }
    @include en {
      label {
        transform: translate(9px, 10px) scale(1);
        &.Mui-focused,
        &.MuiFormLabel-filled {
          transform: translate(8px, -12px) scale(0.75);
          background: #ffffff;
          padding: 3px;
          display: block;
        }
      }
      fieldset {
        legend {
          span {
            display: none;
          }
        }
      }
    }
    label.MuiFormLabel-root.MuiInputLabel-root {
      font-size: 14px;
      font-family: "Figtree" !important;
      @include ar {
        font-family: "IBM Plex Sans Arabic", sans-serif !important;
      }
    }
    .MuiInputLabel-shrinklabel.MuiFormLabel-root {
      color: red;
      top: 0;
    }
    .MuiInputLabel-outlined.Mui-focused {
      top: 0px !important;
    }
    input {
      padding: 8.5px 0px;
    }
  }
  .MuiInputBase-root.MuiOutlinedInput-root {
    padding-inline-end: 4px;
    @include en {
      padding-inline-end: 8px !important;
    }
    padding-inline-start: 12px;
  }
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-error {
    @include ar {
      padding-inline-end: 12px !important;
    }
  }
  .input-adornment {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    @include ar {
      direction: ltr;
    }
  }

  .err-msg {
    color: #f44336 !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin: 0;
    text-align: start !important;
  }
}

#alert-dialog-description p {
  font-family: "Figtree" !important;
  @include ar {
    font-family: "IBM Plex Sans Arabic", sans-serif !important;
  }
  text-align: start !important;
}

.reason-completion-pop-up {
  padding: 24px;
  > div {
    padding: 0;
  }
  h2.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root {
    padding: 0 !important;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Figtree" !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.15px;
    margin-bottom: 15px !important;
    @include ar {
      font-family: "IBM Plex Sans Arabic", sans-serif !important;
    }
  }
}

#radio-buttons-box {
  margin-bottom: -7px !important;
}

.actions-buttons-shared {
  #engagement-complete-popup-right-btn {
    @include ar {
      margin-left: 0;
    }
  }
}
