@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
@mixin falcon-env {
  html[env="falcon"] & {
    @content;
  }
}

.gretting-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -200px;
}
.gretting-wrapper-inactive {
  @extend .gretting-wrapper;
  margin-top: -150px;
}
.gretting-header {
  &.MuiBox-root {
    background: #ffffff;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin-inline: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
  }
}

.gretting-logo {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  margin-bottom: 15px;
  background: #ffffff;
  img {
    animation: imgScale 2s ease alternate infinite;
    @include falcon-env {
      width: 48px;
      height: 48px;
    }
  }
}
.gretting-header {
  color: #212121;
  font-weight: 400;
  font-size: 14px;
  span {
    font-weight: 600;
  }
  .gretting-hello {
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .luci-filter-applied {
    font-weight: 500;
    font-size: 14px;
    color: #456eb2;
    text-decoration: underline;
    cursor: pointer;
  }
}

@keyframes hithere {
  30% {
    transform: scale(1.1);
  }
  40%,
  60% {
    transform: rotate(-20deg) scale(1.1);
  }
  50% {
    transform: rotate(20deg) scale(1.1);
  }
  70% {
    transform: rotate(0deg) scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.gretting-hand {
  padding-inline: 5px;
}

.getting-details {
  line-height: 1.5;
  margin-top: 20px;
  span {
    display: inline-block;
    @include en {
      margin-right: 3px;
    }
    @include ar {
      margin-left: 3px;
    }
    &.luci-data-from {
      font-weight: 400;
    }
  }
}

@keyframes imgScale {
  90% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
