.refresh-rate-alert{
    display: flex;
    padding: 14px 16px;
    margin-block: 20px;
    justify-content: space-between;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid #FCEAC5;
    background: #FDF0E6;
      .refresh-rate-alert-icon{
        color:#ED6C02;
        font-size: 24px;
      }
      .refresh-rate-alert-messege{
        color: #5F2B01;
        font-size: 14px;
        flex-grow: 1; // Ensures text takes up available space
      }
      .close-icon {
        cursor: pointer;
        color: #5F2B01;
        font-size: 20px;
    }
    }