//functions sass
@mixin display-flex-function($flex, $item-center, $justify) {
  display: $flex;
  align-items: $item-center;
  justify-content: $justify;
}
//@include display-flex-function(flex, center, flex-start);
@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
@mixin falcon-env {
  html[env="falcon"] & {
    @content;
  }
}
.luci-policy-parant {
  position: relative;
  .side-bar-head {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
  }
}
.luci-policy {
  padding: 40px;
  font-size: 16px;
  font-weight: 500px;
  .luci-policy-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 30px;
  }
  .luci-paragraph-policy {
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
  }
  .luci-paragraph-policy,
  .list-policy {
    margin-bottom: 40px;
    li {
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      a {
        color: #456eb2 !important;
        white-space: nowrap;
      }
    }
  }

  .list-policy li:first-child {
    margin-bottom: 20px;
  }

  .luci-form-control-lable {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
    span.MuiTypography-root {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
  .btn-accept-policy {
    height: 42px;
    width: 100%;
    background: #456eb2;
    border-radius: 2px;
    color: #fff !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    margin-top: 40px;
    &:hover {
      background: #456eb2;
    }
  }
  .accept-policy-disabled {
    background: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
  }
}
.side-bar-head {
  padding: 20px;
  background: linear-gradient(
    90deg,
    #1f2249 -12.87%,
    #262d56 9.11%,
    #394c7a 50.08%,
    #4e6d9f 87.05%
  );
  @include display-flex-function(flex, flex-start, space-between);
  @include ar {
    direction: ltr;
  }
  @include en {
    direction: rtl;
  }
  .luci-terms {
    padding: 20px;
    @include display-flex-function(flex, center, space-between);
    @include ar {
      direction: rtl;
    }
    @include en {
      direction: ltr;
    }
    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #ffffff;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: rgb(255, 255, 255, 0.8);
    }
  }
  .logo-luci-answer {
    margin-inline-end: 20px;
    background-color: #fff;
    width: 42px;
    height: 42px;
    display: inline-block;
    @include display-flex-function(flex, center, center);
    border-radius: 50%;
    img {
      @include falcon-env {
        width: 32px;
        height: 32px;
      }
    }
  }
}
