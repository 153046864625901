@mixin en {
  html[lang="en"] .monitor-table {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] .monitor-table {
    @content;
  }
}
.MuiTooltip-tooltip {
  .worning-text {
    padding: 5px;
    font-size: 13px;
  }
}
.monitor-table {
  margin-top: 24px;
  .post-used-underline {
    border-bottom: 1px dashed #cbd5e1;
  }
  .name-text-ar {
    display: flex;
    flex-direction: row-reverse;
  }
  .name-text {
    display: flex;
    align-items: center;
    @include ar {
      flex-direction: row-reverse;
    }
  }
  table.MuiTable-root thead {
    border-bottom: 1px solid #d4d8db;
    .MuiTableRow-head {
      background-color: #f1f5f9;
    }
  }
  thead {
    tr {
      height: 56px;
      th {
        font-size: 16px;
        color: #475569;
      }
    }
  }
  tr {
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
    &:nth-of-type(even) {
      background: #f8fafc;
    }
    &:nth-of-type(odd) {
      background: #fff;
    }
    td {
      padding: 10px 16px;
      color: #000;
      font-size: 14px;
      &.monitor-name,
      &.manage-account-name {
        font-weight: 600;
        color: #456eb2 !important;
        .monitor-text {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &:hover {
            .MuiBox-root {
              text-decoration: underline;
              cursor: pointer;
            }
          }
          .monitor-title {
            color: #456eb2;
          }
        }
        .monitor-error {
          display: block;
          color: #ffffff;
          background: #f44336;
          margin-top: 8px;
          padding: 8px;
          border-radius: 4px;
          margin-top: 11px;
          font-size: 12px;
          width: fit-content;
        }
      }
    }
  }

  .status {
    padding: 7px 12px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    width: auto;
    font-size: 13px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    text-transform: capitalize;
    &.collecting {
      color: #3b873e;
      border-color: rgba(76, 175, 80, 0.5);
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.9)
        ),
        #4caf50;
    }
    &.paused {
      color: #616161;
      background: rgba(0, 0, 0, 0.09);
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
    &.unapproved {
      color: #ed6c02;
      background: rgba(237, 108, 2, 0.08);
      border: 1px solid rgba(237, 108, 2, 0.5);
    }
    &.reـauthenticated {
      color: #ed6c02;
      background: rgba(237, 108, 2, 0.08);
      border: 1px solid rgba(237, 108, 2, 0.5);
    }
    &.unapproved-channel {
      color: #616161;
      background: rgba(0, 0, 0, 0.09);
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
  }
  .social {
    display: flex;
    margin-left: 16px;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-icon {
      margin: 0 8px 0 0;
    }

    .facebook-icon {
      width: 20px;
      height: 20px;
      color: #3b5998;
    }
    .twitter-icon {
      width: 20px;
      height: 20px;
      color: #1ba8e3;
    }
    .instagram-icon {
      width: 20px;
      height: 20px;
      color: #e4405f;
    }
  }

  .ellipsis-icon {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.54);
  }
  .analysis {
    display: flex;

    .present-analysis {
      border-bottom: 1px dashed #cbd5e1;
      margin-inline-start: 4px;
      &.increase {
        color: #8cc63f;
      }
      &.decrease {
        color: #e50035;
      }
    }
  }
}

.empty-item {
  width: 100%;
  height: 441px;
  background: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  h2 {
    margin: 0;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #000000;
  }
  p {
    margin: 0;
    font-size: 14px;
    line-height: 14px;
    color: #80868c;
  }
}
.manage-channel-socials {
  display: flex;
  .social-media-icon {
    display: flex;
    -webkit-margin-end: 5px;
    margin-inline-end: 10px;
    align-items: center;
  }
  .social-icon {
    height: 24px !important;
  }
}

@include ar {
  tr,
  th,
  td {
    text-align: right;
    div {
      text-align: right;
    }
  }
}
@include en {
  tr,
  th,
  td {
    text-align: left;
    div {
      text-align: left;
    }
  }
}

.gap2 {
  gap: 5px;
}
.empty-data-box {
  color: #64748b;
  max-width: 510px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  a {
    color: #456eb2;
    margin-inline-start: 3px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}
.empty-item-monitor-list {
  h3.no-data-found.MuiTypography-root {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
}

div.post-used-underline-tooltip {
  width: 200px;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  box-sizing: border-box;
}
