.tags-label-color {
  .tags-label {
    display: flex;
    align-items: center;
    padding: 4px 10px 4px;
    padding-inline-start: 8px;
    width: max-content;
    border-radius: 4px;
  }
  .tags-box-color {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    display: block;
    margin-inline-end: 8px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }
}
.table-tags-main-container {
  .custom-pagination-container {
    margin: 4px 4px 0px;
  }
  .tags-table-head.tagged-border {
    > div {
      padding-bottom: 3px;
      border-bottom: 1px dashed #94a3b8;
    }
  }
  .tags-table-head {
    span.MuiTypography-root {
      color: #475569;
      font-size: 16px;
      font-weight: 500;
      line-height: 14px;
      cursor: pointer;
    }
  }
  #shared-table {
    .MuiTableBody-root {
      > :nth-of-type(even) {
        background: #ffffff;
      }
    }
  }
}
.tag-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .engagement-tags-title-box {
    color: #1a174f;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 3px;
    p {
      color: #1a174f;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}
.options-tag-table {
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-tags-container {
  .status-tag-dec,
  p.status-tag-dec,
  .status-tag-list li {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }
  h4.status-tag-dec {
    margin-bottom: 20px;
  }
  ul {
    margin: 0 0 10px;
  }
}
#tag-table-options-menu {
  #tag-table-more-options-edit-btn,
  #tag-table-more-options-delete-btn {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 12px;
    color: rgba(0, 0, 0, 0.87);
    width: 148px;
    font-size: 14px;
    font-weight: 400;
    svg {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  #tag-table-more-options-delete-btn {
    color: #e31b0c;
    svg {
      color: #e31b0c;
    }
  }
}

#tags-table {
  .empty-table-body {
    border-bottom: 1px solid #e2e8f0;
    background: #fff;
    display: flex;
    height: 170px;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 400;
  }
}
#tag-menu-list-tooltip,
#tags-option-tooltip,
#tag-status-list-tooltip {
  .MuiTooltip-tooltip {
    margin-top: 10px;
    height: auto;
  }
  > div {
    max-width: 137px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#tag-table-head-tooltip {
  .MuiTooltip-tooltip {
    margin-top: 12px;
    height: auto;
  }

  > div {
    max-width: 150px;
    padding: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#status-switch-tags {
  width: max-content;
  .MuiButtonBase-root.MuiSwitch-switchBase.Mui-disabled {
    &:hover {
      cursor: no-drop;
      pointer-events: unset;
      background-color: transparent;
    }
  }
}
.shared-popup-tags {
  .MuiDialogActions-root.MuiDialogActions-spacing {
    padding-inline: 24px;
    padding-bottom: 24px;
  }
  min-width: 600px;
  .status-tag-dec {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .last-text {
    font-weight: 600;
  }
}
.popup-tag-delete {
  .MuiButtonBase-root.popup__rightbtn {
    background: #e31b0c;
  }
}

#shared-table.tags-table {
  margin-top: 20px;
  overflow-y: visible;
}
