@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#body-popup-two-factor-auth {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 8px;
}

.popup-to-enable-two-factor-auth {
  .MuiDialogActions-root {
    padding: 16px 24px;
  }

  .MuiPaper-root {
    //this style to show phone number codes menu list
    overflow: visible;
    .MuiDialogContent-root {
      overflow: visible;
    }
    .MuiAlert-root {
      width: 320px;
    }
  }

  @include ar {
    .popup__rightbtn {
      margin-left: 0;
    }
  }
}
