@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.main-acc-table-countainer {
  margin: 20px 0;
  .main-table-tit {
    color: #475569;
    font-size: 14px;
    margin-bottom: 12px;
    .main-table-acc-prag {
      color: #000000de;
      font-size: 12px;
      padding-top: 3px;
    }
  }
  &.engagement_features {
    padding: 30px 0;
    border-top: 1px solid #e2e8f0;
    border-bottom: 1px solid #e2e8f0;
  }
}
#engag-acc-cell-tooltip {
  max-width: 180px;
}
.main-acc-table {
  .empty-access-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
    color: #475569;
    padding-bottom: 20px;
    &.with-border {
      border-bottom: 1px solid #e2e8f0;
    }
    .empty-access-parag {
      color: #64748b;
    }

    a {
      max-width: fit-content;
    }
  }
  .main-table-container {
    border: 1px solid #e2e8f0;
    border-bottom: none;
    .acc-table-row {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e2e8f0;
      min-height: 52px;
      .acc-table-first-cell {
        width: 26%;
        height: 100%;
        background-color: #f8fafc;
        padding-inline-start: 10px;
        font-size: 13px;
        font-weight: 500;
        min-height: 52px;
        display: flex;
        align-items: center;

        .engag-acc-cell {
          display: flex;
          align-items: center;
          gap: 5px;
          svg {
            color: #bdbdbd;
            width: 15px;
            height: 15px;
            rotate: 180deg;
          }
        }
        .account-acc-cell {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 12px;
          font-weight: 500;
          color: #000000de;
          .account-acc-data-details {
            div {
              width: fit-content;
            }
          }
          .account-acc-data-handle {
            color: #00000099;
            font-weight: 400;
            width: fit-content;
          }
          .account-acc-img {
            width: 32px;
            height: 32px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .account-acc-data-source {
              position: absolute;
              width: 16px;
              height: 16px;
              bottom: -5px;
              right: -5px;
              background-color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              @include ar {
                right: unset;
                left: -5px;
              }
              .social-media-icon {
                margin: 0;
                width: 70%;
                height: 70%;
                svg {
                  width: 100%;
                  height: 100%;
                }
                img {
                  border-radius: 0;
                }
              }
              &.WHATSAPP {
                .social-media-icon {
                  width: 100%;
                  height: 100%;
                }
              }
              &.FACEBOOK {
                .social-media-icon {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
        .monitors-acc-cell {
          color: #000000de;
          font-size: 12px;
          font-weight: 500;
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 5px 0;
          .monitors-acc-cell-name {
            width: fit-content;
          }
          .monitors-acc-details {
            display: flex;
            align-items: center;
            color: #00000099;
            font-size: 12px;
            font-weight: 400;
            .monitors-acc-dott {
              width: 3px;
              height: 3px;
              background-color: #636465;
              border-radius: 50%;
              margin: 0px 5px;
            }
            .monitors-acc-type-name {
              color: #000000de;
              margin: 0 5px;
            }
            .monitors-acc-data-source {
              display: flex;
              align-items: center;
              margin: 0 5px;
              .WHATSAPP {
                svg {
                  width: 16px;
                  height: 16px;
                }
              }
              img,
              svg {
                width: 13px;
                height: 13px;
              }
            }
          }
        }
      }
      .acc-table-check-cell {
        border-inline-start: 1px solid #e2e8f0;
        min-height: 52px;
        display: flex;
        align-items: center;
        //padding: 0 10px;
        .MuiTypography-body1.MuiFormControlLabel-label {
          font-size: 12px;
        }
        .MuiCheckbox-root {
          &:hover {
            background-color: #fff;
          }
          svg {
            width: 18px;
            height: 18px;
          }
        }
        label {
          margin: 0;
        }
        .tooltip-underline {
          border-bottom: 1px dashed #64748b;
        }
        &.cell-center {
          display: flex;
          justify-content: center;
          .MuiCheckbox-root {
            padding-inline-start: 0;
          }
        }
        &.last-cell {
          .MuiCheckbox-root {
            padding-inline-start: 10px;
          }
        }
        &.ACCESS {
          min-height: 62px;
          @include ar {
            min-height: 74px;
          }
        }
        &.engagement_features {
          &.FULL_ACCESS {
            width: 14%;
          }
          &.INBOX,
          &.PUBLISH {
            width: 10.5%;
          }
          &.SL_ENGAGEMENT {
            width: 15%;
          }
          &.ENGAGEMENT_ANALYTICS {
            width: 9%;
            @include ar {
              width: 8%;
            }
          }
          &.CREATE_SAVED_REPLY,
          &.ALL_TAB,
          &.CREATE_COLLECTION {
            width: calc(21% + 1px);
          }
        }
        &.account_access {
          width: 15%;
        }
        &.CREATE_TAG {
          width: calc(21% + 1px);
        }
        &.EDIT_TAG,
        &.DELETE_DISABLE_TAG,
        &.EDIT_COLLECTION,
        &.EDIT_SAVED_REPLY {
          width: 19%;
        }
      }
    }
  }
  .show-more-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }
}
.access-table-direction-list {
  direction: ltr;
}
