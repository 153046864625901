@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

@mixin text-ellipsis($lines: 1) {
  text-overflow: ellipsis;
  overflow: hidden;
  @if ($lines > 1) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  } @else {
    white-space: nowrap;
  }
}

.main-edit-engagment {
  margin: 40px 100px 0;
  .common-snackbar {
    max-width: 400px;
  }
  .main-edit-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #cbd5e1;
    color: #0c192a;
    font-size: 20px;
    font-weight: 600;
    .edit-header-btn {
      font-size: 15px;
      color: #80868c;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      &:hover {
        color: #456eb2;
      }
    }
  }
  .main-edit-step {
    margin: 25px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #475569;
    font-size: 16px;
    font-weight: 700;
    .main-step-countainer {
      padding: 30px;
      background-color: #fff;
      border: 1px solid #e4e4e4;
      border-radius: 4px;
      box-shadow: 0px 4px 4px 0px #cbd5e12b;
      .main-step-header {
        display: flex;
        gap: 10px;
        color: #1a174f;
        font-size: 16px;
        font-weight: 700;
      }
      .req-demo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 15px;
        font-weight: 500;
        color: #00000099;
        gap: 20px;
        margin-top: 20px;
        button {
          font-size: 14px;
          font-weight: 500;
          border: 1px solid #456eb2;
          border-radius: 2px;
          padding: 8px 12px;
          &.Mui-disabled {
            border-color: rgb(0 0 0 / 26%) !important;
          }
        }
      }
      .main-select-card {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 20px 0;
        &.border {
          border-bottom: 1px solid #d4d4d4;
        }
        .select-card-header {
          display: flex;
          gap: 10px;
          color: #000000de;
          font-size: 15px;
          font-weight: 500;
          flex-direction: column;
          max-width: 30%;
          .main-select-pragrf {
            font-size: 14px;
            color: #00000099;
            font-weight: 400;
            line-height: 16.8px;
            .cxm-prag2 {
              width: fit-content;
              display: inline-block;
              font-weight: 700;
            }
          }
        }
        .select-card-input {
          display: flex;
          gap: 20px;
          flex-direction: column;
          width: 50%;
          label {
            font-size: 12px;
            transform: translate(14px, -8px) scale(1);
          }
          fieldset {
            font-size: 42px;
            @include ar {
              font-size: 31px;
            }
          }
          &.channels {
            fieldset {
              font-size: 42px;
              @include ar {
                font-size: 24px;
              }
            }
          }
          &.monitors {
            fieldset {
              @include ar {
                font-size: 50px;
              }
            }
          }
          #demo-multiple-checkbox {
            padding: 7.5px 14px;
            li {
              display: flex;
              align-items: center;
              gap: 10px;
            }
          }
        }
        .cards-link-auth {
          font-size: 14px;
          font-weight: 400;
          color: #00000099;
          display: flex;
          flex-direction: column;
          gap: 10px;
          // margin-top: 20px;
          .link-auth {
            font-size: 14px;
            font-weight: 500;
            color: #456eb2;
            display: flex;
            padding: 8px 12px;
            width: 195px;
            height: 24px;
            align-items: center;
            border: 1px solid #456eb2;
            border-radius: 2px;
            &:hover {
              background-color: #e6f3fc80;
            }
            .link-text {
              margin-inline-end: 8px;
            }
          }
        }
      }
      .main-step-two {
        //padding: 20px 0;
        .step-two-header {
          font-size: 15px;
          font-weight: 400;
          padding-bottom: 25px;
          border-bottom: 1px solid #d9d9d9;
          // margin-bottom: 25px;
          .main-step-header {
            color: #1a174f;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 20px;
          }
        }
        .red-star-req {
          color: #ed1846;
        }
        .step-two-sec {
          padding: 25px 0;
          border-bottom: 1px solid #d9d9d9;
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
          &.frist {
            padding: 0;
            padding-bottom: 25px;
          }
          &.last {
            border: none;
            padding-bottom: 0;
          }
          .sec-header {
            .blue-header {
              display: flex;
              align-items: center;
              gap: 5px;
              font-size: 15px;
              font-weight: 500;
              color: #000000de;
              margin-bottom: 10px;
              .option-header {
                font-size: 14px;
                font-weight: 400;
                color: #00000061;
              }
            }
            .sec-pragrgh {
              font-size: 14px;
              font-weight: 400;
              color: #00000099;
              max-width: 325px;
            }
          }
          .main-step-inputs {
            display: grid;
            align-items: center;
            grid-template-columns: 1fr 1fr;
            gap: 15px;
            // margin: 20px 0;
            .input-label {
              font-size: 14px;
              font-weight: 400;
              color: #000000de;
              margin-bottom: 10px;
              text-transform: capitalize;
            }
            .input-field {
              width: 100%;
              input {
                padding: 9px 14px;
              }
            }
          }
          .input-about-you {
            margin: 25px 0 0px;
            .input-label {
              font-size: 14px;
              font-weight: 400;
              color: #000000de;
              margin-bottom: 10px;
            }
            .input-field {
              width: 100%;
              input {
                padding: 19px 14px;
              }
            }
          }
          .step-boolean {
            .MuiFormControlLabel-label {
              font-size: 14px;
              font-weight: 600;
              color: #000000de;
            }
          }
          .main-file-upload {
            .main-upload-loader {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #fff;
              height: 60px;
              .upload-loader-container {
                width: 100%;
                padding: 15px 10px;
                display: flex;
                align-items: center;
                gap: 10px;
                color: #00000061;
                font-size: 14px;
                font-weight: 500;
                border: 1px solid #e2e8f0;
                border-radius: 4px;
                svg {
                  width: 18px;
                  height: 18px;
                  color: #456eb2;
                }
              }
            }
            .engagment-upload-file-container {
              padding: 12px 20px;
              background-color: #f8fafc;
              border: 1px dashed #0000003b;
              border-radius: 4px;
            }
            .upload-file-title {
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 10px;
              color: #000000;
            }
            .upload-file-data {
              display: flex;
              align-items: center;
              gap: 5px;
              svg {
                color: #64748b;
                width: 26px;
                height: 26px;
                margin-inline-end: 5px;
              }
              .drop-file-content {
                font-size: 14px;
                font-weight: 500;
                color: #00000099;
                display: flex;
                align-items: center;
                gap: 5px;
                .import-browse-file {
                  cursor: pointer;
                  color: #456eb2;
                  padding: 2px 8px;
                  &:hover {
                    background-color: #e6f3fc80;
                  }
                }
              }
            }
            .word-file-container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 12px 20px;
              border: 1px solid #e2e8f0;
              border-radius: 4px;
              .close-upload-file {
                display: flex;
                align-items: center;
                gap: 5px;
                color: #456eb2;
                cursor: pointer;
                font-size: 14px;
                font-weight: 500;
                padding: 7px 13px;
                border-radius: 2px;
                direction: ltr;
                text-transform: capitalize;
                &:hover {
                  background-color: #e6f3fc80;
                }
              }
              .uploaded-file-content {
                display: flex;
                align-items: center;
                gap: 5px;
              }
              .word-file-content {
                display: flex;
                align-items: center;
                gap: 10px;
                .word-file-name-title {
                  font-size: 14px;
                  font-weight: 500;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  max-width: 185px;
                }
                .word-file-kb-num {
                  color: #00000099;
                  font-size: 14px;
                  font-weight: 400;
                }
              }
              .word-file-buttons {
                display: flex;
                align-items: center;
                gap: 8px;
                &-item {
                  svg {
                    width: 14px;
                    height: 16px;
                    font-size: 16px;
                    color: #000000de;
                  }
                }
              }
            }
            .upload-file-error-msg {
              display: flex;
              margin-top: 6px;
            }
          }
        }
        .step-two-footer {
          font-size: 14px;
          font-weight: 600;
          color: #000000de;
          margin-bottom: 25px;
          padding: 12px 15px;
          border-radius: 5px;
          background-color: #ed6c0214;
          div {
            display: inline-block;
          }
          .step-two-footer-blue {
            margin: 0 3px;
            color: #456eb2;
            cursor: pointer;
          }
        }
      }
      .main-step-three {
        .step-three-header {
          display: flex;
          flex-direction: column;
          gap: 15px;
          margin: 20px 0;
          .step-three-title {
            color: #1a174f;
            font-size: 20px;
            font-weight: 600;
          }
          .step-three-pargragh {
            color: #000000de;
            font-size: 15px;
            font-weight: 400;
          }
        }
        .reps-list {
          padding-bottom: 20px;
          border-bottom: 1px solid #e3e3e3;
          .reps-list-header {
            display: flex;
            justify-content: space-between;
            color: #000000de;
            font-size: 16px;
            font-weight: 600;
            align-items: center;
            button {
              border: 1px solid #456eb2;
              display: flex;
              align-items: center;
              gap: 5px;
              font-size: 14px;
              font-weight: 400;
              color: #456eb2;
              padding: 1px 15px;
              span {
                font-size: 20px;
                margin-bottom: 3px;
              }
            }
          }
          .reps-list-contant {
            font-size: 14px;
            color: #00000099;
            padding: 15px 0;
          }
        }
        .ai-list {
          margin-top: 20px;
          padding: 20px;
          background-color: #f9f9fc;
          border: 1px solid #e8eaf3;
          border-radius: 3px;
          .ai-list-header {
            .ai-header-title {
              display: flex;
              flex-direction: column;
              font-size: 16px;
              font-weight: 600;
              color: #212121;
              gap: 7px;
              .header-title-sub {
                font-size: 14px;
                font-weight: 400;
                color: #00000099;
              }
            }
            button {
              border: 1px solid #626cab80;
              display: flex;
              align-items: center;
              gap: 5px;
              font-size: 14px;
              font-weight: 500;
              color: #626cab;
              padding: 8px 16px;
              &:hover {
                background-color: #e8eaf3;
              }
              &.Mui-disabled {
                background-color: #0000001f;
                color: #00000042;
                cursor: not-allowed;
                display: flex;
                gap: 5px;
              }
            }
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            &.not-empty {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              .ai-header-title {
                max-width: 60%;
              }
            }
          }
          .ai-list-contant {
            padding: 20px 0;
            margin-top: 20px;
            border-top: 1px solid #a1a8ce;
            color: #00000099;
            font-size: 14px;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
        }
        .list-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
          padding: 5px 0;
          .rep-card-countainer {
            padding: 12px 15px;
            border: 1px solid #0000001f;
            background-color: #fff;
            border-radius: 4px;
            width: calc(100% - 32px);
            overflow: hidden;
            .rep-card-header {
              color: #000000de;
              font-size: 16px;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 15px;
              svg {
                color: #00000099;
                cursor: pointer;
              }
            }
            .rep-card-contant {
              font-size: 14px;
              font-weight: 400;
              color: #000000de;
              overflow: hidden;
            }
            .rep-card-footer {
              margin-top: 10px;
              padding-top: 5px;
              border-top: 1px solid #e4e4e4;
              button {
                font-size: 12px;
                font-weight: 500;
                color: #00000099;
                display: flex;
                gap: 5px;
                padding: 2px 7px;
                &:hover {
                  color: #000000de;
                }
              }
            }
          }
        }
        .reps-list-pagonation {
          &.new_reps {
            margin-top: 20px;
          }
          display: flex;
          justify-content: center;
          .MuiPaginationItem-root {
            background-color: #fff;
            &.Mui-selected {
              background-color: #efeff2;
            }
            svg {
              @include ar {
                rotate: 180deg;
              }
            }
          }
        }
      }
    }
  }
  .edit-engagment-footer {
    background-color: #fff;
    height: 70px;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0px 100px;
    gap: 10px;
    box-shadow: 0px 1px 10px 0px #0000001f;
    border-top: 1px solid #e8e8e8;
    z-index: 9;
    color: #00000099;
    font-size: 14px;
    font-weight: 400;
    button {
      font-size: 14px;
      font-weight: 500;
      height: 42px;
      border-radius: 2px;
      &.cancel-edit-btn {
        color: #696d72;
        border: 1px solid #696d72;
        width: 70px;
        &:hover {
          background-color: #e1e1e180;
          color: #1e293b;
        }
      }
      &.save-edit-btn {
        color: #fff;
        width: 119px;
        background-color: #456eb2;
        padding: 0;
      }
    }
  }
  .fetching-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 13px;
    font-size: 15px;
    font-weight: 500;
    background-color: #fff;
    margin-top: 20px;
    margin-bottom: -40px;
    height: calc(100vh - 240px);
    svg {
      width: 36px;
      height: 36px;
      color: #456eb2;
    }
  }
}
#main-select-li-item {
  gap: 5px;
  .MuiListItemText-root {
    max-width: fit-content;
    margin-inline-end: 15px;
    margin-inline-start: 10px;
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 150px;
    }
  }
  &.Mui-selected {
    background-color: #fff;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
    .MuiListItemText-root {
      span {
        font-weight: 600;
      }
    }
  }
  .main-select-icons {
    display: flex;
    align-items: center;
    gap: 5px;
    img {
      width: 16px;
    }
  }
  .main-select-type {
    font-size: 14px;
    font-weight: 400;
    color: #00000099;
  }
  .monitor-state {
    color: #696d72;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    &.collecting {
      color: #4caf50;
    }
  }
}
.main-terms-popup {
  color: #000000de;
  width: 550px;
  .main-terms-header {
    font-size: 20px;
    font-weight: 600;
  }
  .main-term-card {
    font-size: 15px;
    font-weight: 400;
    margin: 15px;
    display: flex;
    gap: 10px;
    .term-card-dotted {
      font-size: 40px;
      margin-top: -30px;
    }
    a {
      text-decoration: underline;
    }
  }
  .main-terms-footer {
    display: flex;
    justify-content: flex-end;
    button {
      color: #fff;
      background-color: #456eb2;
      border-radius: 2px;
      padding: 8px 16px;
    }
  }
}
.main-del-popup {
  width: 460px;
  margin: 0 -4px;
  .del-popup-header {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 500;
    color: #000000de;
  }
  .del-popup-contant {
    font-size: 15px;
    font-weight: 500;
    color: #000000de;
    .about-del {
      display: flex;
      gap: 5px;
      .about-del-title {
        font-weight: 700;
      }
    }
  }
  .del-popup-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    gap: 5px;
    button {
      font-size: 14px;
      font-weight: 500;
      padding: 8px 16px;
      border-radius: 2px;
      &.cancel-rep-btn {
        color: #00000099;
        border: 1px solid #0000008a;
        &:hover {
          background-color: #e8e8e880;
        }
      }
      &.del-rep-btn {
        color: #fff;
        background-color: #f44336;
        &.Mui-disabled {
          display: flex;
          gap: 5px;
          background-color: #0000001f;
          color: #00000042;
        }
      }
    }
  }
}

div.word-file-buttons-tooltip {
  padding: 8px;
  font-size: 12px;
  font-weight: 400;
  top: -3px;
}

.greeting-farewell-message-container:hover {
  border: 1px solid rgba(69, 110, 178, 1);
  cursor: pointer;
}
.greeting-farewell-message-container {
  padding: 12px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 30px);
  gap: 10px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.greeting-farewell-message-content {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.greeting-farewell-message-text.MuiTypography-root {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  @include text-ellipsis($lines: 5);
}
.greeting-farewell-message-radio.MuiRadio-root {
  padding: 0;
}

.greeting-farewell-message-footer {
  padding-top: 6px;
  gap: 5px;
  border-top: 1px solid #e4e4e4;
}

.edit-greeting-farewell-message-modal {
  width: 600px;
  max-height: calc(100vh - 120px);
  #alert-dialog-title {
    font-size: 20px;
    font-weight: 500px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }
}

.edit-greeting-farewell-message-modal-buttons.MuiDialogActions-root {
  padding: 0px 24px 24px;
}

.edit-greeting-farewell-message-input {
  .MuiOutlinedInput-input {
    font-size: 12px;
    line-height: 20px;
  }
}

.edit-greeting-farewell-message-input-label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.edit-greeting-farewell-message-input-label.MuiTypography-root {
  font-size: 16px;
  font-weight: 400px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
}

.data-sources-tap-container {
  button.MuiButtonBase-root.MuiTab-root.Mui-selected {
    border-bottom: 3px solid rgba(69, 110, 178, 1);
    color: rgba(35, 55, 89, 1);
    font-size: 14px;
    font-weight: 600;
    background-color: rgba(248, 250, 252, 1);
  }
  button.MuiButtonBase-root.MuiTab-root {
    color: rgba(100, 116, 139, 1);
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    padding: 10px 12px;
    border-bottom: 1px solid rgba(241, 245, 249, 1);
  }
}

.greeting-farewell-message-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .data-sources-tap-container {
    margin-bottom: -24px;
  }
}

.greeting-farewell-message-switch-helperText.MuiFormHelperText-root {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 10px;
  text-align: start;
  .page-link {
    color: #456eb2;
  }
}

.greeting-farewell-message-switch-label.MuiTypography-root {
  color: #000000de;
  font-size: 16px;
  font-weight: 600;
}
.main-swich-container {
  display: flex;
  align-items: center;
  gap: 5px;
  img {
    width: 20px;
    height: 20px;
  }
}
.greeting-farewell-message-switch-form-control-label.MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 0;
  gap: 5px;
}
.main-swich-new-item {
  width: 46px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #4caf50;

  font-size: 12px;
  font-weight: 600;
  color: #fff;
}
.farewell-disclaimer-container {
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 12px;
  background-color: #fcf0e6;
  margin-bottom: 18px;
  border-radius: 4px;
}

.farewell-disclaimer-icon {
  font-size: 24px;
  color: rgba(237, 108, 2, 1);
}

.farewell-disclaimer-text.MuiTypography-root {
  font-size: 14px;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.farewell-survey-container {
  padding: 20px;
  padding-inline-end: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.farewell-survey-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.farewell-survey-title.MuiTypography-root {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: rgba(33, 33, 33, 1);
}

.farewell-survey-description.MuiTypography-root {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
}

.farewell-survey-select {
  width: 358px;
}

.greeting-farewell-setup-container {
  margin-top: 20px;
  padding: 20px 0px;
  border-top: 1px solid #e8eaf3;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.greeting-farewell-setup-title.MuiTypography-root {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
}

.greeting-farewell-setup-messages-container {
  border: 1px solid rgba(232, 234, 243, 1);
  padding: 15px;
  border-radius: 3px;
}
.main-grating-countainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
  background-color: #f8fafc;
  padding: 20px;
  border-radius: 3px;
}

.greeting-farewell-message-wrapper {
  .automated-replies-header-stack {
    justify-content: space-between;
    align-items: center;
  }
  #monitors-select-label {
    background-color: #fff;
    padding-right: 8px;
  }
  .automated-replies-twitter-alert {
    margin-bottom: 15px;
    margin-top: 5px;
  }
  @include ar {
    .select-error-message {
      text-align: right;
    }
    #monitors-select-label {
      right: 35px;
      transform-origin: top right;
      background-color: transparent;
      padding-right: 0px;
    }

    #reply-by-select-label {
      right: 30px;
      transform-origin: top right;
    }

    #automated-reply-monitors-select,
    #reply-by-select {
      & ~ fieldset {
        text-align: right;
      }
    }
  }
}

.engagement-filter-menu-item-option-container {
  .engagement-setting-datasources {
    display: flex;
    position: absolute;
    inset-inline-end: 25px;
  }
}

.public-reply-box-container {
  padding: 12px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 30px);
  gap: 10px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.public-reply-box-content {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.public-reply-box-footer {
  padding-top: 6px;
  gap: 5px;
  border-top: 1px solid #e4e4e4;

  .luc-Button.MuiButton-textPrimary.Mui-disabled {
    pointer-events: none;
  }
}

.add-edit-public-reply-input-label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 10px;

  .add-edit-public-reply-input-label {
    font-size: 16px;
    font-weight: 400px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }
  #reply-count.MuiTypography-root {
    color: rgba(0, 0, 0, 0.6);
  }
}
#alert-dialog-description {
  .add-public-reply-data-source-label {
    margin-bottom: 5px;
  }
}

.add-public-reply-message-input-container {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  &:focus {
    border: 2px solid #456eb2;
  }

  &:has(.Mui-focused) {
    border: 2px solid #456eb2;
  }
  &:has(.Mui-error) {
    border: 2px solid #f44336;
  }

  textarea {
    & + fieldset {
      border: 0;
    }
  }

  @include ar {
    .MuiFormHelperText-root {
      text-align: right;
    }
  }
  .public-reply-attachment-box {
    position: absolute;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 3.5px;
    width: 33px;
    left: 14px;

    div {
      cursor: pointer;
    }
  }

  @include ar {
    .public-reply-attachment-box {
      left: auto;
      right: 14px;
    }
  }
}
.add-public-reply-social-icon {
  width: 16px;
  height: 22px;
  img {
    width: 100%;
    height: 100%;
  }
}

#max-public-replies-tooltip {
  margin-top: 6px;
  .MuiTooltip-tooltipPlacementBottom {
    padding: 13px;
    padding-bottom: 0;
    text-align: center;
    max-width: 200px;
  }
}
.delete-public-reply-message-modal {
  width: 600px;
}

#add-edit-public-reply-modal {
  .reply-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .reply-footer-icon {
    margin-bottom: 5px;
    margin-inline-start: 5px;
  }
  .reply-box-error-message {
    color: #f44336;
    font-size: 12px;
    margin-inline-start: 20px;
    margin-top: 5px;
  }
}

.edit-greeting-farewell-message-modal-buttons {
  @include ar {
    #edit-reply-submit-button,
    #add-reply-submit-button {
      margin-left: 0;
    }
  }
}

#max-reply-limit-tooltip {
  div {
    max-width: 190px;
    padding: 13px;
    padding-bottom: 0;
  }
}

.public-reply-monitor-item.MuiMenuItem-root.Mui-selected {
  background-color: transparent;
}
.public-reply-monitor-item.MuiMenuItem-root.MuiButtonBase-root {
  &:hover {
    background-color: #fafafa;
  }
}

#main-select-li-item {
  .public-reply-count-icons {
    margin-inline-end: 5px;
  }
}

#main-edit-settings-snackbar {
  max-width: 570px;
}
