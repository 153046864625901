@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.big-table-widget {
  border: 1px solid #e2e8f0;
  box-shadow: 0px 4px 8px 0px #00000014;
  background-color: #ffffff;
  border-radius: 5px;
  .MuiTypography-root {
    text-wrap: nowrap;
    white-space: nowrap;
  }
  &-fetching {
    padding: 50px;
  }
  .sentiment-legends-container {
    display: none;
  }
  .widget-box {
    .widget-header-container-dashboard {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      box-sizing: border-box;
      padding: 20px;
      .MuiGrid-item {
        margin: 0;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .big-table-container {
    box-shadow: none;
    border-radius: 0;
    position: relative;
    .MuiTableHead-root {
      border: none;
      border-top: 1px solid #e2e8f0;
      &.big-table-header {
        border-top: none;
      }
    }
    &::-webkit-scrollbar {
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
      box-shadow: none;
    }
    .big-table-loader-circular {
      position: absolute;
      top: 0;
      z-index: 100000000;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
    }
  }
  .big-table-warning-alert {
    height: 48px;
    box-sizing: border-box;
    margin: 5px 20px;
    align-items: center;
  }
  .big-table-alert {
    border: 1px solid #d5f0fc;
    color: #00000099;
    height: 48px;
    box-sizing: border-box;
    background: #e9f4fe;
    margin: 5px 20px;
    align-items: center;
    gap: 12px;
    .MuiAlert-message {
      font-size: 14px;
      font-weight: 400;
      line-height: 20.02px;
      padding: 0;
      overflow-y: hidden;
    }
    .MuiAlert-icon {
      margin: 0;
    }
  }

  // Table styles
  .big-table-cell {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-transform: none;
    color: #000000de;
    text-wrap: nowrap;
    white-space: nowrap;
    border-color: #e2e8f0;
    @include ar {
      text-align: right;
    }
    &.sticky-cell {
      position: sticky;
      @include ar {
        right: 0;
      }
      @include en {
        left: 0;
      }
      background-color: #fff;
      z-index: 2;
    }
    &-header {
      color: #475569;
    }
  }

  tr.big-table-row {
    background: #fff;
    min-height: 60px;
  }

  .big-table-pagination {
    height: 60px;
    display: flex;
    justify-content: right;
    padding: 0 20px;
    align-items: center;
    .MuiTableCell-root {
      border-bottom: none;
      box-shadow: none;
      direction: ltr;
      overflow: hidden;
      p {
        text-wrap: nowrap;
        white-space: nowrap;
      }
    }
  }
}
