.qr-two-factor-auth-container {
  text-align: center;

  .margin-bottom {
    margin-bottom: 10px;

    &.totp-secret-code {
      background-color: #f1f5f9;
      padding-block: 10px;
    }
  }
}
