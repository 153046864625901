@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#bounced-emails-alert {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  .MuiAlert-message {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .bounced-emails-alert-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20.02px;
      span {
        font-weight: 500;
      }
    }
    .bounced-emails-alert-btn.luc-Button.MuiButton-textPrimary {
      color: #603614;
      &:hover {
        background-color: #ed6c0214;
      }
    }
  }
}
.bounced-emails-popup {
  .MuiDialog-paper {
    max-width: 600px;
    margin: auto;
  }
  .MuiDialogContent-root {
    padding-inline-end: 2px;
    padding-bottom: 0px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: #cbd5e1;
      @include ar {
        left: 10px;
      }
      @include en {
        right: 10px;
      }
    }
  }
  .MuiDialogActions-root {
    margin: 12px 8px;
  }
}
#bounce-emails-popup-body {
  max-height: 364px;
  overflow: auto;
  &::-webkit-scrollbar-track {
    border: none;
    box-shadow: none;
  }
  .list-container {
    margin-inline-end: 24px;
  }
  .account-list-item {
    margin-bottom: 8px;
    &:hover {
      border-radius: 3px;
      background-color: #f5f5f5;
      cursor: pointer;
    }
    &.checked {
      border-radius: 3px;
      background-color: #e6f3fc;
    }
    .MuiListItemText-primary {
      color: #000000de;
      @include ar {
        text-align: right;
      }
    }
    .MuiListItemText-secondary {
      color: #00000099;
    }
  }
  .account-list-header {
    font-size: 14px;
    font-weight: 500;
  }
  .account-list-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
    margin-inline-end: 24px;
  }
  .MuiListItemText-root span {
    margin: 0px;
  }
}
