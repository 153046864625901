@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.notes-sidebar-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: white;
  height: 100%;
  z-index: 100;
  .notes-footer-button {
    width: 32px;
    height: 32px !important;
    &.mention-icon {
      width: auto;
      gap: 8px;
    }
    &.send-button-note {
      svg {
        width: 20px;
        height: 20px;
        color: #456eb2;
      }
      &:hover {
        svg {
          color: #304d7d;
        }
      }
      .MuiCircularProgress-root {
        width: 15px !important;
        height: 15px !important;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
    .mention-notified-count {
      font-weight: 500;
      font-size: 12px;
      line-height: 26px;
      letter-spacing: 0.46px;
      color: #475569;
    }
  }
  .notes-content-sidebar-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    border-inline-start: 1px solid #e2e8f0;
    width: 290px;

    // Header of the sidebar
    .notes-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      border-bottom: 1px solid #e2e8f0;
      min-height: 65px;
      padding: 16px 20px;
      box-sizing: border-box;
    }

    // Sticky container for the input field
    .sticky-input-wrapper {
      position: sticky;
      top: 0;
      background: #fff;
      padding: 20px;
      z-index: 10;
      border-bottom: 1px solid #e2e8f0;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
    }
    .note-input-container {
      position: relative;
      width: 100%;
    }
    // Custom styling for the TextField component
    .custom-textfield {
      .MuiOutlinedInput-root {
        border-radius: 4px;
        background-color: #f8fafc;
        &:hover {
          border-color: rgba(0, 0, 0, 0.87);
        }

        &.Mui-focused {
          border-color: #456eb2;
        }
      }
      .MuiInputBase-input {
        font-size: 14px;
        color: #333;
        overflow-y: auto;
        width: 100%;

        &::placeholder {
          color: rgba(0, 0, 0, 0.38);
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.15px;
        }
        &::-webkit-scrollbar-track {
          background: none;
          box-shadow: none;
          border-radius: 0;
        }
      }
      &.expanded {
        .MuiInputBase-input {
          padding-top: 0px; // Adjusted padding when input expands
        }
        .MuiOutlinedInput-root {
          min-height: auto; // Allow height to grow when expanded
          padding: 12px;
          padding-bottom: 40px;
        }
      }
      &.default {
        .MuiOutlinedInput-root {
          max-height: 40px;
        }
      }
    }
    // Styling for the send icon
    .icons-wrapper {
      position: absolute;
      bottom: 0px;
      left: 4px;
      right: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 8px);
      @include ar {
        left: 0px;
        right: 4px;
      }
      &.default {
        width: 20px;
        position: static;
      }
    }

    // icons styling
    .note-icons-left {
      display: flex;
      gap: 2px;
      align-items: center;
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
      width: 100%;
      #notes-footer-send-button {
        &:hover {
          background-color: transparent;
        }
      }
    }

    // Show icons only when input is focused
    .custom-textfield.expanded .note-icons-left {
      opacity: 1;
      height: 40px;
    }

    .emoji-icon,
    .mention-icon {
      color: #475569;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
    }
    .mention-icon.notify {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0px;
      .notified-count {
        color: #475569;
        font-size: 12px;
        font-weight: 500;
        line-height: 26px; /* 216.667% */
        letter-spacing: 0.46px;
      }
    }

    .send-icon-expand {
      margin-inline-start: auto; /* Moves the send icon to the right */
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .send-button {
        font-size: 18px;
        color: #456eb2;
        opacity: 0.2;
        z-index: 2;
        &.active {
          opacity: 1;
          cursor: pointer;
        }
        :hover {
          color: #304d7d;
        }
      }
    }
    .send-icon {
      display: flex;
      align-items: center;
      margin-left: auto;
      .send-button {
        font-size: 18px;
        color: #456eb2;
        opacity: 0.2;
        z-index: 2;
        &.active {
          display: none;
        }
      }
    }

    // Content area for empty state or note display
    .notes-content.empty {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      max-height: calc(100vh - 100px);
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        background: none;
        box-shadow: none;
      }
    }
    .notes-content.with-notes {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      overflow-y: auto;
      max-height: calc(
        100vh - 100px
      ); // Adjust based on actual height of sticky input and header
      padding-bottom: 10px;
      &::-webkit-scrollbar-track {
        background: none;
        box-shadow: none;
      }
    }
    // Container for text content within the notes area
    .note-text-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
      gap: 10px;
    }
    // Styling for the empty state title
    .notes-empty-state-title {
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
    }

    .notes-empty-state-subtitle {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      width: 182.777px;
      line-height: 14.4px;
    }

    .note-image {
      width: 40px;
      height: 40px;
      border-radius: 171.429px;
      border: 1.429px solid #e2e8f0;
    }
  }
  .pagination-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    .pagination-loader-btn {
      width: 35px;
      height: 35px;
      border: 1px solid #456eb2;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        color: #456eb2;
        width: 13px;
        height: 25px;
      }
      &:hover {
        background-color: #e6f3fc80;
      }
      &.disabled {
        background-color: #fff;
        cursor: default;
        svg {
          color: #456eb2;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .note-text-field-custom {
    .MuiFormHelperText-root.Mui-error {
      @include ar {
        text-align: right;
      }
    }
  }
}

// notified users menu
.notified-users-menu {
  .MuiPaper-root {
    width: 280px;
    border-radius: 4px;
    box-shadow:
      0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  .MuiMenuItem-root {
    display: flex;
    padding: 6px 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }
  .notified-menu-items-container {
    display: flex;
    flex-direction: column;
  }
  .note-users-drop-menu-header {
    padding: 8px 16px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.15px;
  }
  .note-users-name {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.15px;
  }
  .note-users-email {
    color: #696d72;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: 0.15px;
  }
}

// Notified Count (next to @ icon)
.notified-count {
  font-size: 12px;
  color: #456eb2;
  margin-left: 5px;
}

.notes-icons-tooltip {
  .MuiTooltip-tooltip {
    padding: 8px 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    border-radius: 4px;
  }
}

div.notified-users-menu-paper {
  width: 280px;
  max-height: 305px;
  border-radius: 4px;
  background: #fff;
  box-sizing: border-box;

  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  padding: 8px 0;
  .MuiList-root {
    padding: 0;
    .note-users-drop-menu-header {
      padding: 6px 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.15px;
      color: #00000099;
    }
    .MuiMenuItem-root {
      display: flex;
      padding: 6px 16px;
      align-items: center;
      gap: 10px;
      box-sizing: border-box;
      cursor: pointer;
      .notified-users-checkbox {
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        svg {
          width: 18px;
          height: 18px;
        }
      }
      .notified-users-profile {
        display: flex;
        gap: 10px;
        align-items: center;
        .notified-users-info {
          display: flex;
          flex-direction: column;
          max-width: 155px;
          .note-users-name {
            overflow: hidden;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.15px;
            color: #000000de;
            line-height: 150%; /* 18px */
          }
          .note-users-email {
            font-size: 12px;
            font-weight: 400;
            line-height: 150%; /* 18px */
            letter-spacing: 0.15px;
            color: #696d72;
          }
        }
      }
    }
  }
}
