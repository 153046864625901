#six-digits-code-input-container {
  text-align: center;
  direction: ltr;
  .code-input-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding-block: 10px;
    .code-input {
      text-align: center;
      padding: 10px;
      width: 20px;
      height: 20px;
    }
  }

  .error-code-input {
    color: #f44336;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.86px;
  }
}
