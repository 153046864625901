#two-factor-auth-login-page {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .form-title {
    margin-bottom: 0px;
  }
  #text-content-to-2fa {
    margin-bottom: 20px;
    max-width: 100%;
  }

  .two-factor-auth-login-method-item {
    padding: 16px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .divider-two-factor-auth-login-method-item.MuiDivider-root {
    margin-block: 4px;
    border: none;
    height: 1px;
    width: 100%;
    background-color: #e2e8f0;
  }

  .remember-me-option-two-factor-auth {
    font-size: 14px;
    font-weight: 500;
    color: #000000de;
  }

  .resend-code-try-another-way-container {
    display: flex;
    justify-content: space-between;
    #resend-code-time-btn {
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      align-items: center;

      .resend-code-time {
        color: #000000de;
      }
    }
    .try-another-way-position {
      margin-inline-start: auto;
    }
  }
}
