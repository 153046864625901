@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.notes-sidebar-wrapper {
  .note-card {
    display: flex;
    gap: 10px;
    align-items: flex-start;
  }

  .note-card:last-child {
    padding-bottom: 20px; // Adjust padding as needed
  }

  .note-avatar {
    display: flex;
    align-items: center;
    height: 32px;
  }

  .note-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1 0 0;
    max-width: 250px;
    overflow: hidden;

    .note-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 32px;
      box-sizing: border-box;
    }
    .note-author {
      display: flex;
      align-items: center;
      gap: 4px;
      flex-wrap: wrap;
      .note-author-name {
        color: #000000de;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .is-edited {
        font-size: 12px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.38);
        white-space: nowrap;
      }
    }
    .note-options {
      width: 32px;
      height: 32px;
      font-size: 14px;
      color: #475569;
      cursor: pointer;
      background: transparent;
      border-radius: 4px;
      &:hover {
        background: #f1f5f9;
      }
      &.open {
        background: #f1f5f9;
      }
    }
    .note-text {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);
      text-align: start;
      // Fix overflow
      word-wrap: break-word; // Break long words
      overflow-wrap: break-word; // Modern alternative to word-wrap
      max-width: 100%; // Ensure text doesn't exceed the container's width
      white-space: break-spaces;
      font-family: Figtree, sans-serif !important;
      @include ar {
        font-family: "IBM Plex Sans Arabic" !important;
      }
    }
    .note-timestamp {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.38);
      text-align: start;
    }
  }
  .note-divider {
    width: 100%;
    border-color: #e2e8f0 !important;
    box-sizing: border-box;
  }

  .edit-note-form {
    width: 212px;
    .edit-input {
      .MuiInputBase-root {
        padding: 12px;
        padding-bottom: 42px;
        .MuiInputBase-input {
          &::placeholder {
            color: rgba(0, 0, 0, 0.38);
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.15px;
          }
          &::-webkit-scrollbar-track {
            background: none;
            box-shadow: none;
            border-radius: 0;
          }
        }
      }
      .MuiFormHelperText-root.Mui-error {
        color: #f44336;
        margin: 0;
        padding-top: 4px;
        @include ar {
          text-align: right;
        }
      }
    }
  }
  // update input field
  .edit-input-container {
    position: relative; // Make sure the parent container is positioned
    padding-bottom: 35px; // Add space at the bottom for the buttons
  }

  .update-input-buttons-holder {
    position: absolute;
    display: flex;
    justify-content: space-between; // Align emoji left and buttons right
    align-items: center;

    padding: 4px 8px 4px 4px;
    @include ar {
      padding: 4px 4px 4px 8px;
    }
    bottom: 0;
    left: 0;
    right: 0;
  }

  .edit-actions {
    display: flex;
    gap: 8px;
    align-items: center;
    .edit-action-cancel {
      min-width: 58px;
      height: 25px !important;
      box-sizing: border-box;
      padding: 0 !important;
    }
    .edit-action-save {
      min-width: 48px;
      height: 25px !important;
      box-sizing: border-box;
      padding: 0 !important;
      .MuiCircularProgress-root {
        width: 15px !important;
        height: 15px !important;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .emoji-icon {
    margin-left: 5px; // Align emoji to left
  }
}

.note-card-item-menu {
  margin-top: 5px;
  .MuiPaper-root {
    border-radius: 4px;
    min-width: 119px;
    box-shadow:
      0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .MuiMenuItem-root {
    font-size: 14px;
    padding: 6px 16px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.15px;
    &:hover {
      background-color: #f8fafc;
    }
  }

  .delete-option {
    color: #e31b0c;
  }
}
