@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.notifications-main-box {
  .header-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.015em;
    color: #0c192a;
    @include ar {
      font-weight: 700;
    }
  }

  .filter-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    margin-top: 20px;

    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 0.8px #e0e0e0;
    border-radius: 4px;

    .header-date-picker-wrapper {
      margin: 0 20px;
    }

    .MuiSelect-root {
      width: 300px;
      height: 40px;
    }
    .filter-results-btn {
      background: #456eb2;
      border-radius: 4px;
      font-weight: 500;
      font-size: 15px;
      line-height: 26px;
      color: #ffffff;
      padding: 8px 24px;
      @include ar {
        padding: 8px 29px;
      }
    }

    #notifications-filter-label {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.6);
      @include ar {
        font-family: "Dubai" !important;
        font-weight: 500;
        left: auto;
        right: 30px;
      }
      &.MuiInputLabel-shrink {
        @include ar {
          left: auto;
          right: 15px;
        }
      }
    }
    .MuiFormControl-root {
      @include ar {
        fieldset {
          legend {
            text-align: right;
          }
        }

        .MuiSvgIcon-root {
          right: auto;
          left: 7px;
        }
      }
    }
  }

  .notification-table {
    margin-top: 30px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    display: block;
    border-radius: 0 !important;

    .MuiTableHead-root {
      border-bottom: 0;
    }
    .action-table-head {
      p {
        border-bottom: 1px dashed #cbd5e1;
      }
    }
    .MuiTableCell-head {
      padding: 12px 16px;
      font-size: 16px;
      font-weight: 500;
      line-height: 14px;
      color: #475569;
      text-transform: capitalize;
      text-align: start;
      background: #f1f5f9;
    }

    .MuiTableBody-root {
      > :nth-of-type(odd) {
        background: rgba(255, 255, 255, 0.002);
      }

      > :nth-of-type(even) {
        background: #f8fafc;
      }
    }

    .date-direction {
      direction: ltr;
    }

    .MuiTableCell-body {
      padding: 12px 16px;
      font-size: 14px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);
      border-bottom: 0;
      box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
      b {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.87);
      }
      @include ar {
        font-family: "Dubai" !important;
        text-align: right;
        .MuiButton-root {
          margin-left: 0;
          margin-right: 8px;
        }
      }
    }
    .monitors_names_td {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #456eb2;
      }
    }
    .type-td {
      position: relative;
      display: inline-block;
      &.red-dot::after {
        background: #e50035;
        color: #e50035;
        content: "\2022";
        font-size: 0px;
        border-radius: 5px;
        width: 5px;
        height: 5px;
        position: absolute;
        top: 2px;
        right: 0px;
      }
    }
    .action-td {
      display: flex;
      align-items: center;
      direction: ltr;
      @include en {
        justify-content: end;
      }
      @include ar {
        justify-content: start;
      }
    }

    .alerts_social {
      display: flex;
      align-items: center;

      .icon {
        margin: 0 5px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        width: 20px;
        height: 20px;
        border-radius: 20px;
      }

      .facebook-icon {
        background-color: #3b5998;
        color: #ffffff;
      }

      .twitter-icon {
        background-color: #1ba8e3;
        color: #ffffff;
      }

      .instagram-icon {
        background: linear-gradient(
          180deg,
          #9136c1 0%,
          #eb3660 39.2%,
          #f4aa45 100%
        );
        color: #ffffff;
      }
      .gmail-icon {
        background: #ea4335;
        color: #ffffff;
      }
      .intercom-icon {
        background: "#326bff";
        color: "#fff";
      }

      .socialnetworks-icon,
      .talkwalker-icon {
        & img {
          width: 28px;
          height: 28px;
        }
      }
    }

    .notification-empty-box {
      width: 100%;
      height: 324px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      svg {
        color: #cbd5e1;
        width: 39px;
        height: 45px;
      }
      .MuiTypography-subtitle2 {
        margin-top: 17px;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        color: #64748b;
        @include ar {
          font-weight: 500;
        }
      }
      a {
        color: #456eb2;
        text-decoration: underline;
        font-size: 15px;
        font-weight: 600;
        margin: 0 4px;
        display: inline-block;
        width: max-content;
      }
    }
  }
}

.notifications-multi-menuItem {
  padding: 0 10px !important;
  height: 40px;
  .MuiListItemText-root {
    span {
      margin: 0;
      letter-spacing: 0.15px;
      @include ar {
        font-family: "Dubai" !important;
      }
    }
  }
}
.notificationType-multiple-name {
  height: 40px;
}
.notification-table {
  .notification-type-parent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .type-td {
      margin-inline-end: 10px;
      img {
        width: 32px;
        height: 32px;
      }
    }
    .notification-type {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 5px;
    }
    .notification-message {
      font-weight: 400;
      font-size: 12px;
      line-height: 143%;
      color: rgba(0, 0, 0, 0.6);
      b {
        @extend .notification-message;
      }
    }
  }
  .btn-action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 32px;
    border-radius: 2px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }
  .btn-action-view {
    color: #fff;
    background: #456eb2;
  }
  .btn-action-download {
    background-color: transparent;
    color: #456eb2;
    border: 1px solid rgba(69, 110, 178, 0.5);
  }
  .time-parant {
    display: flex;
    flex-direction: column;
  }
  .time-parant {
    .date {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 5px;
    }
    .time {
      font-weight: 400;
      font-size: 12px;
      color: #80868c;
    }
  }
  .notifications-main-box
    .notification-table
    .MuiTableBody-root
    > :nth-of-type(even) {
    background: #f1f5f9;
  }
  .product-sources {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 5px;
  }
  .monitor-sources {
    font-weight: 400;
    font-size: 12px;
    line-height: 143%;
    color: #80868c;
  }
  &#shared-table {
    .btn-action-view {
      color: #fff;
      background: #456eb2;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 95px;
      height: 32px;
      border-radius: 2px;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.notification-download-view-btn-tooltip {
  width: 167px;
}
