@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.react-datepicker-wrapper {
  .custom-date-picker-input-wrapper {
    .MuiButton-outlinedSecondary {
      color: rgba(0, 0, 0, 0.87);
      border: 1px solid rgba(196, 196, 196, 1);
      background-color: rgba(255, 255, 255, 1);
      justify-content: flex-start;
      border-radius: 4px !important;
      &:hover {
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(0, 0, 0, 0.87);
      }

      &.luc-Button {
        &.MuiButton-sizeSmall {
          .MuiButton-startIcon {
            margin-right: 0;
            margin-inline-end: 8px;
          }
        }
        &.MuiButton-sizeSmall,
        &.MuiButton-sizeLarge {
          .MuiButton-startIcon {
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }
}

/* Popper Container */
.date-range-picker-container {
  .react-datepicker-popper {
    width: 550px;
    overflow: hidden;
    z-index: 999;
    box-shadow: 0 20px 100px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.25);
    .custom-datepicker-container {
      display: flex;
      background-color: #fff;
      padding-left: 0;
      border-radius: 8px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      width: 600px;
      height: 402px;
      margin: auto;
      overflow: hidden;
      &.sidebar-only {
        height: auto;
      }
      /* Overall DatePicker */
      &.react-datepicker {
        width: 100%;
        border: none;
        border-radius: 8px;
        font-family: "Arial", sans-serif;
      }
      /* -------------------- SIDEBAR -------------------- */
      .sidebar {
        padding: 16px 0;
        width: 150px;
        display: flex;
        flex-direction: column;
        border-inline-end: 1px solid #e2e8f0;
        box-sizing: border-box;
        overflow: hidden;

        .sidebar-button {
          padding: 10px 16px;
          background-color: transparent;
          border: none;
          text-align: start;
          cursor: pointer;
          transition: all 0.2s ease;
          border-inline-start: 5px solid transparent;
          box-sizing: border-box;
          span {
            font-size: 14px;
            font-weight: 500;
          }

          &.has-icon {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              font-size: 14px;
              font-weight: 500;
            }
            svg {
              color: rgba(0, 0, 0, 0.6);
              width: 14px;
              height: 16px;
              cursor: pointer;
            }
          }

          &:hover {
            background-color: rgba(248, 250, 252, 1);
          }

          &.disabled {
            background-color: rgba(255, 255, 255, 1);
            color: rgba(0, 0, 0, 0.38);
            cursor: unset;
          }

          &.active {
            font-size: 14px;
            font-weight: 500;
            background-color: #e6f3fc;
            color: rgba(69, 110, 178, 1);
            border-inline-start: 5px solid rgba(69, 110, 178, 1);
          }
        }
      }

      /* -------------------- DATE PICKER WRAPPER -------------------- */
      .datepicker-wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 400px;

        .custom-datepicker {
          width: 100%;
        }

        .custom-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .nav-button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            padding: 8px 9px;
            width: 32px;
            height: 32px;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            svg {
              color: rgba(71, 85, 105, 1);
            }
            &:hover {
              background-color: #f1f5f9;
            }
            @include ar {
              transform: rotate(180deg);
            }
          }
          .header-labels {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .header-label {
            font-size: 16px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 32px;
            width: 38px;
            border-radius: 2px;
            display: flex;
            align-items: center;
            user-select: none;
            &.year-label {
              width: 56px;
            }
            &:hover {
              cursor: pointer;
              color: #456eb2;
              background-color: #f1f5f9;
            }
            @include ar {
              width: 60px;
              height: 30px;
            }
          }
        }

        /* Day names row in a 7-column grid matching the day columns */
        .react-datepicker__day-names {
          display: grid;
          grid-template-columns: repeat(7, 52.57px);
          gap: 0;
          margin: 16px 0 0 0;
          box-sizing: border-box;
          text-align: center;
          justify-content: center;
          .react-datepicker__day-name {
            width: 52.57px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
            line-height: 30px;
            text-align: center;
          }
        }
        /* -------------------- DAY GRID -------------------- */
        .react-datepicker__month-container {
          width: 100%;
          .react-datepicker__month {
            padding: 0;
            box-sizing: border-box;
            .react-datepicker__week {
              display: grid;
              grid-template-columns: repeat(7, 52.57px);
              gap: 0;
              margin: 0;
              align-items: center;
              margin-bottom: 6px;
              justify-content: center;
              .react-datepicker__day {
                width: 52.57px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                margin: 0;
                border-radius: 0;
                border: 2px solid transparent;
                box-sizing: border-box;
                &:hover {
                  background-color: transparent;
                  border: 2px solid #456eb2;
                  color: #456eb2;
                  border-radius: 2px;
                  @include ar {
                    border-radius: 2px;
                  }
                }

                &.react-datepicker__day--in-range {
                  background-color: rgba(230, 243, 252, 1);
                  color: rgba(0, 0, 0, 0.87);
                  &:hover {
                    border: 2px solid transparent;
                    background-color: rgba(230, 243, 252, 1);
                    color: rgba(0, 0, 0, 0.87);
                  }
                }
                &.react-datepicker__day--range-start,
                &.react-datepicker__day--range-end {
                  background-color: rgba(69, 110, 178, 1) !important;
                  color: rgba(255, 255, 255, 1);
                  &:hover {
                    background-color: rgba(69, 110, 178, 1);
                    color: rgba(255, 255, 255, 1);
                  }
                }
                &.react-datepicker__day--range-start {
                  border-top-left-radius: 4px;
                  border-bottom-left-radius: 4px;
                  @include ar {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                  }
                }
                &.react-datepicker__day--range-end {
                  border-top-right-radius: 4px;
                  border-bottom-right-radius: 4px;
                  @include ar {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                  }
                }
                &.react-datepicker__day--in-selecting-range {
                  &.react-datepicker__day--selecting-range-start {
                    &:hover {
                      border: 2px solid transparent;
                      background-color: rgba(230, 243, 252, 1);
                      color: rgba(0, 0, 0, 0.87);
                    }
                  }
                }
                &.react-datepicker__day--disabled {
                  color: rgba(0, 0, 0, 0.38);
                  text-decoration: line-through;
                  cursor: unset;
                  span {
                    text-decoration: line-through;
                    cursor: unset;
                  }
                  &:hover {
                    background-color: transparent;
                    border: 2px solid transparent;
                    color: rgba(0, 0, 0, 0.38);
                  }
                }
                @include ar {
                  border-radius: 0;
                }
              }
            }
          }
          .react-datepicker__header {
            background-color: #ffffff;
            border-bottom: none;
            padding: 16px;
            padding-bottom: 0;
            box-sizing: border-box;
          }
        }
        /* -------------------- TIME INPUTS -------------------- */
        .time-inputs {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;
          padding: 16px;
          width: 100%;
          box-sizing: border-box;
          &.hidden {
            display: none;
          }
          .MuiPickersPopper-root {
            @include ar {
              direction: ltr;
            }
          }
          .time-input-group {
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            &.has-time-picker {
              justify-content: space-between;
            }
            .time-input-items {
              display: flex;
              gap: 8px;
              align-items: center;

              .time-separator {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.38);
                @include ar {
                  transform: rotate(180deg); /* Change to rotate(180deg) */
                }
              }

              .time-picker-wrapper {
                border: 1px solid #c4c4c4;
                width: 110px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 12px;
                box-sizing: border-box;
                border-radius: 4px;
                cursor: pointer;
                &:hover {
                  border: 1px solid rgba(0, 0, 0, 0.87);
                  background-color: transparent;
                }
                &.opened {
                  border: 1px solid #456eb2;
                }
                .MuiFormControl-root {
                  .MuiInputBase-formControl {
                    &:hover {
                      background-color: transparent;
                    }
                  }
                }
                .MuiPickersLayout-actionBar {
                  display: none;
                }
                .MuiInputBase-root {
                  height: 42px;
                  width: 62px;
                  overflow: hidden;
                  box-sizing: border-box;
                  border: 0;
                  padding: 0;

                  input {
                    padding: 0;
                    font-size: 14px;
                  }
                  fieldset {
                    border: 0;
                  }
                }
                .MuiMultiSectionDigitalClockSection-root {
                  &::after {
                    height: auto;
                  }
                  .MuiMultiSectionDigitalClockSection-item {
                    padding: 6px 16px;
                    @include en {
                      font-family: "Figtree", sans-serif;
                    }
                    @include ar {
                      font-family: "IBM Plex Sans Arabic", sans-serif;
                    }
                    &.Mui-selected {
                      border-bottom: 0;
                    }
                  }
                }

                .MuiMultiSectionDigitalClockSection-item:hover {
                  background-color: #f1f5f9;
                }

                .MuiMultiSectionDigitalClockSection-item.Mui-selected {
                  background-color: #e6f3fc;
                  color: #456eb2;
                }

                .MuiMultiSectionDigitalClockSection-list {
                  // Hide scrollbar in Chrome/Safari
                  &::-webkit-scrollbar {
                    display: none;
                  }

                  // Hide scrollbar in Firefox
                  scrollbar-width: none;

                  // Hide scrollbar in IE
                  -ms-overflow-style: none;
                }

                .MuiInputAdornment-root {
                  display: none;
                  .MuiSvgIcon-root {
                    display: none;
                  }
                }
                .time-picker-icon {
                  font-size: 14px;
                  color: rgba(0, 0, 0, 0.6);
                  svg {
                    cursor: pointer;
                    &:hover {
                      color: rgba(0, 0, 0, 0.87);
                    }
                  }
                }
              }
            }
            .apply-btn {
              padding: 8px 12px;
              background-color: #456eb2;
              color: #ffffff;
              border: none;
              border-radius: 2px;
              cursor: pointer;
              font-weight: 500;
              font-size: 14px;
              width: 70px;
              height: 42px;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }

  &.has-only-sidebar {
    .react-datepicker-popper {
      width: auto;
    }
  }
}

.react-datepicker__current-month {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

/* -------------------- MONTH/YEAR PICKERS -------------------- */
.month-picker,
.year-picker {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  box-sizing: border-box;
}

.month-picker {
  .month-picker-header {
    width: 100%;
    padding: 16px;
    padding-top: 0;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    .header-label {
      font-size: 16px;
      font-weight: 600;
      padding: 0 8px;
      height: 32px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      margin: 0;
      box-sizing: border-box;
      &:hover {
        cursor: pointer;
        color: #456eb2;
        background-color: #f1f5f9;
      }
    }
  }
}

.picker-header {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.year-picker-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  padding-top: 0;
  box-sizing: border-box;
  .MuiTypography-root {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  cursor: unset;
  .picker-header {
    cursor: unset;
  }
  .nav-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 8px 9px;
    width: 32px;
    height: 32px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      color: rgba(71, 85, 105, 1);
    }
    &:hover {
      background-color: #f1f5f9;
    }
    @include ar {
      transform: rotate(180deg);
    }
  }
}

.year-picker-grid,
.month-picker-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  text-align: center;
  padding: 0 16px 16px;
  box-sizing: border-box;
}

/* Buttons in Month and Year Pickers */
.picker-button {
  span {
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 25px 0;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 2px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    user-select: none;
    &:hover {
      border: 2px solid #456eb2;
      color: #456eb2;
      background-color: transparent;
    }
  }
  &.disabled {
    .MuiTypography-root {
      cursor: unset;
      text-decoration: line-through;
      color: rgba(0, 0, 0, 0.38);

      &:hover {
        border: 2px solid transparent;
        color: rgba(0, 0, 0, 0.38);
      }
    }
  }
}

/* Day name, day, time name text sizes */
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  user-select: none;
}

/* Style the input container (outside popper) */
.react-datepicker__input-container {
  .react-datepicker__calendar-icon {
    display: none;
  }
  input {
    display: none;
  }
}

/* (Optional) widen the tooltip */
#picker-siderbar-date-till-today-button-tooltip {
  width: 200px;
}

/* Hide the default popper arrow */
.react-datepicker__triangle {
  display: none;
}
.react-datepicker__time-list-item--selected {
  background-color: red;
}

.custom-date-picker-input {
  display: flex;
  align-items: center;
  justify-content: start;
  min-width: 140px;
  max-width: 400px;
  height: 40px;
  padding: 0 12px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .custom-input-icon {
    width: 13px;
    height: 16px;
  }
  &.outlined {
    border: 1px solid rgba(196, 196, 196, 1);
    color: rgba(0, 0, 0, 0.87);
    .custom-input-icon {
      color: rgba(0, 0, 0, 0.6);
    }
    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.87);
    }
  }
  &.filled {
    border: none;
    color: rgba(100, 116, 139, 1);
    .custom-input-icon {
      color: rgba(100, 116, 139, 1);
    }
    &:hover {
      background-color: rgba(241, 245, 249, 1);
    }
  }

  /* Text styling */
  .date-picker-input-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }
}

.day-of-month {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

/* If day is in the hovered range, color the entire cell */
.range-hover {
  background-color: rgba(230, 243, 252, 1);
  border-radius: 0;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: rgba(230, 243, 252, 1);
  border-radius: 0;
  color: #000;
}
