@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.engag-survey-no-cdp {
  display: flex;
  align-items: flex-start;
  padding: 32px;
  gap: 32px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px #00000026;
  img {
    width: 82px;
    height: 55px;
  }
  .engag-survey-no-cdp-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    font-size: 15px;
    font-weight: 400;
    color: #4a4c58;
    line-height: 20px;
    .no-cdp-details-tit {
      font-family: serif !important;
      font-size: 22px;
      font-weight: 700;
      line-height: 32px;
    }
  }
}
#main-engagement-survey {
  .survey-egag-alert {
    margin-bottom: 0;
  }

  .MuiRadioGroup-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-inline-start: -12px;
    margin-bottom: -15px;
    label {
      margin: 0;
    }
    .MuiTypography-root {
      font-size: 14px;
      font-weight: 500;
      color: #000000de;
    }
  }
  .engag-survey-disc {
    font-size: 14px;
    color: #00000099;
    margin-bottom: 18px;
    font-weight: 400;
  }
  .main-chat-survey {
    .chat-survey-container {
      display: flex;
      align-items: flex-start;
      gap: 18px;
    }
    .share-survey-container {
      background-color: #fff;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      border: 1px solid #e2e8f0;
      border-radius: 6px;
      width: 100%;
    }
    .engag-survey-accordion {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      .MuiAccordion-root {
        box-shadow: unset;
        transition: unset;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        padding: 16px;
        margin: 0;
        &::before {
          display: none;
        }
        .MuiAccordionSummary-root {
          padding: 0;
          min-height: auto;
          .MuiAccordionSummary-expandIconWrapper {
            svg {
              font-size: 20px;
              fill: #00000099;
            }
          }
          .MuiAccordionSummary-content {
            margin: 0;
            .accordion-header {
              font-size: 14px;
              font-weight: 500;
              color: #0f172a;
            }
          }
        }
        .MuiAccordionDetails-root {
          margin: 20px 0;
          padding: 0;
          .contant-tap-container {
            .MuiTabs-flexContainer {
              border-bottom: 1px solid #e2e8f0;
              button {
                color: #64748b;
                font-size: 14px;
                font-weight: 400;
                padding: 12px;
                &.Mui-selected {
                  color: #0f172a;
                  border-bottom: 3px solid #456eb2;
                  padding-bottom: 9px;
                }
              }
            }
          }
        }
      }
    }
    .chat-survey-img {
      width: calc(100% - 18px);
      min-height: 600px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px;
      border: 1px solid #e2e8f0;
      border-radius: 6px;
      background-color: #f1f5f9;
      justify-content: space-between;
      color: #0f172a;
      font-size: 14px;
      font-weight: 500;
      &.share-servay {
        min-height: 260px;

        max-height: 260px;
      }
      img {
        // min-width: 490px;
        width: 100%;
      }
    }
  }
  .survey-chat-fields-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .survey-chat-text-title {
    display: flex;
    align-items: center;
    gap: 2px;
    .MuiInputLabel-root {
      color: #000000de;
      font-size: 14px;
      font-weight: 400;
    }
    svg {
      font-size: 14px;
      color: #00000099;
      rotate: 180deg;
    }
  }

  .MuiOutlinedInput-root {
    width: 100%;
    height: 40px;
  }
  .survey-setting-field {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MuiOutlinedInput-root {
      max-width: 240px;
      @include ar {
        .MuiSelect-select.MuiSelect-outlined {
          padding-right: 14px;
          padding-left: 32px;
        }
      }
    }
  }
  .survey-chat-field {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .MuiInputLabel-root {
      color: #000000de;
      font-size: 14px;
      font-weight: 400;
    }
    .MuiFormHelperText-root {
      text-align: justify;
    }
    label {
      font-family: "Figtree";
      @include ar {
        font-family: "IBM Plex Sans Arabic";
      }
    }
    &.ar-contant {
      label {
        font-family: "IBM Plex Sans Arabic";
      }
      direction: rtl;
      .MuiSelect-select.MuiSelect-outlined {
        padding-right: 14px;
        padding-left: 32px;
      }
      .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
        right: unset;
        left: 7px;
      }
    }
    &.en-contant {
      label {
        font-family: "Figtree";
      }
      direction: ltr;
      .MuiSelect-select.MuiSelect-outlined {
        padding-right: 32px;
        padding-left: 14px;
      }
      .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
        right: 7px;
        left: unset;
      }
    }
  }
}
#ar-contant-survey-menu-list {
  direction: rtl;
}
#en-contant-survey-menu-list {
  direction: ltr;
}
