@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

//button default style to remove border and BG
%button-style {
  outline: none;
  background-color: transparent;
  border: none;
}

.widget-box {
  width: 100%;
  position: relative;
  z-index: 9;
}

.widget-title {
  border-bottom: 1px dashed #cbd5e1;
  font-family: "Graphik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  color: #334155;
}
.widget-title-section {
  font-size: 12px;
  margin-inline-start: 4px;
}
.MuiTableCell-root.MuiTableCell-head {
  span.widget-title-table-header {
    border-bottom: 1px dashed #cbd5e1;
    font-size: 12px;
    font-weight: 500;
    color: #64748b;
    text-transform: uppercase;
    width: max-content;
  }
}

.widget-subtitle {
  border-bottom: 1px dashed #cbd5e1;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #64748b;
}

.chart {
  position: relative;
}
.chart {
  display: flex;
  flex-direction: column;

  .chart-no-data,
  .fetch_wrapper {
    flex-grow: 1;
  }
}

.sub-dialect-checkbox {
  position: absolute;
  top: 50px;
  z-index: 9;
  @include en {
    left: 7px;
  }

  @include ar {
    right: 7px;
  }

  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    color: #5c5c5c;
  }

  svg {
    width: 20px;
  }
  &.custom-dashboard {
    position: static;
  }
}

.aiIconDiv {
  position: relative;

  img {
    width: 27px;
    height: 27px;
    transition: all 0.5s ease;
    filter: opacity(0.5) drop-shadow(0 0 0 #c4c4c4);

    &:hover {
      transition: all 0.5s ease;
      filter: none;
    }
  }
}

// ai & download icon STYLES
.icons-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ai-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;

  @include ar {
    padding-left: 10px;
    padding-right: unset;
  }
}
.show-ai-line {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    bottom: 0;
    width: 2px;
    height: 25px;
    background-color: #e2e8f0;
    transform: translateY(-50%);

    @include ar {
      right: 0;
      left: unset;
    }
  }
  &:hover {
    &::after {
      display: none;
    }
  }
}
// themes & subthems custom header
.themes_themestrends {
  display: flex;
  align-items: center;
  justify-content: center;

  .aiIconDiv {
    position: relative;
    padding-right: 10px;

    &::after {
      position: absolute;
      content: "";
      right: 0px;
      top: 0;
      bottom: 0;
      width: 2px;
      height: 25px;
      background-color: #e2e8f0;

      @include ar {
        right: unset;
        left: 0px;
      }
    }

    @include ar {
      padding-left: 15px;
      padding-right: unset;
    }
  }
}

.theme_options,
.ai_download_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.engagement-grid {
  padding-top: 25px;

  @include en {
    padding-left: 30px;
  }

  @include ar {
    padding-right: 30px;
  }
}

//handle download icon STYLES
.arrowBtn {
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  svg {
    color: #00000099;
  }

  &:hover {
    background-color: #f1f5f9;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .dropdown-contentDiv {
      visibility: visible;
      opacity: 1;
      z-index: 2;
    }
  }
}

.dropdown-contentDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 9;
  box-shadow:
    0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px rgb(0 0 0 / 14%),
    0px 1px 5px rgb(0 0 0 / 12%);
  border-radius: 4px;
  background: #ffffff;
  position: absolute;
  right: 0px;
  top: 40px;
  visibility: hidden;
  opacity: 0;
  padding: 7px 0px;

  @include ar {
    right: unset;
    left: 0;
  }

  .exportBtn {
    width: 170px;

    &:hover {
      transition: all 0.5s ease;
      background: #fafafa;
    }

    padding: 9px 16px;

    &:nth-child(2) {
      margin-bottom: 0px;
    }
  }
}

.exportBtn {
  @extend %button-style;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.arrowBtn {
  @extend %button-style;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
  z-index: 9;
}

//channel analytics
.dialect-subdialect-widget {
  .icon-after-title {
    @include en {
      margin-left: 3px !important;
    }
  }
}

//styles icons in different widgets
#agent_performance,
#agent_status {
  height: auto !important;
  .engag-overview {
    margin-top: -40px;
  }
  .box {
    border-radius: 4px;
    background: #f1f5f9;
    svg {
      width: 18px;
      height: 18px;
      color: #456eb2;
    }
  }
  .widget-subtitle {
    border-bottom: 1px dashed #94a3b8;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000de;
  }
  .response_header {
    margin-bottom: 20px;
    margin-top: 50px;
  }

  .response_data {
    font-weight: 600;
    font-size: 16px;
    color: #456eb2;
    span {
      span {
        font-weight: 600 !important;
        font-size: 18px !important;
      }

      display: inline-block !important;
    }

    @include ar {
      span {
        direction: ltr;
      }
    }
  }

  .response-data-percentage {
    @include ar {
      direction: ltr;
      margin-left: 35px;

      span {
        direction: ltr;
      }
    }

    span {
      font-size: 14px !important;
    }
  }

  .format-data-values {
    line-height: 0 !important;
  }

  .response-rate-percentage {
    margin-left: 5px;
  }

  .response_title {
    border-bottom: 0 !important;
  }
}

#agent_status {
  .box {
    svg {
      width: 13px;
      height: 13px;
      color: #ed6c02;
      &.avg_available_time,
      &.total_available_time {
        color: #4caf50;
      }
      &.avg_unavailable_time,
      &.total_unavailable_time {
        color: #94a3b8;
      }
    }
  }
}

.insights-icons,
.social-icons {
  display: contents;
}

#most_engaged_contacts {
  .icon-after-title {
    &.gmail {
      top: 0px;
    }
  }

  .insights-icons {
    .twitter-datasource-icon {
      top: 1px !important;

      @include ar {
        top: 3px !important;
      }
    }

    .instagram-datasource-icon {
      top: 2px !important;

      @include ar {
        top: 4px !important;
      }
    }
  }
}

#most_engaged_contacts,
#most_influential_contacts,
#verified_contacts {
  .ai_download_wrapper {
    position: absolute;

    @include en {
      right: 1%;
    }

    @include ar {
      left: 1%;
    }

    top: 3%;
  }

  .social-icons {
    .facebook {
      top: 4px !important;

      @include ar {
        top: 5px !important;
      }
    }
  }
}

#gender_distribution {
  .insights-icons {
    position: absolute;
    display: inline-block;

    @include en {
      top: 0px;
    }

    @include ar {
      right: 60px;
      top: 5px;
    }
  }

  .social-icons {
    position: absolute;
    display: inline-block;
  }
}

#top_cities {
  .insights-icons {
    display: inline-block;

    @include en {
      left: 65px;
      top: 2px;
    }

    @include ar {
      right: 30px;
      top: 3px;
    }
  }
}

#top_countries {
  .insights-icons {
    display: inline-block;

    @include en {
      left: 94px;
      top: 3px;
    }

    @include ar {
      right: 25px;
      top: 3px;
    }
  }
}

#top_countries,
#top_cities {
  .gmb-datasource-icon {
    @include en {
      margin-left: -5px;
    }
  }

  @include ar {
    top: 7px;
  }
}

.bread-crumb-page-style {
  .bread-crumb {
    margin-top: 20px;
  }
}

.twitter-icon {
  color: #1da1f2;
}

.instagram-icon {
  color: #e1306c;
}

.linkedIn-icon {
  color: #0a66c2;
}

.icon-after-title-news {
  @include en {
    margin-left: 5px;
  }

  @include ar {
    margin-right: 5px;
  }
}

.news-blogs-icon {
  color: #64748b;
}

.widget-boxes {
  width: 14px;
  height: 14px;
  margin: 0px;
  border-radius: 3px;
}

.chart-label-style {
  color: #64748b;
}

.chart-label-title {
  font-weight: 500;

  @include en {
    margin-right: 3px;
  }

  @include ar {
    margin-left: 3px;
  }
}

.flex-style {
  display: inline-flex;
  align-items: center;
  margin-bottom: 18px;
}

.label-text {
  .chart-label-style {
    font-size: 14px;
    img {
      vertical-align: middle;
      margin-inline-end: 2px;
      width: 16px;
      height: 16px;
    }
  }

  .MuiTypography-root {
    @include en {
      margin-left: 8px;
      margin-right: 16px;
    }

    @include ar {
      margin-left: 16px;
      margin-right: 8px;
    }
  }

  cursor: pointer;
}
.label-text.single-label-text {
  cursor: auto;
}
.chart-label-percent {
  margin: 0;
  color: #475569;
  font-weight: 500;
}

.select-groupby-dropdown {
  &.general-groupby-dropdown {
    width: 200px;
    &.no-groupby-label {
      @include en {
        width: 130px;
      }
      @include ar {
        width: 154px;
      }
    }
    @include en {
      margin-right: 10px;
    }
    @include ar {
      margin-left: 10px;
    }
    > .MuiFormControl-root {
      width: 100%;
    }
  }
  @include ar {
    label {
      transform: translate(35px, -9px) scale(0.75);
      &.genral-groupby-label {
        transform: translate(124px, -9px) scale(0.75);
      }
    }
  }
}

.view-widget-dropdown-field {
  width: 132px;
}
.view-widget-dropdown-field {
  .MuiInputLabel-outlined.Mui-focused {
    color: rgba(0, 0, 0, 0.6);
  }
  .Mui-focused {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.23);
    }
  }
}

.select-widget-dropdown {
  @include ar {
    .MuiSelect-select {
      display: flex;
      justify-content: flex-start;
    }

    label {
      background: #ffffff;
      padding: 0 8px;
    }

    fieldset {
      legend {
        text-align: right;

        span {
          display: none;
        }
      }
    }
  }

  .MuiSelect-select {
    padding: 8.3px 11px;
  }
}

.view-as-select-widget-dropdown {
  @include ar {
    .MuiSelect-select {
      display: flex;
      justify-content: flex-start;
      padding-inline-start: 12px;
    }

    label {
      background: #ffffff;
      padding: 0 8px;
    }

    fieldset {
      legend {
        text-align: right;

        span {
          display: none;
        }
      }
    }
    label {
      transform: translate(35px, -9px) scale(0.75);
    }
  }

  .MuiSelect-select {
    padding: 8.3px 11px;
  }
}

.select-scale-dropdown {
  @include ar {
    label {
      transform: translate(28px, -9px) scale(0.75);
    }
  }
}

.quick-inspect-card {
  .MuiTypography-body2 {
    font-weight: 500;
  }
}

.quick-inspect-card-legends {
  @include en {
    margin-left: -5px;
  }

  @include ar {
    margin-right: -5px;
  }
}

.response_data {
  span {
    font-weight: normal !important;
    font-size: 15px !important;
  }
}

.stats-value {
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 1 !important;

  span {
    font-size: 14px !important;
    margin: 0 !important;
    color: #334155 !important;
  }
}

#widget-header-title-tooltip-control {
  z-index: 99999;
}

.tooltip-custom-new {
  white-space: pre-line;
  padding: 13px;
  line-height: 14px;
  font-weight: 400;
  font-size: 12px;
}

.spa-tooltip-custom-new {
  white-space: pre-line;
  line-height: 14px;
  font-weight: 500;
  font-size: 12px;
  padding: 8px 10px;
  max-width: 178px;
}

.tooltip-not-applied-filter {
  white-space: pre-line;
  padding: 4px 8px;
  line-height: 14px;
  font-weight: 400;
  font-size: 12px;
}

.report-icon {
  color: #f1c363;

  @include en {
    margin-right: 6px;
  }

  @include ar {
    margin-left: 6px;
  }
}

.display-in-start {
  display: flex;
  align-items: flex-start;
  svg {
    top: 2px !important;
  }
}
.display-in-center {
  display: flex;
  align-items: center;
  svg {
    top: 2px !important;
  }
}

.tooltip-text {
  line-height: 17px;
}

.facebook-private-icon {
  @include en {
    top: 0 !important;
  }

  @include ar {
    top: 1px !important;
  }
}

.gmb-datasource-icon-sources {
  @include en {
    margin-bottom: -1px;
    top: 6px;
  }

  #top_countries,
  #top_cities {
    .gmb-datasource-icon {
      margin-bottom: -6px;
    }
  }
}

.topics-twitter-icon {
  .icon-after-title {
    font-size: 16px;
    position: relative;
    margin-left: 5px;
    top: 3px;
    width: 16px;
    &.tikTok-icon {
      width: 14px;
    }
    @include ar {
      margin-right: 5px;
      top: 5px;
    }
  }
}

.icon-after-title {
  font-size: 16px;
  position: relative;
  margin-left: 5px;

  &.instagram {
    color: #e1306c;
    width: 16px;
    height: 16px;
  }

  &.facebook {
    color: #4267b2;
    width: 16px;
    height: 16px;
  }

  &.intercom {
    color: #0057ff;
  }

  &.whatsapp {
    color: #25d366;
  }

  &.linkedIn {
    color: #0a66c2;
    width: 20px;
    height: 22px;
    top: 6px;
  }

  &.gmail {
    width: 16px;
    height: 22px;

    @include en {
      top: 8px;
    }

    @include ar {
      top: 8px;
    }
  }

  top: 3px;

  @include ar {
    margin-right: 5px;
    top: 5px;
  }
}

.social-icons {
  .facebook {
    font-size: 16px;
    top: 1px;

    @include ar {
      top: 2px;
    }
  }

  .instagram,
  .whatsapp-datasource-icon {
    font-size: 17px;
  }

  .twitter-icon {
    font-size: 18px;
  }

  .linkedIn-datasource-icon {
    top: 1px;
    height: 17px;
    @include ar {
      top: 3px;
    }
  }
}

//icons datasources
.insights-icons {
  .icon-after-title {
    @include ar {
      margin-right: 4px;
      top: 3px;
    }
  }

  .twitter-datasource-icon {
    font-size: 20px;
    left: 2px;

    @include en {
      top: 6px;
    }

    @include ar {
      top: 6px;
      right: 3px;
    }
  }

  .whatsapp-datasource-icon {
    font-size: 17px;
  }

  .intercom-datasource-icon {
    width: 16px;
  }

  .facebook-datasource-icon {
    font-size: 16px;
    width: 16px;
    top: 6px;
    @include ar {
      top: 4px;
    }
  }

  .gmail-datasource-icon {
    font-size: 16px;
    @include ar {
      top: 8px;
    }
  }

  .instagram-datasource-icon {
    font-size: 18px;
    width: 16px;

    @include en {
      top: 6px;
    }

    @include ar {
      top: 7px;
    }
  }

  .linkedIn-datasource-icon {
    top: 6px;
    width: 16px;
    @include ar {
      top: 7px;
    }
  }
}

#gmb-datasource-icon {
  margin-bottom: -2px;
  width: 18px;

  @include ar {
    top: 6px;
  }
}

// sentiment analysis new design
.sentiment-legends-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 0 45px;
}

.sentiment-labels {
  color: #334155;
  font-weight: 500;
  font-size: 18px;
  border: 2px solid #f1f5f9;
  border-radius: 5px;
  height: 40px;

  @include en {
    padding: 12px 15px 16px 12px;
  }

  @include ar {
    padding: 12px 15px 16px 12px;
  }

  .sentiment-label-percentage {
    @include en {
      margin-left: 15px;
    }

    @include ar {
      margin-right: 15px;
    }
  }

  .sentiment-label-name {
    margin: 0 32px;
    color: #8b9aaf;
    font-weight: 500;
    font-size: 12px;

    @include en {
      line-height: 18px;
    }

    @include ar {
      line-height: 5px;
    }
  }

  .sentiment-label-value {
    color: #64748b;
    font-size: 14px;
  }

  .btn-inspect-sentiment {
    @include en {
      float: right;
    }

    @include ar {
      float: left;
    }

    button {
      margin-top: 4px;
    }
  }
}
.preloader-msg-box-style {
  margin-top: 0px;
  margin-bottom: 20px;
}
.preloader-msg-text-style {
  color: #6d737a;
  font-style: "italic";
}
.filtering-main-box,
.filtering-main-box-interaction {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.filtering-main-box-interaction {
  margin-left: -20px;
}
.not-applied-icon {
  position: relative;
  top: 3px;
}

.widget-view-type {
  width: fit-content;
  background-color: #f1f5f9;
  margin: 0px 10px;
  padding: 5px 10px;
  border-radius: 35px;
  gap: 10px;
  .type-text {
    font-weight: 500;
    margin: 0px 5px;
  }
  .delete-icon {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.6);
  }
}
#custom-dashboard-widget-header-monitors {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .monitor-details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    margin-bottom: 10px;
    &.sidebar-widget {
      margin: 10px 0px;
    }
    .monitor-name {
      margin-inline-end: 8px;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.2px;
      color: #00000099;
    }
    .monitor-type-wrapper {
      display: flex;
      align-items: center;
      gap: 4px;
      background: #f1f5f9;
      padding: 5px;
      border-radius: 2px;
      .monitor-type-text {
        font-weight: 400;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
      }
      img {
        vertical-align: middle;
        width: 16px;
        height: 16px;
      }
    }
  }

  .customized-monitors-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;
    &.sidebar-widget {
      margin-top: 10px;
    }
    .customized-monitor-details {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      .customized-monitor-name {
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
      }
      img {
        vertical-align: middle;
        width: 16px;
        height: 16px;
      }
    }
  }
}

.widget-header-grid-item {
  height: fit-content;
  margin: 0 !important;
}
