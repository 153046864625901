@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.out-filters {
  position: fixed;
  width: 100%;
  height: 100%;
}
.main-engagement-filter-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1999;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: calc(100vh - 60px);
  top: 60px;
}
.main-engagement-filter {
  position: fixed;
  top: 60px;
  left: unset;
  right: 0;
  background-color: #fff;
  width: 345px;
  padding: 10px 0;
  height: calc(100vh - 60px);
  overflow: hidden;
  box-sizing: border-box;
  z-index: 2000;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 8px 10px -5px,
    rgba(0, 0, 0, 0.14) 0px 16px 24px 2px,
    rgba(0, 0, 0, 0.12) 0px 6px 30px 5px;
  transform: none;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  @include ar {
    left: 0;
    right: unset;
  }
  .main-filter-contant {
    height: calc(100% - 119px);
    overflow-y: scroll;
  }
  .select-menu {
    width: 100%;
    margin: 10px 0;
    .select-label {
      font-size: 14px;
      &[data-shrink="true"] {
        @include ar {
          transform: translate(138px, -8px) scale(0.75);
        }
      }
      @include ar {
        right: 20px;
      }
    }
    .select-input {
      height: 40px;
    }
    fieldset {
      @include ar {
        text-align: right;
      }
    }

    legend {
      font-size: 86px;
      @include ar {
        font-size: 50px;
      }
    }
  }
  .item-header {
    font-size: 13px;
    font-weight: 400;
    color: #475569;
  }
  .engagement-twitter-parent-id-wrapper {
    border-top: 1px solid #e2e8f0;
    padding: 25px 25px 15px;
    .MuiInputBase-root.MuiOutlinedInput-root {
      margin: 10px 0;
      height: 40px;
      width: 100%;
    }
  }
  .engagement-twitter-parent-id-title {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .filter-input {
    .MuiInputBase-root.MuiOutlinedInput-root {
      margin: 10px 0;
      height: 40px;
      width: 100%;
    }
    .user-click-add {
      color: #00000099;
      font-size: 11px;
      font-weight: 400;
      margin: -5px 10px;
    }
  }
  .selected-users-accounts-lable {
    height: 30px;
    margin-inline-end: 4px;
    margin-bottom: 4px;
    border-radius: 50px;
    text-transform: none !important;
    font-size: 14px !important;
    font-weight: 400;
  }
  .filter-boolean {
    label {
      margin: 0;
      display: flex;
      justify-content: space-between;
      .MuiFormControlLabel-label {
        color: #475569;
        font-size: 13px;
        font-weight: 400;
        line-height: 16.86px;
      }
    }
  }
  .filter-part {
    border-top: 1px solid #e2e8f0;
    padding: 25px 25px 15px;
  }

  .main-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .select-placeholder {
    color: #adadad;
    font-size: 14px;
  }
  .main-filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px 10px;
    .main-filter-header-title {
      display: flex;
      align-items: center;
      .filter-main-title {
        color: #456eb2;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 16.8px;
        margin: 3px 5px 0;
      }
      svg {
        color: #456eb2;
      }
    }
  }
}
.engagement-tags.untagged-engagements {
  border-bottom: 1px solid #e2e8f0;
}
#engagement-tooltip-parent-id {
  z-index: 2001;
}
.engagement-menu-item-countainer {
  display: flex;
  align-items: center;
  margin: 0;
  span {
    font-size: 14px;
    font-weight: 600;
  }
  .MuiCheckbox-root {
    padding: 7px 0;
    margin-inline-end: 15px;
  }
  .assign-item-countainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    .assign-item-details {
      font-size: 14px;
      font-weight: 400;
      color: #000000de;
      .assign-item-state-container {
        display: flex;
        align-items: center;
        gap: 4px;
        .status-circle {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          &.available {
            background-color: #4caf50;
          }
          &.busy,
          &.meeting,
          &.break {
            background-color: #ed6c02;
          }
          &.unavailable {
            background-color: rgba(148, 163, 184, 1);
          }
        }
      }
      .assign-item-state {
        color: #696d72;
        font-size: 12px;
      }
    }
  }
}
.engagement-menu-item-countainer.tag-filter-container {
  > p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }
  .MuiListItemText-root {
    margin-inline-start: 10px;
    span {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
    }
  }
  .MuiCheckbox-root {
    padding: 5px;
    padding: 5px;
    margin-inline-end: 7px;
    svg {
      font-size: 20px;
    }
  }
}
.engagement-filter-menu-item-option-container {
  flex: 1 1;
  max-width: 220px;
  @include ar {
    margin-left: 4px;
  }
  @include en {
    margin-right: 4px;
  }
  .engagement-menu-item-option {
    display: flex;
    flex: 1;
    align-items: center;
    gap: 10px;
    &-status {
      max-width: 8px;
      min-width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #4caf50;
      &.paused {
        background-color: #94a3b8;
      }
    }
    &-name {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #000000de;
      @include ar {
        text-align: right;
      }
    }
    &-datasources {
      display: flex;
      align-items: center;
      gap: 7px;
      justify-content: flex-end;
      flex: 1;

      .social-media-icon {
        margin: 0;
        .instagram-prvate-icon {
          color: #000000;
        }
        img.facebook-dm-icon {
          width: 20px;
          height: 20px;
          @include en {
            margin-right: -2px;
          }
          @include ar {
            margin-left: -2px;
          }
        }
      }
    }
  }
}

div.engagement-menu-item-option-tooltip {
  border-radius: 4px;
  max-width: 210px;
  text-wrap: initial;
  white-space: initial;
  direction: ltr;
  text-align: left;
  &.arabic-tooltip {
    direction: rtl;
    text-align: right;
  }
}

li.engagement-filter-monitors-menu-item {
  padding: 0px 5px 5px 7px;
}
