@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.header-title {
  font-size: 20px;
  font-weight: 500;
  @include en {
    margin: 10px 9px 16px 0px;
  }
  @include ar {
    margin: 10px 0px 16px 9px;
  }
}

.header-monitors {
  font-size: 15px;
  color: #0c192a;
  border-bottom: 1px dashed #cbd5e1;
  margin-bottom: 6px;
}

.dashboard-header-warning {
  color: #ec8623;
  @include en {
    margin-right: 6px;
    margin-bottom: 5px;
  }
  @include ar {
    margin-left: 6px;
  }
}

.header-tooltip-name {
  text-transform: capitalize;
  font-weight: 500;
  @include en {
    margin-right: 2px;
  }
  @include ar {
    margin-left: 2px;
  }
}

.header-with-filter {
  margin-bottom: 10px;
}

.header-without-filter {
  margin-bottom: 10px;
}

.main-pages-header {
  margin-inline-end: 10px;
  margin-bottom: 7px;
  font-weight: 500;
  font-size: 20px;
}

.date-margin {
  .aggregate-date-range-picker {
    margin: 0 20px;
  }
  @include ar {
    margin-left: -20px;
  }
}

.view-monitor-dasboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
