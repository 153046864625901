@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.user-avatar-main-wrapper {
  position: relative;
  border: none;
  font-weight: 600;
  color: #ffffff;
  border-radius: 50%;
  border: 1px solid #e2e8f0;
  .user-avatar-main-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  .user-avatar-default-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #64748b;
    cursor: default;
  }
  .user-avatar-status-indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    bottom: -2px;
    right: -2px;
    &.available {
      background-color: #4caf50;
    }
    &.busy,
    &.meeting,
    &.break {
      background-color: #ed6c02;
    }
    &.unavailable {
      background-color: rgba(148, 163, 184, 1);
    }
    @include ar {
      right: unset;
      left: -2px;
    }
  }
}
