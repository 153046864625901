@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
#bulk_tooltip {
  max-width: 130px;
}
#bulk-snackbar {
  text-align: left;
  width: 380px;
  @include ar {
    text-align: right;
    width: 320px;
  }
}
.tabs-wrapper {
  padding: 0 15px;
  border-bottom: 1px solid #e2e8f0;
  margin-top: -10px;
  .bulk-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 59.8px;
    .locked-engagement {
      display: none;
    }
    hr {
      height: 18px;
    }
    svg {
      cursor: pointer;
      padding: 8px;
      font-size: 16px;
      color: #475569;
      &:hover {
        background-color: #f8fafc;
      }
      &.bulk-disabled {
        color: #9e9e9e;
        &:hover {
          background-color: #ffffff;
        }
      }
    }
  }
  .MuiTabs-flexContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .MuiButtonBase-root.MuiTab-root {
    padding: 10px 0;
    min-width: 73px;
  }
  .tab-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #64748b;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    svg {
      width: 12px;
      height: 12px;
      margin-inline-start: 5px;
      color: #00000042;
    }
  }
  .tab-number {
    background-color: #94a3b8;
    padding: 0px 3px;
    min-width: 8px;
    height: 13px;
    display: flex;
    border-radius: 60px;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    align-items: center;
    justify-content: center;
  }
  .MuiButtonBase-root.MuiTab-root:hover {
    background-color: #f8fafc;
  }
  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    border-bottom: 3px solid #456eb2;
    background-color: #f8fafc;
    padding-bottom: 7px;
    .tab-name {
      color: #233759;
      font-weight: 600;
    }
  }
}
.tabs-panel-wrapper {
  height: calc(100% - 175px);
  flex-grow: 1;
  .tabs-panel-box {
    height: 100%;
    > div {
      height: 100%;
    }
  }
}
.no-data-side-bar-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  flex-direction: column;
  gap: 20px;
  font-size: 14px;
  color: #00000099;
  .no-data-side-bar-search {
    font-size: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.871);
  }
  .no-data-text {
    max-width: 253px;
    text-align: center;
  }
  .text-heading {
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0 0 15px;
  }
  .text-description {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #000000;
    margin: 0;
  }
  // h3.no-data-found{
  //   font-size: 17px;
  // }
  // span.try-adjust-date{
  //   text-align: center;
  //   font-size: 14px;
  // }
}
.tab-content-wrapper {
  height: 100%;
  overflow-y: scroll;
  // margin-top: -10px;
  // span.try-adjust-date.MuiTypography-root{
  //   max-width: 190px;
  // }
  // .no-data-side-bar-wrapper h3.no-data-found{

  //   font-weight: 500!important;
  //   font-size: 18px!important;
  // }
  .pagination-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    .pagination-loader-btn {
      width: 35px;
      height: 35px;
      border: 1px solid #456eb2;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        color: #456eb2;
        width: 13px;
        height: 25px;
      }
      &:hover {
        background-color: #e6f3fc80;
      }
      &.disabled {
        background-color: #fff;
        cursor: default;
        svg {
          color: #456eb2;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  &::-webkit-scrollbar-track {
    background: none;
    box-shadow: none;
  }
}

.main-cards-loader {
  box-sizing: border-box;
  background-color: #f0f4f7;
  max-width: 289px;
  min-height: 174px;
  border-radius: 4px;
  padding: 15px;
  margin: 0 auto;
  margin-block: 15px;
  .cards-loader-header {
    display: flex;
    align-items: center;
    .cards-loader-title {
      margin-inline-start: 10px;
    }
  }
  .cards-loader-contant {
    padding: 0px 165px 5px 30px;
    @include ar {
      padding: 0px 30px 5px 165px;
    }
  }
}
.card-engag-container {
  position: relative;
  display: flex;
  flex-direction: column;
  &.delete-card-animation {
    overflow: hidden;
  }
  .card-users-teams {
    display: flex;
    align-items: center;
    gap: 8px;
    @include ar {
      right: 30px;
      left: unset;
    }
    .card-user-img {
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      .card-user-img-styles-holder {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        .user-avatar-main-wrapper {
          border: 0;
        }
        &:hover,
        &.list-opened {
          border: 5px solid #e2e8f0;
        }
        &.disabled-agent {
          cursor: auto;
          border: none;
        }
        .user-avatar-default-img {
          cursor: pointer;
        }
      }
      &.team-name {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #00000061;
        font-size: 12px;
        cursor: pointer;
        height: 12px;
        .team-name-driver {
          border-inline-start: 1px solid #e2e8f0;
          height: 20px;
          margin-inline-end: 8px;
        }
        span {
          max-width: 150px;
          // text-align-last: end;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .team-name-exist {
          color: #00000099;
          font-size: 12px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 5px;
          span {
            border-bottom: 1px dashed #cbd5e1;
            max-width: 130px;
          }
        }
        &.with-sla {
          span {
            max-width: 83px;
          }
          .team-name-exist {
            span {
              max-width: 60px;
            }
          }
        }
      }
    }
    .card-users-container {
      position: absolute;
      background-color: #fff;
      width: 240px;
      top: 35px;
      box-shadow: 0px 3px 14px 2px #0000001f;
      border-radius: 4px;
      &.last-one {
        top: unset;
        bottom: 35px;
      }
      .users-list-loading {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: #00000099;
          height: 25px;
          width: 25px;
        }
      }
      &.teams-container {
        left: 35px;
        @include ar {
          right: 35px;
          left: unset;
        }
      }
      .main-card-users-empty {
        padding: 15px 16px;
        color: #000000de;
        font-size: 14px;
        font-weight: 500;
        p {
          color: #00000099;
          font-weight: 400;
        }

        .link-user-management {
          margin: 10px 0px;
        }
      }
      .main-card-users-list {
        max-height: 140px;
        overflow-y: auto;
        color: #666666;
        font-size: 14px;
        font-weight: 400;
        padding: 5px 0;
        > div {
          padding: 6px 16px;
        }
        .assign-item-countainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          &:hover {
            background-color: #fafafa;
          }
          &.selected-user {
            background-color: rgb(240 244 249);
          }
          &.item-loading {
            background-color: #fff;
            cursor: progress;
          }
          &.item-not-allowed-disabled {
            cursor: default;
            opacity: 0.5;
          }
          .assign-item-profile {
            position: relative;
            display: flex;
          }
          .assign-item-details {
            font-size: 14px;
            font-weight: 400;
            color: #000000de;
            .assign-item-state {
              color: #696d72;
              font-size: 12px;
              &.team-assign-item {
                display: flex;
                align-items: center;
                gap: 5px;
                div {
                  background-color: #4caf50;
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }
      .card-user-unassign {
        padding: 15px 16px;
        border-top: 1px solid #e2e8f0;
        font-size: 14px;
        color: #e31b0c;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        &.item-loading {
          background-color: #fff;
          cursor: progress;
        }
      }
    }
  }

  .main-engag-cards-item {
    padding: 10px;
    border: 1px solid #e2e8f0;
    margin: 5px 20px;

    border-radius: 4px;
    cursor: pointer;
    &:hover {
      border: 1px solid #64748b;
    }
    &.selected {
      border: 2px solid #456eb2;
    }
    .card-item-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .card-datasource {
        font-size: 12px;
        font-weight: 600;
        color: #456eb2;
        display: flex;
        align-items: center;
        gap: 8px;
        .engagement-card-item-new-tab {
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover {
            background: #f1f5f9;
          }
          svg {
            @include ar {
              transform: rotate(270deg);
            }
          }
        }
        .card-engag-state {
          opacity: 1;
          transition: all 0.3s ease-out;
          &.card-state-animation {
            opacity: 0.2;
          }
        }
        &.complete {
          color: #00000061;
        }
        .item-sentiment {
          border: 0.5px solid #e2e8f0;
          border-radius: 1.4px;
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 14px;
            height: 14px;
          }
          &.none {
            padding-bottom: 1.5px;
          }
          &.neutral {
            color: #f9a700;
          }

          &.positive {
            color: #8cc63f;
          }

          &.negative {
            color: #ed1846;
          }
        }
      }
      .main-img-acc-name {
        display: flex;
        align-items: center;
        .MuiCheckbox-root {
          visibility: hidden;
          opacity: 0;
          margin-inline-end: -31px;
          transition:
            visibility 0s linear 0.33s,
            opacity 0.33s linear,
            margin-inline-end 0.33s linear;
          padding: 6.8px;
          &.selected {
            visibility: visible;
            opacity: 1;
            margin-inline-end: 10px;
            transition-delay: 0s;
          }
          svg {
            width: 18px;
            height: 18px;
          }
          &:hover {
            background-color: #f5f5f5;
          }
        }
        .main-img {
          position: relative;
          visibility: visible;
          opacity: 1;
          margin-inline-end: 10px;
          transition:
            visibility 0s linear 0.33s,
            opacity 0.33s linear,
            margin-inline-end 0.33s linear;
          img {
            width: 30px;
            height: 30px;
            border: 0.824px solid #e2e8f0;
            border-radius: 50%;
          }
          .main-icon {
            position: absolute;
            bottom: 0;
            inset-inline-end: -3px;
            display: flex;
            width: 16px;
            height: 16px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            font-size: 11px;
            color: #ffffff;
            border: 2px solid white;
            img {
              border: none;
              width: 16px;
              height: 16px;
            }
          }
        }
        &.hovered {
          .MuiCheckbox-root {
            visibility: visible;
            opacity: 1;
            margin-inline-end: 10px;
            transition-delay: 0s;
          }

          .main-img {
            visibility: hidden;
            opacity: 0;
            margin-inline-end: -31px;
            transition-delay: 0s;
          }
        }
        .acc-name {
          display: flex;
          align-items: center;
          color: #000000de;
          font-size: 14px;
          font-weight: 600;
          line-height: 14.5px;
          letter-spacing: 0.15px;
          gap: 5px;
        }
        .acc-followers {
          display: flex;
          align-items: center;
          color: #00000099;
          gap: 5px;
          font-size: 12px;
          font-weight: 400;
          margin-top: 3px;
        }
      }
    }
    .card-item-contant {
      margin: 20px 0;
      font-size: 14px;
      font-weight: 400;
      color: #0c192a;
      padding-inline-end: 40px;
      max-height: 3.6em;
      line-height: 1.2em;
      overflow: hidden;
      text-overflow: ellipsis;
      &.ar {
        direction: rtl;
      }
      &.en {
        direction: ltr;
      }
    }
    .card-item-footer {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: space-between;
      height: 29px;
      .item-state {
        font-size: 14px;
        font-weight: 500;
        border-radius: 3px;
        text-align: center;
        line-height: 26px;
        letter-spacing: 0.46px;
        &.complete,
        &.reopen {
          color: #4caf50;
          background-color: #edf7ed;
          width: 107px;
        }
        &.new {
          color: #626cab;
          background-color: #e8eaf3;
          width: 70px;
        }
        &.active {
          color: #ed6c02;
          background-color: #fdf0e6;
          width: 88px;
        }
      }
      .card-item-state {
        display: flex;
        gap: 6px;
        align-items: center;
        color: #00000061;
        font-size: 12px;
        flex: 1;
        justify-content: flex-end;
        .sla-countainer {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 12px;
          font-weight: 500;
          color: #00000099;
          svg {
            width: 13px;
            height: 13px;
            margin: 0 8px;
          }
          .sla-item {
            font-size: 12px;
            border-bottom: 1px dashed #cbd5e1;
            margin-top: 1px;
          }
          .sla-counter {
            width: 30px;
            height: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            &.green {
              background-color: #ebf3ec;
              color: #3b873e;
            }
            &.orange {
              background-color: #fff5e5;
              color: #ff9900;
            }
            &.red {
              background-color: #feeceb;
              color: #f44336;
            }
          }
        }
      }
      .completion-reason {
        border-radius: 3px;
        background: #eee;
        display: flex;
        padding-inline: 8px;
        justify-content: center;
        align-items: center;
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
      }
    }
  }
  &.selected-checked {
    .main-engag-cards-item {
      background-color: #edf6fc;
      .card-item-header {
        .MuiCheckbox-root {
          &:hover {
            background-color: #e4ecf2;
          }
        }
      }
    }
  }
}
// .tab-content-wrapper {
//   .empty-search-side-bar {
//     height: 90%;
//     display: flex;
//     align-items: center;
//     h3.no-data-found.MuiTypography-root {
//       font-size: 18px !important;
//       font-weight: 500 !important;
//     }
//     span.try-adjust-date.MuiTypography-root {
//       text-align: center;
//       max-width: 340px;
//       font-size: 14px !important;
//       font-weight: 400 !important;
//     }
//   }
// }
.card-item-footer-ai-agent {
  display: flex;
  align-items: center;
  gap: 8px;
  .card-footer-ai-name {
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
  }
  .MuiAvatar-root.MuiAvatar-circular {
    width: 20px;
    height: 20px;
    border-radius: 0;
  }
}

div.engagement-card-item-new-tab-tooltip {
  left: -5px;
  padding: 8px 10px 8px 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: #fff;
  box-sizing: border-box;
  max-width: 171px;
  &.tab-limit {
    background: #f44336;
    span.tab-limit::before {
      background: #f44336;
    }
  }
}
