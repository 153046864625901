.monitor-statistics-grid-container {
  background-color: white;
  margin: 24px 0;
  border: 1px solid rgba(226, 232, 240, 1);
  border-radius: 5px;
}

.monitor-statistics-loading-grid-item {
  min-height: 150px;
}

.monitor-statistics-metric-grid-item {
  padding: 30px 20px;

  &.monitor-statistics-with-border {
    border-bottom: 1px solid rgba(226, 232, 240, 1);
  }
}

.monitor-statistics-metric-box {
  display: flex;
  gap: 15px;
  height: 50px;
}

.monitor-statistics-icon-box {
  background-color: rgba(241, 245, 249, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  border-radius: 4px;
  flex-shrink: 0;
}

.monitor-statistics-tooltip.MuiTooltip-tooltip {
  padding: 13px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.monitor-statistics-text-container {
  min-width: 0;
  max-width: 260px;
}

.monitor-statistics-metric-title.MuiTypography-root {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  line-height: 20px;
  font-weight: 500;
  border-bottom: 1px dashed rgba(148, 163, 184, 1);
  max-width: 100%;
  display: inline-block;
}

.monitor-statistics-metric-value.MuiTypography-root {
  font-size: 14px;
  color: rgba(69, 110, 178, 1);
  line-height: 16.8px;
  font-weight: 500;
  margin-top: 10px;
}

.monitor-widget-summary-icon {
  color: rgba(69, 110, 178, 1);
}
