@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
@mixin falcon-env {
  html[env="falcon"] & {
    @content;
  }
}
.summary-widget-wrapper{
  border: 1px solid #E2E8F0;
  background-color: #FFFFFF;
  border-radius: 5px;
  margin: 18px 0 30px;
}
.summary-header-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid #E2E8F0
}
.summary-header-description{
  display: flex;
  align-items: center;
  gap: 8px;
}
.summary-header-img{
  width: 36px;
  height: 36px;
  background-color: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-shadow: 0px 0px 14px 0px #0000000D;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  img{
    width: 23.7px;
    height: 20.5px;
    @include falcon-env {
      height: 23.7px;
    }
  }
}

.summary-header-title{
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;  
  color: rgba(0, 0, 0, 0.87);
  margin:0 0 3px;
}
.summary-header-hint{
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  span{
    color: #456EB2;
    margin-inline-start: 3px;
    cursor: pointer;
  }
}
.summary-header-info{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}
.summary-header-date{
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
}
.summary-header-status{
  padding: 7px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.summary-status-updated{
  color: #3B873E;
  background-color: rgba(59, 135, 62, .1);
}
.summary-status-outdated{
  color: #F44336;
  background-color: rgba(244, 67, 54, .1);
}
.summary-header-refresh{
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  cursor: pointer;
  &:hover{
    background-color: #F1F5F9;
  }
}
.summary-header-refresh.summary-refresh-disabled{
  color: rgba(0, 0, 0, 0.38);
  &:hover{
    background-color: transparent;
  }
}
.summary-content-wrapper{
  padding: 20px;
  min-height: 90px;
  .summary-main-content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.summary-content-box{
  border-inline-start: 1px solid #E2E8F0;
  padding: 0 16px 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #000000DE;
  width: 47%;
  margin: 0;
  span{
    font-weight: 600;
  }
}
.summary-more-wrapper{
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
  .summary-more-line{
    flex-grow: 1;
    background-color: #E2E8F0;
    height: 1px;
    display: inline-block;
  }
}
.summary-footer-wrapper{
  padding: 5px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.summary-footer-action{
  display: flex;
  align-items: center;
  gap: 16px;
  .footer-action-box{
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
    &:hover{
      background-color: #F1F5F9;
    }
  }
  .footer-action-box.action-like{
    color: #456EB2;
    background-color: #F1F5F9;
  }
  .footer-action-box.action-dislike{
    color: #F44336;
    background-color: rgba(244, 67, 54, .1);
  }
  .summary-widget-disabled{
    &:hover{
      background-color: transparent;
    }
  }
}
.summary-widget-disabled{
  opacity: .4!important;
}
.summary-span-tooltip{
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: inline-block;
  padding: 10px 13px;
  text-align: center;
}
.summary-filters-modal{
  .MuiPaper-root.MuiDialog-paper{
    min-width: 600px;
  }
}
.summary-filters-title.MuiDialogTitle-root{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 20px 24px;
  span{
    color: rgba(0, 0, 0, 0.87);
  }
  .summary-filters-close{
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    cursor: pointer;
  }
}
.summary-filters-content.MuiDialogContent-root{
  padding: 0;
}
.summary-filters-wrapper{
  height: 174px;
  padding: 24px;
  overflow: auto;
}
.summary-filters-box{
  margin: 0 0 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  .summary-filters-box-title{
    color: rgba(0, 0, 0, 0.87);
  }
  .summary-filters-box-info{
    color:rgba(0, 0, 0, 0.6);
  }
}
.summary-filters-actions.MuiDialogActions-root{
  padding: 16px 24px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.summary-Loading-wrapper{
  padding: 25px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.summary-Loading-img{
  position: relative;
  .summary-logo{
    width: 30px;
    height: 26px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    @include falcon-env {
      height: 30px;
    }
  }
  .summary-processing {
    animation: rotate 1s linear infinite;
    width: 50px;
    height: 50px;
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.summary-Loading-title{
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
  margin: 16px 0 0;
}
.summary-empty-wrapper{
  text-align: center;
  padding: 30px 20px 20px;
  .summary-empty-img{
    margin-bottom: 12px;
  }
}
.summary-empty-title{
  margin: 0 0 4px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
}
.summary-empty-hint{
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.6);
}
.summary-token-span{
  img{
    margin: -4px 3px;
  }
}
.summary-date{
  @include ar {
    direction: ltr;
    display: inline-block;
  }
}