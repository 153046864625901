@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.audience-table {
  margin-bottom: 25px;
  .header-box {
    display: flex;
    align-items: center;
  }
  .audience-date {
    display: flex;
    margin: 0 20px;
  }
  .audience-date {
    .monitor__btn {
      &.MuiLoadingButton-root {
        @include ar {
          margin-left: 0px !important;
          margin-right: 14px;
        }
      }
    }
  }
  .audience-date .date-field {
    margin-inline-end: -20px;
  }
  .audience-date .MuiInputBase-formControl {
    height: 40px;
    background-color: #fff;
  }
  .audience-title h3 {
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    font-weight: 500;
    font-style: normal;
    margin-bottom: 10px;
  }
  .audience-title span {
    display: block;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    color: #696d72;
    margin-bottom: 20px;
  }
  .search-box {
    position: relative;
    height: 40px;
    width: 257px;
    .MuiFormHelperText-root.Mui-error {
      width: 300px;
      display: flex;
    }
    .search-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      font-size: 18px;
      pointer-events: none;
    }
    .search-icon-container {
      position: absolute;
      top: 1%;
      width: 44px;
      height: 39px;
      background-color: #7f7f7f;
      border-style: none;
      cursor: pointer;
    }
    .search-header button {
      min-width: 0;
    }
    .search-header {
      .search-audience {
        margin-inline-end: 14px;
      }
    }
    .search-icon-container-blue {
      background-color: #456eb2;
    }
    .search-icon-container-red {
      background-color: #ff0000;
    }
    @include ar {
      .search-icon-container {
        border-radius: 3px 0px 0px 3px;
        right: 82.8%;
      }
      .search-icon {
        left: 13px;
      }
    }
    @include en {
      .search-icon-container {
        border-radius: 0px 3px 3px 0px;
        left: 82.8%;
      }
      .search-icon {
        right: 13px;
      }
    }
    .search-field {
      width: 100%;
      height: 100%;

      @include ar {
        label {
          transform: translate(130px, 10px) scale(1);

          &.Mui-focused,
          &.MuiFormLabel-filled {
            transform: translate(160px, -9px) scale(0.75);
            background: #f8fafc;
            padding: 0 8px;
          }
        }
        fieldset {
          legend {
            span {
              display: none;
            }
          }
        }
      }
      @include en {
        label {
          transform: translate(14px, 10px) scale(1);
          &.Mui-focused,
          &.MuiFormLabel-filled {
            transform: translate(14px, -9px) scale(0.75);
          }
        }
      }
      .MuiInputBase-formControl {
        &:hover {
          fieldset {
            border-color: #456eb2;
          }
        }
      }
      input {
        padding: 8.5px;
      }
    }
  }
}
.audience-filter-btn {
  height: 42px;
  @include en {
    padding: 6px 75px;
    margin-left: -10px !important;
  }
  @include ar {
    padding: 6px 75px;
  }
}

.audience-header-wrapper,
.audience-date-options-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.audience-date-options-wrapper {
  margin-top: -18px;
}

#managed-audience-menu {
  .MuiPaper-root {
    min-width: 290px;
    @include ar {
      left: 164px !important;
    }
  }
}
#managed-audience-menu-active-filter {
  .MuiPaper-root {
    min-width: 290px;
    @include ar {
      left: 292px !important;
    }
  }
}
.filter-box {
  margin-inline-start: 14px;

  .MuiLoadingButton-loadingIndicatorStart {
    @include en {
      margin-inline-start: -10px;
    }
    @include ar {
      margin-inline-end: 1px;
    }
  }
}
.empty-cdp-picker {
  display: none;
}
