#disable-feature-popup {
  min-width: 600px;
  .security-desc {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .security-desc-second {
    font-size: 16px;
    font-weight: 600;
  }
}
