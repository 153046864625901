@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.sec-container {
  .view-table-countainer {
    border: 1px solid #e2e8f0;
    border-bottom: none;
    .view-table-row {
      display: flex;
      width: 100%;
      min-height: 52px;
      align-items: center;
      border-bottom: 1px solid #e2e8f0;
      .view-table-cell {
        height: 100%;
        min-height: 52px;
        display: flex;
        align-items: center;
        color: #000000de;
        font-size: 13px;
        font-weight: 500;
        padding: 0px 12px;
        border-inline-start: 1px solid #e2e8f0;
        width: calc(26% + 25px);
        > svg.view-table-cell-icon {
          color: #bdbdbd;
          width: 15px;
          height: 15px;
          rotate: 180deg;
          margin-inline-start: 5px;
        }
        .checked-cell {
          display: flex;
          align-items: center;
          gap: 10px;
          color: #000000de;
          font-size: 12px;
          font-weight: 400;
          svg {
            color: #89bb2a;
            font-size: 13px;
            padding-inline-start: 5px;
          }
        }
        &.first-cell {
          border: none;
          width: 20%;
          background-color: #f8fafc;
        }
        &.INBOX,
        &.PUBLISH,
        &.REPLY {
          width: 13%;
        }
        &.SL_ENGAGEMENT,
        &.OC_ENGAGEMENT,
        &.ENGAGEMENT_ANALYTICS {
          width: 14%;
        }
        &.CREATE_TAG,
        &.EDIT_TAG,
        &.DELETE_DISABLE_TAG,
        &.CREATE_SAVED_REPLY,
        &.EDIT_SAVED_REPLY,
        &.DELETE_SAVED_REPLY,
        &.CREATE_COLLECTION,
        &.EDIT_COLLECTION,
        &.DELETE_COLLECTION {
          width: 24%;
        }

        .view-table-cell-account {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 12px;
          font-weight: 500;
          color: #000000de;
          .account-acc-data-details {
            div {
              width: fit-content;
            }
          }
          .account-acc-data-handle {
            color: #00000099;
            font-weight: 400;
            width: fit-content;
          }
          .account-acc-img {
            width: 32px;
            height: 32px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .account-acc-data-source {
              position: absolute;
              width: 16px;
              height: 16px;
              bottom: -5px;
              right: -5px;
              background-color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              @include ar {
                right: unset;
                left: -5px;
              }
              .social-media-icon {
                margin: 0;
                width: 70%;
                height: 70%;
                svg {
                  width: 100%;
                  height: 100%;
                }
                img {
                  border-radius: 0;
                }
              }
              &.FACEBOOK {
                .social-media-icon {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  .collapse-cards-cont {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
    .monitors-acc-cell {
      color: #000000de;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      grid-gap: 5px;
      gap: 5px;
      padding: 0 16px;
      width: 216px;
      height: 84px;
      justify-content: center;
      border: 1px solid #e2e8f0;
      border-radius: 4px;
      .monitors-acc-cell-name {
        width: fit-content;
      }
      .monitors-acc-details {
        display: flex;
        align-items: center;
        color: #00000099;
        font-size: 12px;
        font-weight: 400;
        .monitors-acc-dott {
          width: 3px;
          height: 3px;
          background-color: #636465;
          border-radius: 50%;
          margin: 0px 5px;
        }
        .monitors-acc-type-name {
          color: #000000de;
          margin: 0 5px;
        }
        .monitors-acc-data-source {
          display: flex;
          align-items: center;
          margin: 0 5px;
          img,
          svg {
            width: 13px;
            height: 13px;
          }
        }
      }
    }
  }

  .collapse-container {
    .MuiPaper-elevation.MuiPaper-rounded {
      box-shadow: none;
      border: 1px solid #e2e8f0;
      border-radius: 0;
      &::before {
        display: none;
      }
    }
    .MuiCollapse-vertical.MuiCollapse-entered {
      padding: 15px;
      border-top: 1px solid #e2e8f0;
    }
    .MuiAccordionDetails-root {
      padding: 0;
    }
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 12px 0;
    }
    .MuiAccordionSummary-expandIconWrapper {
      svg {
        color: #616161;
        width: 30px;
      }
    }
    .MuiAccordionSummary-root {
      padding: 0 10px;
      background-color: #f8fafc;
      height: 52px;
      min-height: 52px;
    }
  }
  .sec-title {
    color: #0f172a;
    font-size: 14px;
    margin-bottom: 8px;
    &.space-top {
      margin-top: 10px;
    }
  }
  .collapse-title {
    font-size: 13px;
    color: #000000de;
  }
  #engag-acc-cell-tooltip {
    max-width: 180px;
  }
}
