@import "../../utils/colors.scss";

@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

@media (min-width: 1600px) {
  .MuiContainer-root {
    max-width: 2450px !important;
  }
}
#main-network-faild {
  .MuiAlert-icon {
    display: flex;
    align-items: center;
  }
  #network-faild-snack {
    display: flex;
    max-width: 355px;
    font-size: 14px;
    color: #621b16;
    padding-inline-end: 10px;
    gap: 20px;
    @include ar {
      > div {
        max-width: 220px;
      }
    }
    button {
      font-size: 13px;
      padding: 0;
      font-weight: 500;
      color: #621b16;
      white-space: nowrap;

      &:hover {
        background-color: #feefee;
      }
    }
  }
}
.custom-container {
  padding-inline: 20px;
}
.header-container {
  position: relative;
  height: 60px;

  #mainHeader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}
#mainHeader {
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: #000000;

  .MuiGrid-container {
    background-color: transparent;
  }
  .navbar-padding {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    button {
      opacity: 1;
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
    }
    min-height: 60px;
    .header-logo {
      padding: 0;
    }
  }
  .navbar-setting {
    button {
      padding: 0;
    }
    .resource-center-icon {
      color: #fff;
      display: inline-block;
      width: 45px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999999;
      text-align: center;
      position: relative;
      margin: 0 20px;
      svg {
        font-size: 20px;
      }
      &:hover {
        transition: all 0.5s ease;
        color: #456eb2;
      }
      &:after {
        content: "";
        width: 0;
        height: 30px;
        position: absolute;
        border: 1px solid #64748b;
        top: 50%;
        transform: translateY(-50%);
        @include en {
          right: -10px;
        }
        @include ar {
          left: -10px;
        }
      }
    }
  }
  .navbar-header,
  .navbar-setting,
  .profile-btn-box {
    display: flex;
    min-height: 60px;
  }

  .navbar-header {
    .selected-route {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    .btn-dropdown-nav {
      padding: 0 10px;
      white-space: nowrap;
    }
    button {
      padding: 0;
      min-width: auto;
      > a {
        padding: 0 10px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .MuiTab-root {
    p {
      margin: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-self: center;
      font-weight: normal;
      font-size: 16px;
    }

    &:hover {
      transition: all 0.5s ease;
      color: #456eb2;
    }
  }

  .PrivateTabIndicator-colorSecondary {
    background-color: green;
  }

  .MuiAvatar-root {
    width: 40px;
    height: 40px;
    cursor: default;
  }

  .MuiTabs-indicator {
    background-color: green;
  }

  .MuiButton-root {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    padding: 0;
    &:hover {
      transition: all 0.5s ease;
      color: #5a8fe6;
    }
  }

  .MuiButton-root.selected-route {
    &.MuiButton-root {
      font-weight: 500;
      color: #5a8fe6;
    }
  }

  .Mui-selected {
    color: #5a8fe6;
  }

  .MuiGrid-item:last-child {
    text-align: end;
    background-color: transparent;
  }

  .MuiGrid-item:first-child {
    margin: auto;
  }

  .settingsBtn {
    min-width: 25px;

    a {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }

    @include en {
      margin-left: auto;
    }

    @include ar {
      margin-right: auto;
    }
  }

  .notificationBtn {
    min-width: auto;
    .MuiBadge-badge {
      inset-inline-end: 4px;
      top: 0px;
      background: #e50035;
      color: #fff;
      font-weight: 600;
      font-size: 12px;
      height: 10px;
      border-radius: 30px;
      padding: 6px 4px;
      display: inline-block;
      line-height: 0;
    }
  }

  .MuiTab-labelIcon {
    max-width: fit-content;
    min-width: fit-content;
  }
}

.header-divider {
  margin: 10px 0 !important;
}

.profile-details {
  margin-inline-start: 8px;
}

html[lang="rtl"] #mainHeader {
  margin-left: 50px;
}

.sidebar__link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.handle-profile-menu {
  a {
    display: flex;
  }
}

.selected-route {
  color: #5a8fe6 !important;
  font-weight: 500;
  font-size: 16px;

  svg {
    color: #5a8fe6 !important;
    font-weight: 400 !important;
  }

  .MuiListItemText-root {
    span {
      color: #5a8fe6 !important;
    }
  }
}

.MuiListItemText-root {
  span {
    margin: 0px -10px;
  }
}

.dropdown_select {
  .MuiListItemText-root {
    span {
      margin-left: 0px !important;
      padding-left: 10px;
      padding-right: 5px;
    }
  }
}

.social-listening-menu {
  margin-inline-start: 9px !important;
  ul {
    min-width: 180px;
    li.MuiButtonBase-root.MuiMenuItem-root.Mui-focusVisible {
      background-color: transparent !important;
    }
  }
}

.channel-analytics-menu {
  ul {
    min-width: 180px;
    li.MuiButtonBase-root.MuiMenuItem-root.Mui-focusVisible {
      background-color: transparent !important;
    }
  }
  margin-inline-start: 11px !important;
}

.social-listening-menu,
.channel-analytics-menu,
.menu-settings {
  z-index: 2000 !important;
}

.navbar_alert {
  margin-right: auto !important;

  @include ar {
    margin-left: auto !important;
    margin-right: 0 !important;
  }
}

.style-dropdown-info {
  width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: -12px;

  @include ar {
    direction: ltr;
    width: 125px;
  }
}

.profile-btn {
  svg {
    @include ar {
      margin-right: 15px;
    }
  }
}

.menu-list {
  padding: 0px !important;
  cursor: default !important;

  a {
    width: 100%;
    font-size: 15px;
    color: #64748b;
    font-weight: 500;
    padding: 10px;
    cursor: pointer !important;

    &:hover {
      background-color: #fafafa !important;
    }
  }
}

.menu-profile-item {
  color: #475569 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  .external-icon {
    font-size: 12px;
    line-height: 12px;
    color: rgba(100, 116, 139, 0.6);
    margin-inline-start: 8px;
    display: inline-block;
  }
}

.menu-profile-item-last {
  color: #475569 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  .change-lang {
    margin-inline-start: 10px;
    display: inline-block;
  }
}

.menu-profile-item-last,
.menu-profile-item {
  cursor: default !important;

  a,
  span {
    width: 100%;
    cursor: pointer !important;
  }
}

.profile-name {
  width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;

  span {
    color: #334155;
    font-size: 15px;
    font-weight: 500;
  }
}

.profile-email {
  color: #80868c;
  font-size: 12px;
  font-weight: 400;
  cursor: default;
}

.dropdown-menu-arrow[aria-expanded="true"] .MuiButton-endIcon .MuiSvgIcon-root {
  transform: rotate(180deg);
  margin-top: 4px;
}
.dropdown-menu-arrow {
  &.header-dropdown-menu {
    padding-right: 0px;
    .MuiButton-endIcon {
      margin: 0px;
    }
  }
}

#mainHeader .MuiButton-endIcon {
  @include ar {
    margin-right: 4px !important;
  }
}
.title-beta {
  font-size: 12px;
  font-weight: 400;
  margin-inline-start: 5px;
  color: #c79120;
  text-transform: uppercase;
  line-height: 14.4px;
}

.header-beta {
  &.MuiChip-root {
    color: #ffb400;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
    margin-inline-start: 5px;
    height: 100%;
    span {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: normal;
    }
  }
  span {
    padding: 0px;
  }
}
.audience-route {
  padding: 0px 15px;
}
.header-img {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  a {
    padding-inline-end: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
.navbar-setting {
  .btn-group {
    min-width: auto;
    width: 45px;
    a {
      svg {
        margin: 0;
      }
    }
  }
}
.profile-btn-box {
  .user-avatar-status-indicator {
    width: 14px;
    height: 14px;
    bottom: -3px;
    box-sizing: border-box;
  }
  p {
    margin: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #e2e8f0;
    font-weight: 700;
    font-size: 15px;
    color: #334155;
  }
  #main-header-user-image {
    position: relative;

    .profile-btn-box-img-wrapper {
      width: 40px;
      height: 40px;
      font-size: 17px;
    }
    .user-avatar-main-wrapper {
      border: 0;
    }
  }
  .main-header-preloader-img {
    width: 40px;
    height: 40px;
    background-color: #282828;
    border-radius: 50%;
  }
}
.menu-profile-item {
  background-color: #000000;
  &:hover {
    background-color: #fafafa !important;
  }
}
.menu-profile-item-last {
  &:hover {
    background-color: #fafafa !important;
  }
}
.handle-profile-menu {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px 16px;
  .user-img-navbar {
    margin: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #e2e8f0;
    font-weight: 700;
    font-size: 15px;
    color: #334155;
    cursor: default;
  }
  .user-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #334155;
    line-height: 17px;
  }
  .profile-email {
    font-weight: 300;
    font-size: 12px;
    color: #80868c;
    margin-bottom: 8px;
  }
  .profile-settings {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #456eb2;
  }
}
.utilties-divider-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  min-height: 60px;
}
.utilties-divider {
  width: 1px;
  height: 24px;
  background-color: #64748b;
  display: inline-block;
}

.agent-status-dropdown-container {
  margin: 15px 0px;
  padding: 0 16px;
}
.agent-status-dropdown-root {
  z-index: 2001 !important;
  .agent-status-dropdown-item {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    &-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.15px;
    }
    &-circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
}
#navbar-agent-status-tooltip {
  z-index: 999999;
  max-width: 140px;
}
#resource-center-tooltip {
  z-index: 9999;
}
#main-header-notifications-btn {
  a {
    font-size: 20px;
  }
}
#agent-status-tooltip {
  .MuiTooltip-tooltip {
    background-color: #000000;
    padding: 8px 10px;
    font-size: 14px;
    .MuiTooltip-arrow {
      color: #000000;
    }
  }
}
