// language mixin
@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.datepicker_wrapper {
  .MuiFormControl-root {
    width: 100%;
  }
  svg {
    display: none;
  }
  .MuiOutlinedInput-root {
    margin: 0 !important;
  }
  .dateRange {
    input {
      width: 100%;
    }
  }
}

.benchmark-title {
  margin-top: 40px;
  h6 {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.015em;
    color: #0c192a;
    @include ar {
      font-weight: 700 !important;
    }
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #696d72;
  }
}

.benchmark-header {
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  padding: 22px !important;
  .benchmark-header-hint {
    margin-bottom: 30px;
    color: rgba(0, 0, 0, 0.6);
  }
  .benchmark-header-box {
    display: flex;
    justify-content: flex-start;
    .benchmark-date-range-picker {
      margin-inline-start: 20px;
      margin-inline-end: 20px;
    }
  }
  .MuiFormControl-root .MuiFormHelperText-root {
    @include ar {
      text-align: right !important;
    }
  }
  .MuiFormControl-root .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      @include ar {
        text-align: right !important;
      }
    }
  }
  .MuiFormControl-root.benchmark-container {
    width: 195px !important;
    margin-inline-end: 20px;
    .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
      padding-right: 14px;
    }
    @include ar {
      .MuiInputLabel-root {
        font-family: "Dubai";
      }
      .MuiOutlinedInput-notchedOutline {
        text-align: right !important;
      }
      .MuiOutlinedInput-root .MuiSvgIcon-root {
        transform: translate(-229px, 0px) scale(1) !important;
      }
      .MuiInputLabel-root {
        transform: translateY(16px) !important;
        left: unset;
        right: 10px;
        &.Mui-focused,
        &.MuiFormLabel-filled {
          transform: translateY(-12px) !important;
        }
      }
    }
  }
  .MuiFormControl-root.benchmark-container.last-benchmark-container {
    margin-inline-end: 0;
  }
  .datepicker-box {
    .MuiFormControl-root {
      width: 277px;
      margin: 0px;
    }
  }

  .dropdown-benchmark-items {
    @include ar {
      direction: rtl;
    }
  }
  .dateRange .MuiInputBase-formControl input {
    width: 151px !important;
  }
  .dateRange .MuiOutlinedInput-root {
    height: 43px !important;
  }
  .dateRange .MuiInputBase-formControl fieldset {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    box-shadow: none !important;
  }
  @include ar {
    .date-field svg {
      right: 12% !important;
    }
  }
}

.benchmark_wrapper {
  padding: 10px 20px 25px;
}
.monitor_type {
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  font-weight: 500;
}
.twitter_type {
  color: #1da1f2;
  padding: 0px 10px;
}

///// --Widget Header-- /////
.widget-header {
  color: #64748b;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 10px;
}
///// tables
.table_column_head {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  position: relative;
  text-transform: capitalize !important;
  &:last-of-type {
    &::after {
      width: 0;
    }
  }
  &::after {
    position: absolute;
    content: "";
    height: 14px;
    width: 2px;
    background-color: #e0e0e0;
    right: 0;
    @include ar {
      right: unset;
      left: 0;
    }
  }
}
.cell_sticky {
  position: sticky;
  left: 0;
  background: white;
  box-shadow: 5px 2px 5px grey;
}
.table_row_head {
  color: #334155 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  letter-spacing: 0.25px;
  img {
    margin: 0px 10px;
  }
  span {
    &:first-child {
      width: 40px;
      display: inline-block;
    }
  }
  text-align: left;
  @include ar {
    text-align: right;
  }
}
.table-row-cell {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 17px !important;
  font-weight: 400 !important;
}
.table_row {
  background-color: #fff;
  &:nth-of-type(odd) {
    background-color: #fff !important;
  }
  &.sentiment {
    .table_column_head {
      color: #334155 !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      letter-spacing: 0.25px !important;
      text-align: left;
      &::after {
        width: 0;
      }
      @include ar {
        text-align: right;
      }
    }
  }
}

/////
.table-highlight-cell {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 17px !important;
  font-weight: 500 !important;
  background: rgba(42, 170, 226, 0.1);
}

.positive_icon {
  color: #8cc63f;
  font-size: 18px;
}
.negative_icon {
  color: #ed1846;
  font-size: 18px;
}
.meh_icon {
  color: #f5b819;
  font-size: 18px;
  @include en {
    margin-right: 5px;
  }
  @include ar {
    margin-left: 5px;
  }
}

.empty-bench-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 57px;
  .empty-data-box {
    max-width: 540px;
    a {
      text-wrap: nowrap;
    }
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #64748b;
    b {
      font-weight: 500;
    }
  }
  img {
    margin-bottom: 26px;
  }
}

// SOV
.sov_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.benchmark-checkbox {
  @include ar {
    margin-left: 19px !important;
    margin-right: 0 !important;
  }
  @include en {
    margin-right: 19px !important;
  }
}
.benchmark-head-divider {
  margin-top: 26px !important;
  margin-bottom: 13px !important;
}
.benchmark-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  background-color: #ffff;
  margin-top: 30px;
  .benchmark-head-text {
    color: #696d72;
    font-weight: 400;
    font-size: 13px;
    b {
      font-weight: 500;
      @include ar {
        margin-left: 5px;
      }
    }
  }
  .benchmark-head-action {
    display: flex;
    align-items: center;
    justify-content: center;
    button:first-child {
      width: 165px;
    }
    .btn-export-pdf {
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 15px;
      background: unset;
      color: #767d87;
      margin-left: 14px !important;
      padding-left: 16px !important;
      padding-right: 16px !important;
      &.blue {
        color: #456eb2;
      }
      @include ar {
        .MuiCircularProgress-root {
          position: relative;
          left: 105px;
        }
      }
      &:hover {
        background-color: #e6f3fc !important;
        box-shadow: none;
      }
      p {
        text-transform: none !important;
      }
    }
  }
}

.benchmark-monitor-loading-box {
  position: relative;
  width: 100%;
  height: 220px;
  margin-bottom: 200px;
  .circular_loading {
    position: absolute !important;
    top: 50% !important;
    right: 50% !important;
  }
}

.benchmark-monitor-table-container {
  box-shadow: none !important;
  margin-bottom: 80px;
  .MuiTableHead-root {
    border-bottom: unset;
  }
  .body-tr-head {
    height: 56px;
    background: #fff !important;
    border: 1px solid #cbd5e1 !important;
    .metrics-title-vs-monitor:first-child {
      &::after {
        background: none !important;
      }
      @include ar {
        text-align: right;
      }
    }
    th:first-child {
      @include ar {
        text-align: right;
      }
    }
    th {
      border-bottom: unset;
      background: rgba(255, 255, 255, 0.002);
      position: relative;
      text-transform: capitalize;
      width: 200px;
      @include en {
        padding: 16px 10px 16px 16px;
      }

      @include ar {
        padding: 16px 16px 16px 10px;
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.17px;
        color: rgba(0, 0, 0, 0.87);
        text-transform: capitalize;
      }
      .social-media-icon {
        display: inline-flex;
      }

      &::after {
        position: absolute;
        content: "";
        height: 14px;
        width: 2px;
        background-color: #e0e0e0;
        right: 0;
        top: 22px;
        @include ar {
          right: auto;
          left: 0;
        }
      }

      &:last-of-type {
        &::after {
          width: 0;
        }
      }
    }

    .width-title-header {
      min-width: 20rem !important;
    }
  }

  .MuiTableBody-root {
    .body-tr-title {
      height: 52px;
      &:first-of-type {
        th {
          border: 1px solid #cbd5e1;
        }
      }
      th {
        border-right: 1px solid #cbd5e1;
        border-left: 1px solid #cbd5e1;
        border-bottom: none;
        background: #f8fafc;

        font-weight: 600;
        letter-spacing: 1.45px;
        text-transform: uppercase !important;
        color: #64748b;
        font-size: 14px;
        line-height: 21px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        &.sticky {
          font-size: 10px;
          line-height: 15px;
          border: 1px solid rgba(0, 0, 0, 0.12);
          border-top: unset;
          @include ar {
            font-family: "Dubai";
            font-style: normal;
            font-weight: 700 !important;
            font-size: 14px !important;
            letter-spacing: 0px !important;
          }
        }
      }

      .widget-title-benchmark {
        @include ar {
          text-align: right;
          font-family: "Dubai";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          letter-spacing: 0px !important;
        }
        background: #f8fafc !important;
        text-transform: uppercase !important;
        letter-spacing: 1.45px;
        font-weight: 600;
        color: #64748b;
        font-size: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        height: 52px;
      }
    }
    .body-tr-data {
      border-bottom: unset;
      height: 55px;
      background: rgba(255, 255, 255, 0.002);
      border: 1px solid #cbd5e1 !important;
      .MuiTableCell-root {
        padding: 0px 20px;
      }
      th {
        position: relative;
        @include ar {
          text-align: right;
        }
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #334155;
        }
        img {
          @include en {
            margin-right: 10px;
          }
          @include ar {
            margin-left: 10px;
          }
        }
      }
      td:first-child {
        @include ar {
          text-align: right;
        }
      }
      td {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 1.15px;
        color: rgba(0, 0, 0, 0.87);
        opacity: 0.99;
        &.highlight-td {
          font-weight: 500;
          background: rgba(42, 170, 226, 0.1);
        }
      }
      .customer-care-icon {
        position: relative;
        top: 4px;
      }
    }
    .reach-title {
      @include en {
        margin-left: 7px;
      }
      @include ar {
        margin-right: 8px;
      }
    }
    .avg-posts-title {
      @include en {
        margin-left: 7px;
      }
      @include ar {
        margin-right: 4px;
      }
    }
  }
  &.sticky-benchmark-table {
    th.widget-title-benchmark:first-child,
    td.widget-title-benchmark:first-child {
      z-index: 1;
      background: #f8fafc !important;
      border-inline: 1px solid #cbd5e1 !important;
      text-transform: uppercase !important;
      letter-spacing: 1.45px;
      font-weight: 600;
      color: #64748b;
      font-size: 10px;
    }
  }
  &.sticky-benchmark-table {
    th:first-child,
    td:first-child {
      z-index: 1;
      background: #fff;
      filter: drop-shadow(5px 6px 7px rgba(0, 0, 0, 0.06));
      ::after {
        width: 0;
      }

      @include en {
        left: 0;
      }
      @include ar {
        right: 0;
      }
    }
  }
}

.benchmark-item {
  padding: 6px 16px !important;
  a {
    text-decoration: underline;
    color: #456eb2;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    width: 100%;
    height: 100%;
    padding: 6px 16px !important;
  }
  .social-media-icon {
    margin-inline-end: 10px;
  }
}
.benchmark-item.dont-have-monitors.MuiButtonBase-root.MuiMenuItem-root {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.benchmark-item.create-monitor-link.MuiButtonBase-root.MuiMenuItem-root {
  padding: 0 !important;
}
.benchmark-item-checkbox {
  padding: 0 16px !important;
  .social-media-icon {
    margin-inline-end: 10px;
  }
}
.positive_icon,
.negative_icon {
  position: relative;
  top: 3px;
  @include ar {
    padding-left: 6px;
  }
  @include en {
    padding-right: 6px;
  }
}
.share-voice-icon-tooltip {
  position: absolute;
  top: 35%;
  @include en {
    left: 92%;
  }
  @include ar {
    right: 92%;
  }
}

.select-comparison-title {
  .Mui-focused {
    color: #456eb2 !important;
    font-size: 12px;
    font-weight: 400;
  }
}
.btn-export-pdf {
  p {
    text-transform: none !important;
  }
}
.loading-export-pdf {
  text-align: center;
  background-color: #f1f5f9;
  padding: 10px 10px;
  position: relative;
  bottom: 11px;
}

.loading-msg-export-pdf {
  position: relative;
  bottom: 13px;
  font-weight: 400;
  font-size: 12px;
  @include en {
    left: 10px;
  }
  @include ar {
    right: 10px;
  }
}
.sentiment-overtime-title {
  color: #334155 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  letter-spacing: 0.25px !important;
}
.items-sentiment-overtime {
  @include ar {
    direction: rtl;
  }
}
.fetching-tr {
  position: relative !important;
  height: 145px !important;
  .feching-td {
    position: absolute;
    height: 140px;
    padding: 0;
    border: none;
    filter: unset !important;
    background-color: transparent;
    left: 0;
    width: calc(100vw - 200px);
    .fetch_wrapper,
    .chart-no-data {
      height: 100%;
      width: calc(100vw - 224px);
    }
    .empty-data-search-Box {
      .fetching-image {
        width: 100px;
        height: 56px;
      }
    }
    .empty-data-search {
      padding: 10px;
    }
    h3.no-data-found.MuiTypography-root {
      font-size: 20px !important;
      margin: 5px 0 10px;
    }
  }
}
.empty-data-search-Box.empty-data-search {
  padding: 10px;
  .fetching-image {
    width: 105px !important;
    height: 72px !important;
  }
}
.empty-bench-msg {
  h3.no-data-found.MuiTypography-root {
    font-size: 20px !important;
    margin: 10px 0 0px;
  }
}

#pdf-content-parent {
  position: absolute;
  z-index: -99;
  visibility: unset;
  display: inline-flex;
  opacity: -9;
  object-fit: cover;
  height: max-content;
  width: max-content;
  inset: 0;
  overflow-x: hidden;
  top: -1000%;
  left: -1000%;
  flex-direction: column;
}

.vs-style::after {
  font-weight: 700;
  color: #1a174f;
  margin: 0px 3px;
  padding: 0px 5px;
  display: inline-block;
  @include en {
    content: "vs";
  }
  @include ar {
    content: "مقابل";
  }
}

.vs-style:last-child::after {
  display: none;
}

.vs-style {
  display: inline;
  margin: 5px;
  letter-spacing: normal;
  @include en {
    float: left;
  }
  @include ar {
    float: right;
  }
}

.main-pdf-div {
  height: auto;
  width: 100%;
  display: flex;
  text-align: left;
}
.main-pdf-div-table {
  height: 100%;
  background-color: #fff;
  width: 100%;
  @include en {
    padding: 0px 100px 0 15px;
  }
  @include ar {
    padding: 0px 60px 0px 15px;
  }
}
.pdf-margin {
  @include en {
    margin-left: 5px;
  }
  @include ar {
    margin-right: 5px;
  }
}
.pdf-date-margin {
  @include en {
    margin-left: 10px;
  }
  @include ar {
    margin-right: 10px;
  }
}
.main-pdf-div-table .pdf-title {
  font-size: 55px;
  color: #456eb2;
  margin: 100px 0px 0px 0px;
  letter-spacing: normal;
}
.main-pdf-div-table .pdf-subtitle {
  font-size: 18px;
  color: #1a174f;
  font-weight: 700;
  margin: 100px 0px 0px 0px;
  width: 100%;
  display: inline-block;
  letter-spacing: normal;
  @include ar {
    text-align: right;
  }
}
.main-pdf-div-table .pdf-date {
  font-size: 16px;
  color: #1a174f;
  font-weight: 700;
  span {
    letter-spacing: normal;
  }
}
.main-pdf-div-table .detail {
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  font-weight: 400;
  color: #1a174f;
  width: 80%;
  letter-spacing: normal;
  @include ar {
    text-align: right;
  }
}
.main-pdf-div-table .pdf-date-range {
  font-size: 18px;
  color: #1a174f;
  font-weight: 400;
}

.pdf-footer {
  width: 80%;
  height: 6px;
  border-radius: 2px;
  background: linear-gradient(
    32.85deg,
    #211d4c 0%,
    #27275a 19.54%,
    #35437d 56.18%,
    #4a69b0 100%
  );
}

.pdf-footer-text {
  font-size: 16px;
  color: #1a174f;
  margin-bottom: 5px;
}

.pdf-tables-main-div {
  height: auto;
  width: 100%;
  object-fit: cover;
}
.pdf-tables {
  object-fit: cover;
  border-spacing: 0px !important;
  width: 96% !important;
  margin-top: 20px;
  @include ar {
    padding-right: 40px;
  }
}
.pdf-table-body,
.pdf-table-body table {
  width: 100%;
  border-spacing: 0;
}
.pdf-table-body td {
  padding: 10px;
  border-right: 1px solid #fff;
  text-align: center;
}
.pdf-table-body .pdf-table-rows {
  td {
    height: 40px;
  }
  border: 1px solid #111;
}
.pdf-table-body .pdf-table-rows td {
  border-color: #111 !important;
  background-color: #fff !important;
  border: 1px solid #111 !important;
  color: #111;
}
.pdf-table-body .pdf-table-rows td {
  @include en {
    border-right: 0px !important;
  }
  @include ar {
    border-left: 0px !important;
  }
  border-bottom: 0px !important;
  text-align: center;
}
.pdf-table-body .pdf-table-rows td:first-child {
  font-weight: 500;
  font-size: 14px;
  @include en {
    text-align: left;
  }
  @include ar {
    text-align: right;
  }
}
.pdf-table-body .pdf-table-last-row td,
.pdf-table-body .pdf-table-rows:nth-child(5) td,
.pdf-table-body .pdf-table-rows:nth-child(12) td,
.pdf-table-body .pdf-table-rows:nth-child(17) td,
.pdf-table-body .pdf-table-rows:nth-child(21) td {
  border-bottom: 1px solid #111 !important;
  margin-bottom: 15px !important;
}
.pdf-table-body .pdf-table-rows td:last-child {
  @include en {
    border-right: 1px solid #111 !important;
  }
  @include ar {
    border-left: 1px solid #111 !important;
  }
}

.pdf-table-body .pdf-selected-monitors td {
  padding: 10px;
  background-color: #1a174f;
  font-weight: 700;
  color: #fff;
  text-align: center;
  font-size: 16px;
  min-width: 50px;
  max-width: 70px;
  height: 30px;
  letter-spacing: normal;
}

.pdf-table-title {
  background-color: #f1f5f9;
}
.pdf-table-title td {
  font-size: 20px;
  color: #1a174f;
  font-weight: 700;
  padding: 18px 10px;
  letter-spacing: normal;
  @include en {
    text-align: left;
  }
  @include ar {
    text-align: right;
  }
}
.pdf-table-rows {
  padding: 1px;
  background-color: #111;
}
.pdf-selected-monitors td:first-child {
  background-color: #f1f5f9 !important;
}
.pdf-table-rows td {
  color: #fff;
  letter-spacing: normal;
}
table a {
  color: #219bdd;
  font-weight: 700;
  text-decoration: none !important;
}
table .text-gray {
  color: gray;
  font-weight: 700;
}
table .text-green {
  color: green;
}
table .text-gold {
  color: gold;
}
table .text-red {
  color: red;
}

.pdf-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 100px;
  }
}
.pdf-table-title {
  img {
    width: 100px;
  }
}
.benchmark-tab {
  .MuiTabs-flexContainer {
    gap: 30px;
  }
  .MuiButtonBase-root.MuiTab-root {
    padding: 11px 10px;
  }
  .Mui-selected {
    border-bottom: 3px solid #456eb2;
  }
  .MuiTabPanel-root {
    padding: 0;
  }
}
.metrics-date {
  direction: ltr !important;
}
.benchmark-tab {
  position: relative !important;
}
.widget-title-benchmark {
  z-index: 1;
  background: #f8fafc !important;
  border-inline: 1px solid #cbd5e1 !important;
  background: #f8fafc !important;
  text-transform: uppercase !important;
  letter-spacing: 1.45px;
  font-weight: 600 !important;
  color: #64748b;
  font-size: 10px !important;
  text-align: start !important;
  @include ar {
    letter-spacing: normal !important;
    font-family: dubai;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 14px !important;
  }
}
.benchmark-filter-box {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
}
#benchmark-list-filter-btn-clear-btn,
#benchmark-list-filter-btn-apply-btn {
  width: calc(50% - 5px);
}
