#two-factor-authentication-modal-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #000000de;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > .steps-counter {
    color: #00000099;
    font-size: 14px;
    font-weight: 400;
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}
