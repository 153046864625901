@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.form-radio-parant {
  padding: 0 20px;
  max-width: 280px;
  margin-bottom: 10px;
  span {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
  svg {
    width: 20px;
    height: 20px;
    padding: 0;
  }
}
.monitor__btn {
  background-color: #ffffff !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 1px 3px rgba(0, 0, 0, 0.12) !important;
  margin-left: 14px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  @include ar {
    .MuiCircularProgress-root {
      position: relative;
      left: 54px;
    }
  }
}

.label-style {
  color: rgba(0, 0, 0, 0.6) !important;
}

.export-btn-dropdown {
  @include en {
    margin: 20px 0px 5px 0px;
  }
  @include ar {
    margin: 20px 0px 5px 0px;
    .MuiInputLabel-root {
      left: auto;
      right: 22px;
      font-weight: 500;
      font-family: "Dubai" !important;
      &.Mui-focused,
      &.filter-selected-label {
        right: 22px;
      }
    }
    .MuiSvgIcon-root {
      right: auto;
      left: 7px;
    }
    fieldset legend {
      text-align: right;
    }
  }
}

.single-tweet-analysis-box .export-btn-top {
  padding: 8px 22px;
  background-color: transparent;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.46px;
  color: #456eb2;
  margin-left: 0px;
  margin-right: 8px;
  border: 1px solid #2497ca;
  @include ar {
    margin-left: 8px;
    margin-right: 0px;
  }
  &:hover {
    background-color: rgba(63, 81, 181, 0.08);
    border-color: #2497ca;
    box-shadow: none;
  }
  &.active-export {
    background-color: #456eb2;
    border-color: #456eb2;
    color: #fff;
  }
}
.export-btn-bottom {
  margin-top: 25px;
  button {
    width: 81px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 4px;
  }
}

.export-btn {
  [aria-expanded="true"] {
    .MuiButton-endIcon {
      transform: rotate(180deg);
    }
  }
}

.export-menu {
  ul {
    padding: 0px;
  }
  .export-item {
    flex-direction: column;
    .export-label.type,
    .export-label.language {
      &:first-child {
        label {
          &:last-child {
            span {
              @include ar {
                border-top-right-radius: 0px !important;
                border-bottom-right-radius: 0px !important;
              }
              border-top-left-radius: 0px !important;
              border-bottom-left-radius: 0px !important;
            }
          }
          &:first-child {
            span {
              @include ar {
                border-top-left-radius: 0px !important;
                border-bottom-left-radius: 0px !important;
              }
              border-top-right-radius: 0px !important;
              border-bottom-right-radius: 0px !important;
            }
          }
        }
      }
      &:nth-child(odd) {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
    .export-type {
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 15px;
    }
    .export-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0px 20px;
      &.language {
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
    .export-choose {
      padding: 15px;
      width: auto;
      height: 15px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #e2e8f0;
      color: rgba(0, 0, 0, 0.6);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      cursor: pointer;
      width: 120px;
      font-size: 14px;
      min-width: 50%;
    }
    .radio-item {
      width: 100%;
      min-width: 50%;
    }
    .radio-item input:checked + span.export-choose {
      border: 1px solid #456eb2;
      color: #456eb2;
      font-weight: 600;
      width: 100%;
      min-width: 50%;
    }
  }
  .export-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    img {
      margin-inline-end: 10px;
    }
  }
  .loading-export-btn-margin {
    margin: 20px;
  }
}

.export-btn-menu {
  min-width: 250px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

#export-menu {
  @include en {
    .MuiMenu-paper {
      transform: translate(1%, 5px) !important;
    }
  }
  @include ar {
    .MuiMenu-paper {
      transform: translate(-2%, 5px) !important;
    }
  }
}
.export-btn-CA-container {
  margin-inline: 10px;
}

.export-options-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  padding: 20px 20px 10px;
}
.export-select-pages-head {
  @extend .export-options-title;
  padding: 0px 20px 10px;
}
.filters-not-affect-export {
  margin: 0px 20px 20px;
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.38);
  background-color: #f8f8f8;
  font-size: 12px;
  font-weight: 500;
  line-height: 26px;
  padding: 4px 10px;
}
.export-all-engagements {
  color: #c77700;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 20px;
  margin-inline: 20px;
}
