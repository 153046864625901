@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#profile {
  .card {
    border: 2px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    height: 70px;
  }

  .MuiDivider-root {
    margin-right: 25px;
    margin-left: 25px;
  }

  .profile-picture {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }

  .card-body {
    padding: 0.7rem 1rem;
  }

  .MuiOutlinedInput-input {
    display: block;
    width: 100%;
    height: 38px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    height: 0.4375em;
  }

  .MuiOutlinedInput-root {
    background-color: white;
  }

  #password > .MuiOutlinedInput-root {
    background-color: white;
  }

  .accounts {
    padding-top: 30px;

    hr {
      margin-bottom: 10px;
    }
  }

  .title {
    position: relative;
    margin-left: 14px;
    top: 10px;
    right: 22px;
  }

  .Instagram,
  .Facebook {
    position: relative;
    bottom: 7px;
  }

  .accounts > .actions > .MuiButton-root {
    color: gray;
  }

  .actions {
    float: right;
  }

  .MuiCardContent-root > .MuiButton-root {
    float: right;
    color: #456eb2;
    padding-left: 30px;
    padding-right: 28px;
    border-width: medium;
    border: 2px solid #456eb2;
    box-sizing: border-box;
    border-radius: 4px;
    text-transform: "none";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
  }

  h2 {
    margin: 50px 0px;
    font-weight: 500;
    color: #0c192a;
    font-size: 20px;
  }

  .profile-auth-statement {
    font-weight: 400;
    font-size: 13px;
    padding-top: 0px;

    @include en {
      margin-left: 0px;
    }
  }

  h6 {
    right: 8px;
    margin-block: auto;
  }

  .position {
    right: 17px !important;
  }

  .MuiChip-root {
    left: 43px;
    background-color: #ddfd9b;
    position: relative;
    bottom: 18px;
  }

  .MuiChip-label {
    font-size: 15px;
    color: #8cc63f;
    font-weight: 500;
    padding: 0px 20px;
  }

  #button > .cameraIcon,
  #button > .MuiButton-root {
    position: relative;
    left: 77px;
    bottom: 66px;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1rem;
    margin: 4px;
    padding: 8px;
    border-radius: 4px;
    box-sizing: content-box;
    color: rgb(255, 255, 255);
    background-color: rgb(25, 118, 210);
    border: white solid;
    border-radius: 50px;
  }

  #button > .MuiButton-root {
    min-width: 10px !important;
    height: 22px;
  }

  .twitter {
    color: #fff;
    background: #27ace4;
    padding: 6px 6px;
    border-radius: 5px;
  }

  .facebook {
    color: #fff;
    background: #45619d;
    padding: 6px 6px;
    border-radius: 5px;
  }

  .instagram {
    background: #f09433;
    background: -moz-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    ) !important;
    background: -webkit-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    ) !important;
    background: linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    ) !important;
    border: 0;
    color: #fff;
    padding: 6px 6px;
    border-radius: 5px;
  }

  @include ar {
    .MuiCardContent-root .MuiButton-root {
      float: left;
    }

    .actions .MuiSvgIcon-root,
    .accounts .MuiSvgIcon-root {
      position: relative;
      left: 4px;
    }

    .MuiChip-root {
      right: 44px;
    }

    .selected-flag {
      position: absolute;
      left: 0px;
      width: 80px;
    }

    .selected-flag .iti-flag {
      position: relative;
      top: 13px;
      left: 6px;
    }

    .flag-container .country-list {
      top: 44px;
    }

    .selected-dial-code {
      position: relative;
      // left: 9px;
      top: 1px;
      direction: ltr;
    }

    .selected-flag .down,
    .selected-flag .up {
      top: 4px !important;
      // right: -59px !important;
    }

    .country-list {
      width: 270px;
    }

    .MuiSnackbar-root {
      right: auto;
      direction: rtl;
      left: 18px;
    }

    .title {
      float: right;
    }

    .actions {
      float: left;
    }
  }

  label.MuiFormLabel-root.MuiInputLabel-root.input-user-settings-label {
    font-family: "Figtree";
    font-size: 14px;
    line-height: 22px;
    @include ar {
      font-family: "IBM Plex Sans Arabic", sans-serif;
    }
  }

  .user-info-tel-label {
    font-family: "Figtree";
    color: #000000de;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;

    &.disabled-label {
      color: #00000061;
    }
    @include ar {
      font-family: "IBM Plex Sans Arabic", sans-serif;
    }
  }
}

.header-section-profile {
  margin-bottom: 20px;

  .profile-section-header {
    margin-bottom: 6px;
    position: relative;
    overflow: hidden;
    color: #475569;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 8.5%;
    &::after {
      content: "";
      width: 100%;
      background-color: #e2e8f0;
      height: 1px;
      position: absolute;
      top: calc(50% - 0.5px);
      margin-inline-start: 20px;
    }
  }

  .profile-section-description {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #475569;
  }
}

.userinfo__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth__account {
  border: 2px solid #e2e8f0;
  box-shadow: none !important;

  img {
    width: 35px;
  }
}

.save__profile {
  text-transform: none !important;
  padding-left: 61px !important;
  padding-right: 61px !important;
  position: relative !important;
  top: 17px !important;
  left: 2px !important;
}

html[lang="ar"] .save__profile {
  margin-left: 15px !important;
}

.intl-tel-input .country-list .country {
  display: flex !important;
}

.intl-tel-input {
  @include ar {
    direction: ltr;
  }
}

.user-info-loader {
  min-height: 400px;
}

.user-info-section {
  margin-bottom: 15px;
}

.profile-user-team-container {
  margin-top: 45px;
  margin-bottom: 60px;

  .profile-user-teams-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .profile-user-team-content {
    .profile-user-teams {
      margin-top: 24px;
    }
  }

  .profile-user-empty-teams {
    padding: 24px;
    border-radius: 5px;
    border: 1px;
    background: #fff;
    border: 1px solid #e2e8f0;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #334155;
  }

  .profile-teams-cards-container {
    .profile-teams-card {
      padding: 16px 20px 16px 20px;
      border-radius: 4px;
      border: 1px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      background: #fff;
      border: 1px solid #e2e8f0;

      &-clickable {
        cursor: pointer;

        &:hover {
          border: 1px solid #3e63a0;
          box-shadow: 0px 13px 37px 0px #00000014;
        }
      }

      .profile-teams-card-icon {
        display: inline-block;
        width: 30px;
        height: 29.99px;
        img {
          width: 30px;
          height: 29.99px;
        }
      }

      #profile-teams-card-name {
        font-size: 22px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0px;
        color: #000000de;
        margin: 0;
        bottom: 0;
        position: initial;
      }

      #profile-teams-card-count {
        color: #00000099;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0px;
        margin: 0;
        bottom: 0;
        position: initial;
      }

      .profile-team-card-users {
        display: flex;
        margin-top: -5px;
        margin-bottom: -5px;
        // fist child
        & > :first-child {
          &.teams-table-member {
            .user-avatar-main-wrapper {
              position: static;
            }
            @include en {
              border-left: 0;
            }

            @include ar {
              border-right: 0;
            }
          }
        }

        & > :not(:first-child) {
          border: 5px solid #ffffff;

          @include ar {
            margin-right: -12px;
          }

          @include en {
            margin-left: -12px;
          }
        }

        .teams-table-member,
        .teams-table-member-more {
          border-radius: 50%;
          width: 30px;
          height: 30px;
          background-color: #f1f5f9;
          border: 5px solid #ffffff;

          img {
            border: 1px solid #1018281a;
            width: 100%;
            height: 100%;
            border-radius: 50%;

            &.broken-image {
              border: 1px solid #fff;
            }
          }
        }
        .teams-table-member {
          .user-avatar-main-wrapper {
            position: static;
          }
        }
        .teams-table-member-more {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #456eb2;
          cursor: default;
          direction: ltr;
          background-color: #e2e8f0;
        }
      }
    }
  }
}

.profile-user-loading-section {
  .circular-loading {
    top: 0;
    margin: 60px 0;
  }
}

#profile-page-accounts-management-alert,
#company-information-accounts-management-alert {
  margin-bottom: 20px;
  .MuiAlert-message {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: rgba(13, 60, 97, 1);
    background-color: rgba(232, 244, 254, 1);
  }
  .alert-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btns-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 15px;
      .view-btn {
        font-weight: 500;
        font-size: 13px;
      }
      .close-btn {
        color: rgba(13, 60, 97, 1);
      }
    }
  }
}
