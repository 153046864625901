@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

@mixin text-ellipsis($lines: 1) {
  text-overflow: ellipsis;
  overflow: hidden;
  @if ($lines > 1) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  } @else {
    white-space: nowrap;
  }
}

.main-del-popup {
  width: 460px;
  margin: 0 -4px;
  .del-popup-header {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 500;
    color: #000000de;
  }
  .del-popup-contant {
    font-size: 15px;
    font-weight: 500;
    color: #000000de;
    .about-del {
      display: flex;
      gap: 5px;
      .about-del-title {
        font-weight: 700;
      }
    }
  }
  .del-popup-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    gap: 5px;
    button {
      font-size: 14px;
      font-weight: 500;
      padding: 8px 16px;
      border-radius: 2px;
      &.cancel-rep-btn {
        color: #00000099;
        border: 1px solid #0000008a;
        &:hover {
          background-color: #e8e8e880;
        }
      }
      &.del-rep-btn {
        color: #fff;
        background-color: #f44336;
        &.Mui-disabled {
          display: flex;
          gap: 5px;
          background-color: #0000001f;
          color: #00000042;
        }
      }
    }
  }
}

.main-step-three-replay-section {
  .step-three-header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 20px 0;
    .step-three-title {
      color: #1a174f;
      font-size: 20px;
      font-weight: 600;
    }
    .step-three-pargragh {
      color: #000000de;
      font-size: 15px;
      font-weight: 400;
    }
  }
  .reps-list {
    padding-bottom: 20px;
    border-bottom: 1px solid #e3e3e3;
    .reps-list-header {
      display: flex;
      justify-content: space-between;
      color: #000000de;
      font-size: 16px;
      font-weight: 600;
      align-items: center;
      .reps-list-header-btns {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 10px;
      }
    }
    .reps-list-contant {
      font-size: 14px;
      color: #00000099;
      padding: 15px 0;
      .replay-section-accordion {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        .collection-page-counter {
          display: flex;
          justify-content: center;
        }
        .replaies-page-counter {
          display: flex;
          justify-content: center;
          margin-top: 25px;
        }
        .empty-loading-reps-list {
          display: flex;
          justify-content: center;
          font-size: 14px;
          font-weight: 400;
          color: #000;
          svg {
            color: #456eb2;
          }
        }
        .MuiAccordion-root {
          box-shadow: unset;
          transition: unset;
          border: 1px solid #e2e8f0;
          border-radius: 0px;
          padding: 11px 20px;
          margin: 0;
          background-color: #f8fafc;
          &:hover {
            background-color: #f1f5f9;
          }
          &.Mui-expanded {
            padding: 11px 20px 0;
            background-color: #f8fafc;
          }
          &::before {
            display: none;
          }
          .MuiAccordionSummary-root {
            padding: 0;
            min-height: auto;
            .MuiAccordionSummary-expandIconWrapper {
              svg {
                font-size: 20px;
                fill: #00000099;
              }
            }
            .MuiAccordionSummary-content {
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-inline-end: 8px;
              .accordion-header {
                font-size: 16px;
                font-weight: 500;
                color: #0f172a;
                display: flex;
                gap: 5px;
                flex-direction: row;
                align-items: center;
              }
              .menu-hidden {
                width: 32px;
                height: 32px;
              }
              .collection-menu-list {
                .menu-items.menu-item-container {
                  width: 32px;
                  height: 32px;
                  border-radius: unset;
                  .ellipsis-icon {
                    font-size: 20px;
                    color: #616161;
                  }
                  &:hover,
                  &.menu-item-active {
                    background-color: #ced3db;
                    .ellipsis-icon {
                      font-size: 20px;
                      color: #456eb2;
                    }
                  }
                }
              }
            }
          }
          .MuiAccordionDetails-root {
            margin: 20px 0;
            margin-top: 36px;
            padding: 0;
            .collaps-hr {
              position: absolute;
              top: 57px;
              left: 0;
              width: 100%;
            }
            .multi-selected-replaies {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 8px 20px;
              font-size: 16px;
              color: rgba(0, 0, 0, 0.87);
              font-weight: 400;
              border-radius: 4px;
              border: 1px solid var(--neutral-300, #cbd5e1);
              background: #fff;
              margin-bottom: 20px;
              .multi-actions-replaies {
                display: flex;
                align-items: center;
                gap: 4px;
                hr {
                  height: 16px;
                  width: 1px;
                }
                .multi-actions-btn {
                  width: 32px;
                  height: 32px;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 2px;
                  svg {
                    font-size: 16px;
                    color: #475569;
                  }
                  &:hover {
                    background: rgba(230, 243, 252, 0.5);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .ai-list {
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9fc;
    border: 1px solid #e8eaf3;
    border-radius: 3px;
    .ai-list-header {
      .ai-header-title {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        font-weight: 600;
        color: #212121;
        gap: 7px;
        .header-title-sub {
          font-size: 14px;
          font-weight: 400;
          color: #00000099;
        }
      }
      button {
        border: 1px solid #626cab80;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        font-weight: 500;
        color: #626cab;
        padding: 8px 16px;
        &:hover {
          background-color: #e8eaf3;
        }
        &.Mui-disabled {
          background-color: #0000001f;
          color: #00000042;
          cursor: not-allowed;
          display: flex;
          gap: 5px;
        }
      }
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      &.not-empty {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .ai-header-title {
          max-width: 60%;
        }
      }
    }
    .ai-list-contant {
      padding: 20px 0;
      margin-top: 20px;
      border-top: 1px solid #a1a8ce;
      color: #00000099;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  .list-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    padding: 5px 0;
    .rep-card-countainer {
      display: flex;
      flex-direction: column;
      padding: 12px 15px;
      border: 1px solid #0000001f;
      background-color: #fff;
      border-radius: 4px;
      width: calc(100% - 32px);
      overflow: hidden;
      &.clicked {
        cursor: pointer;
      }
      &.selected {
        border-color: #456eb2;
      }
      .rep-card-header {
        color: #000000de;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        svg {
          color: #00000099;
          cursor: pointer;
        }
      }
      .rep-card-contant {
        flex-grow: 1;
        font-size: 14px;
        font-weight: 400;
        color: #000000de;
        overflow: hidden;
        .see-more-less-btn {
          color: #456eb2;
          cursor: pointer;
        }
      }
      .rep-saved-card-footer {
        margin-top: 15px;
        padding-top: 8px;
        border-top: 1px solid #cbd5e1;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        .MuiCheckbox-root {
          padding: 4px 7px;
        }
        .card-footer-options {
          display: flex;
          align-items: center;
          gap: 8px;
          hr {
            height: 16px;
            width: 1px;
            color: #cbd5e1;
          }
          svg {
            color: #00000099;
            cursor: pointer;
            font-size: 16px;
            padding: 8px 12px;
            border-radius: 2px;
            &:hover {
              background: rgba(230, 243, 252, 0.5);
            }
          }
        }
      }
      .rep-card-footer {
        margin-top: 10px;
        padding-top: 5px;
        border-top: 1px solid #e4e4e4;
        .rep-card-ai-btn {
          width: max-content;
        }
        button {
          font-size: 12px;
          font-weight: 500;
          color: #00000099;
          display: flex;
          gap: 5px;
          padding: 2px 7px;
          &:hover {
            color: #000000de;
          }
        }
      }
    }
  }
  .reps-list-pagonation {
    &.new_reps {
      margin-top: 20px;
    }
    display: flex;
    justify-content: center;
    .MuiPaginationItem-root {
      background-color: #fff;
      &.Mui-selected {
        background-color: #efeff2;
      }
      svg {
        @include ar {
          rotate: 180deg;
        }
      }
    }
  }
}
[aria-labelledby="collection-menu-list-container"] {
  li {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    svg {
      font-size: 14px;
    }
    &.del-collection-item {
      color: #e31b0c;
    }
  }
}
