@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.audience-user {
  border: 1px solid #dadcdd;
  padding: 7px 16px;
  min-height: 58px;
  h6 {
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    text-transform: capitalize;
  }
}

.audience-rate {
  display: flex;
  align-items: center;
  @include ar {
    .progress-value {
      margin-left: 10px;
    }
  }
  @include en {
    .progress-value {
      margin-right: 10px;
    }
  }
  .progress {
    width: 100%;
    border-radius: 40px;
    background-color: #cbd5e1;
    .MuiLinearProgress-root {
      border-radius: 40px;
    }
  }
}

.monitor-table {
  .dropDown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    -webkit-box-pack: flex-end;
    -ms-flex-pack: flex-end;
    justify-content: flex-end;
  }

  .dropDown button {
    border-radius: 4px;
    min-width: 24px;
    height: 24px;
    padding: 15px 8px;
    &[aria-expanded="true"] {
      background-color: #cbd5e1;
      border-radius: 4px;
      color: #757575;
    }
  }
  .dropDown button:hover {
    background-color: rgba(69, 110, 178, 0.04);
  }
}

.audience-image {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  .audience-user-image {
    margin-inline-end: 10px;
  }
  .audience-user-image img {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: block;
  }
  &:hover {
    .audience-table-title {
      text-decoration: underline;
      color: #2497ca;
    }
  }
}

.audience-tooltip {
  width: fit-content;
}

.audience-information {
  @include en {
    .audience-subtitle {
      direction: ltr;
    }
  }

  @include ar {
    .audience-subtitle {
      direction: ltr;
    }
  }
}

.audience-information {
  .audience-table-title {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
  }
  .audience-identifier {
    font-size: 12px;
    color: #80868c;
    font-weight: 400;
  }
  .no-name {
    color: #80868c;
    font-size: 14px;
    font-weight: 400;
  }
}

.audience-user-name {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  font-size: 14px;
}

.audience-load {
  height: 500px;
}
.monitor-table {
  .audience-main-name {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
  }
}

.completeness-rate-tooltip {
  width: fit-content;
  border-bottom: 1px dashed #cbd5e1;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: start;
}

.completeness-tooltip {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding: 1px 8px;
}

.audience-social {
  display: flex;
  align-items: center;
  font-size: 12px;

  .box-icon {
    font-size: 12px;
    margin-inline-end: 5px;
    img {
      width: 12px;
      height: 12px;
      filter: grayscale(100%);
    }
  }
}
.audience-social-name {
  @include ar {
    direction: rtl;
  }
}

.audience-header-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.segment-checkbox {
  width: 15px;
  height: 15px;
  border: 2px solid rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition: 0.1s all ease-in-out;
}
.segment-active {
  background: #456eb2;
  border-color: transparent;
}
.segment-box-tooltip {
  padding: 8px;
  width: 136px;
}
.check-user {
  display: flex;
  align-items: center;
}
.item {
  align-items: center;
  position: relative;
  width: max-content;
  margin-inline-end: 19px;
  width: 36px;
  height: 36px;
  display: flex;
  transition: 0.3s;
  justify-content: center;
  border-radius: 50%;
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
  .segment-checkbox {
    border-radius: 2px;

    .segment-active {
      background-color: #456eb2;
      border-color: transparent;
      color: #fff;
    }
  }
  .segment-checkbox ~ .check-icon {
    display: none;
  }
  .segment-checkbox.segment-active ~ .check-icon {
    display: block;
  }
  .segment-checkbox.segment-active ~ .check-icon-remove {
    display: block;
  }

  .check-icon,
  .check-icon-remove {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    font-size: 17px;
    pointer-events: none;
    z-index: 0;
    color: #fff;
    svg {
      pointer-events: none;
    }
  }
}

.check-icon-remove {
  color: #456eb2;
  background: #456eb2;
  display: inline;
  border-color: #456eb2 !important;
  border: none;
}
.selected-user-parant {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 45px;
  padding: 0 16px;
  background: #f8fafc;
  p {
    font-weight: 400;
    font-size: 14px;
    color: #696d72;
    margin-inline-end: 5px;
  }
  .btn-select-all {
    height: 34px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    letter-spacing: 0.46px;
    color: #456eb2;
    cursor: pointer;
    transition: 0.3s;
    padding: 0 10px;
    &:hover {
      background: rgba(42, 170, 226, 0.1);
    }
  }
}

.aduience-table {
  tr {
    height: 64px;
    > .MuiTableCell-root {
      text-transform: inherit;
      @include ar {
        font-family: "Dubai";
      }
    }
  }
  .audience-icon-arrow {
    font-weight: 900;
    font-size: 16px;
    color: #475569;
    margin-inline-start: 10px;
  }
}
.audience-btns {
  display: flex;
  .segment-exclude-add-btns {
    display: flex;
    align-items: center;
    hr {
      height: 26px;
      margin: 0 10px;
    }
  }
  .create-segment-btn {
    padding: 8px 12px;
    h5 {
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0.46px;
      color: #456eb2;
      text-transform: capitalize;
    }

    @include ar {
      .MuiButton-startIcon {
        margin-right: 0px;
      }
    }
    .css-i4bv87-MuiSvgIcon-root {
      font-size: 16px;
      @include ar {
        order: 1;
      }
    }
  }
}
.add-segment-select {
  height: 40px;
  position: relative;
}
.add-segment-pop {
  p {
    margin-bottom: 16px !important;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .segment-num-deleted-users {
    font-size: 14px;
  }
}
.add-segment-popup {
  .segment-num-deleted-users {
    font-size: 14px;
  }
}
.add-segment-engagements.add-segment-pop {
  .add-segment-select.MuiInputBase-root{
    font-size: 14px;
    line-height: 15px;
  }
  .MuiSelect-icon{
    top: 4px;
  }
}
.segment-delete-popup-second-title {
  &.user-profile {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 400;
  }
}
.user-profile {
  .segment-num-deleted-users {
    font-size: 15px;
    font-weight: 600;
  }
}
//add profile segment~~~~~~
.segment-menu-item {
  height: 34px;
  span {
    padding: 0;
  }
}
.selected-name-segment {
  span {
    margin: 0;
    height: 21px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
  }
}
.segment-formControl {
  width: 100%;
  height: 42px;

  .MuiInputLabel-root {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);

    &.Mui-focused {
      @include ar {
        right: 13px !important;
      }
      @include ar {
        left: auto;
        right: 30px;
        font-family: "Dubai" !important;
      }
      &.filter-selected-label {
        margin-top: 0px;
      }
    }
  }

  .MuiSelect-select {
    padding: 11px 14px;
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: rgba(0, 0, 0, 0.23);
    }
    &:hover {
      fieldset {
        border-color: #456eb2;
      }
    }
  }

  @include ar {
    fieldset {
      legend {
        text-align: right;
      }
    }

    .MuiSvgIcon-root {
      right: auto;
      left: 7px;
    }
  }

  @include ar {
    label {
      transform: translate(16px, 15px) scale(1);

      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(16px, -11px) scale(0.75);
        background: #ffffff;
        padding: 3px;
      }
    }
  }
  @include en {
    label {
      transform: translate(16px, 12px) scale(1);
      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(16px, -11px) scale(0.75);
        background: #ffffff;
        padding: 3px;
      }
    }
  }
  fieldset {
    legend {
      span {
        display: none;
      }
    }
  }
}
.segment-formControl {
  width: 100%;
  height: 42px;
  .MuiInputLabel-root {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);

    @include ar {
      left: auto;
      right: 30px;
      margin-top: -7px;
      font-family: "Dubai" !important;
      &.Mui-focused {
        margin-top: 1px !important;
        right: 13px;
      }
      &.filter-selected-label {
        right: 15px;
        margin-top: 0px;
      }
    }
  }

  .MuiSelect-select {
    padding: 0;
    padding: 0 25px 0 12px;
    @include ar {
      padding: 0 12px 0 25px;
    }
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: rgba(0, 0, 0, 0.23);
    }
    &:hover {
      fieldset {
        border-color: #456eb2;
      }
    }
  }

  @include ar {
    fieldset {
      legend {
        text-align: right;
      }
    }
    .MuiSvgIcon-root {
      right: auto;
      left: 7px;
    }
  }
}
.segment-menu-item {
  height: 34px;
  span {
    padding: 0;
  }
  .selected-name-segment {
    margin-inline-start: 16px;
  }
}
.add-segment-popup {
  p {
    margin-bottom: 16px !important;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87) !important;
  }
}
.filter-selected-label {
  margin-top: 0px !important;
}
.understand-exclude-segment {
  margin: 0 !important;
  span {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    margin-top: 16px;
    margin-inline-start: 3px;
    color: rgba(0, 0, 0, 0.87);
  }
}
.check-viewer {
  width: 15px;
  height: 15px;
  cursor: not-allowed;
  border: 2px solid rgba(0, 0, 0, 0.26);
  border-radius: 2px;
}
.segment-tooltip {
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }
}
.no-data-cdp {
  .empty-data-box {
    width: 439px;
  }
}
