#user-phone-number-input {
  .MuiFormControl-root:has(.react-tel-input),
  .separate-dial-code:has(.react-tel-input) {
    width: 100%;
  }

  .react-tel-input {
    width: 100%;
    font-weight: 400;
    border: 1px solid #cbd5e1;
    box-shadow: none;
    font-size: 12px;
    line-height: 1.4375em;
    color: #1e293b;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    padding-left: 100px;

    &::placeholder {
      opacity: 30%;
    }

    &:focus {
      border-color: #27ace4;
    }

    &.user-phone-error {
      border-color: #f44336;
    }

    &:disabled {
      background-color: #fff;
      color: #00000061;
    }
  }

  .selected-flag {
    border-inline-end: 1px solid #e5e5e5;
    background-color: transparent;
    font-size: 12px;
    font-weight: 500;
  }

  .selected-dial-code {
    color: #1e293b;
    padding-left: 7px;
    font-size: 12px;
    pointer-events: none;
  }

  .selected-flag .arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
  }

  .selected-flag .up,
  .selected-flag .down {
    position: relative;
    top: 3px;
    right: 5px;
    font-size: 0px;
  }

  .selected-flag .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .selected-flag .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .selected-flag .iti-flag {
    position: relative;
    top: 12px;
    left: 6px;
  }

  .country-list {
    width: 100%;
  }

  .user-phone-error-msg {
    color: #f44336;
    margin-top: 5px;
    margin-inline-start: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
}
