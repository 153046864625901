#two-fa-method-name-with-icon {
  display: flex;

  .two-fa-method-icon {
    margin-inline-end: 12px;
    padding: 2px;
    font-size: 20px;
    color: #64748b;
  }

  .title-two-fa-method,
  .description-two-fa-method {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #000000de;
  }
  .description-two-fa-method {
    color: #00000099;
    font-weight: 400;
  }

  &.disabled-two-fa-method {
    .title-two-fa-method,
    .description-two-fa-method {
      color: #00000061;
    }
  }

  .title-two-fa-method {
    margin-bottom: 2px;
  }
}
