@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

label.MuiFormLabel-root.MuiInputLabel-root.input-user-settings-label {
  color: #000000de;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  .required {
    color: #e31b0c;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  &.disabled-label {
    color: #00000061;
  }
}
.input-user-settings-form-error.MuiFormHelperText-root {
  color: #f44336;
  margin-top: 5px;
  margin-inline-start: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  @include ar {
    text-align: right;
  }
}
