.main-note-loader-container {
  display: flex;
  padding: 10px;
  flex-direction: column;
  gap: 10px;
  .main-note-cards-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: #f0f4f7;
    width: 100%;
    max-width: 252px;
    min-height: 120px;
    border-radius: 4px;
    padding: 10px;

    .note-cards-loader-header {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
    }
  }
}
