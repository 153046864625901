#resend-code-time-btn {
  text-align: center;

  .resend-code-time {
    font-size: 14px;
    font-weight: 400;
    color: #00000099;
    line-height: 20px;
  }
}
