//functions sass
@mixin display-flex-function($flex, $item-center, $justify) {
  display: $flex;
  align-items: $item-center;
  justify-content: $justify;
}
//@include display-flex-function(flex, center, flex-start);
@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
@mixin falcon-env {
  html[env="falcon"] & {
    @content;
  }
}
.no-data-parant {
  .no-data-head {
    @include display-flex-function(flex, center, space-between);
    padding: 20px;
    @include en {
      direction: rtl;
    }
    .luci-btns-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      width: auto;
      height: fit-content;
      .luci-settings-btn {
        height: 35px !important;
        width: 35px !important;
        background: #f7f7f7;
        border: 1px solid #e8e8e8;
        &:hover {
          background: rgba(255, 255, 255, 0.15);
        }
      }
    }
  }
  .btn-close-side-bar {
    width: 42px;
    height: 42px;
    color: #212121;
    background: #f7f7f7;
    border-radius: 2px;
    transition: 0.5s;
    &:hover {
      color: #ffffff;
      background: linear-gradient(
        90deg,
        #1f2249 -12.87%,
        #262d56 9.11%,
        #394c7a 50.08%,
        #4e6d9f 87.05%
      );
    }
  }
  .header-tokens {
    background: #f7f7f7;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    color: #212121;
  }
  .no-data-content {
    width: 100%;
    span {
      @include display-flex-function(flex, center, center);
      margin: 147px auto 10px;
      width: 80px;
      height: 80px;
      background: #ffffff;
      box-shadow: 0px 13px 37px rgba(0, 0, 0, 0.08);
      border-radius: 100px;
      img {
        @include falcon-env {
          width: 56px;
          height: 56px;
        }
      }
    }
    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #212121;
      margin: 0;
      margin-bottom: 10px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #676767;
      width: 50%;
      margin: auto;
    }
  }
}
.nodata-show-prev {
  &.MuiButton-root {
    position: absolute;
    bottom: 0;
  }
}
