@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.details-side-bar-tabs {
  max-width: 100%;
  height: calc(100% - 66px);
  .tab-list {
    height: 48px;
    display: flex;
    list-style: none;
    font-size: 18px;
    padding: 0 10px;
    margin: 0;
    button {
      padding: 11px 15px;
      &#saved-suggestions-tab-btn {
        flex-grow: 3;
      }
      &#ai-replies-tab-btn {
        flex-grow: 2;
      }
      @include ar {
        padding: 11px 7px;
      }
    }
  }

  .no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    > p {
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 188px;
      margin-bottom: 20px;
    }
    > img {
      width: 110px;
      height: 110px;
      margin-bottom: 20px;
    }
    span {
      display: inline-block;
      padding-inline: 2px;
      color: #456eb2;
      cursor: pointer;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    button {
      display: flex;
      padding: 0px 10px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 2px;
      border: 1px solid #456eb2;
      svg {
        font-size: 17px;
      }
    }
  }
  .tabs {
    // width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
  }

  .active-tabs {
    color: rgba(0, 0, 0, 0.87);
  }
  .content-container {
    overflow: auto;
    height: 100%;
  }
  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 3px;
    z-index: 1;
    background: #e2e8f0;
  }
  .loading-color {
    .active-tabs {
      color: rgba(0, 0, 0, 0.26) !important;
      &::before {
        background-color: rgba(0, 0, 0, 0.26) !important;
      }
    }
  }
  .item-group {
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
  .content {
    margin-top: 3px;
    padding: 20px;
    display: none;
    display: none;
    height: 100%;
    gap: 10px;
    max-width: 100%;
    > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .replies-way {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      position: relative;
      img {
        width: 100px;
        height: 100px;
      }
    }
  }

  .active-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    max-height: calc(100vh - 229px);
  }

  .action-button {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    button {
      width: max-content;
      display: flex;
      padding: 0px 10px;
      padding-inline-start: 4px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 2px;
      border: 1px solid #456eb2;
      color: #456eb2;
      font-size: 13px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.46px;
      text-transform: capitalize;
      svg {
        font-size: 16px;
      }
    }
  }
  .content-box {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background: #fff;
    padding: 15px;
    gap: 20px;
    transition: 0.4s;
    &:hover {
      background: #fafafa;
      .start-box {
        button {
          background-color: #456eb2;
          color: #fff;
        }
      }
    }
    .head-content {
      height: 26px;
    }
  }
  .content-box.content-save-reply {
    width: 218px;
    .body-content {
      span {
        color: rgba(0, 0, 0, 0.87);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.4px;
        display: inline-block;
        margin-bottom: 10px;
      }
      p {
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.07px;
      }
    }
  }
  .ai-icon {
    border-radius: 4px;
    border: 1px solid #f0f0f0;
    background: #fff;
    padding-inline: 8px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    img {
      width: 16px;
    }
  }
  .head-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .side-bar-close-wrapper {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    font-weight: 900;
    cursor: pointer;
  }
  .body-content {
    margin-bottom: 20px;
    p {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      font-weight: 400;
      overflow: hidden;
    }
  }
  .footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .start-box {
      display: flex;
      align-items: center;
      button {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        background: #fff;
        padding: 0 10px;
        gap: 4px;
        margin-inline-end: 10px;
        color: #456eb2;
        font-size: 13px;
        font-weight: 500;
        line-height: 26px; /* 200% */
        text-transform: capitalize;
        height: 26px;
        &.inserted-replay-button {
          background: transparent;
          border: 1px solid #0000001f;
          color: #00000042;
        }
        svg {
          font-size: 15px;
          @include en {
            rotate: 45deg;
          }
          @include ar {
            rotate: -45deg;
          }
        }
      }

      > span {
        border-radius: 4px;
        background: rgba(76, 175, 80, 0.08);
        min-height: 26px;
        padding: 0px 8px;
        width: max-content;
        line-height: normal;
        display: flex;

        p {
          font-size: 14px;
          font-weight: 500;
          width: max-content;
          height: max-content;
          border-bottom: 1px dashed;
        }
      }
      span.score-green {
        color: #3b873e;
        p {
          border-color: #7bc67e;
          color: #3b873e;
        }
      }
      span.score-red {
        background: rgba(244, 67, 54, 0.08);
        p {
          border-color: #f88078;
          color: #f44336;
        }
      }
      span.score-Warning {
        background: rgba(237, 108, 2, 0.11);
        p {
          border-color: #ffb547;
          color: #ed6c02;
        }
      }
    }
    .end-box {
      button {
        border-radius: 2px;
        display: flex;
        width: 26px;
        height: 26px;
        min-width: auto;
        padding: 0px;
        justify-content: center;
        align-items: center;
        color: rgba(0, 0, 0, 0.6);
        font-size: 16px;
        font-weight: 400;
        .awesome-icon {
          font-size: 16px;
        }
      }
    }
  }
}

.details-side-bar-header .side-bar-name {
  @include ar {
    font-size: 19px;
  }
}

.no-data-list-above-cases-ai {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  > p {
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 188px;
    margin-bottom: 20px;
  }
  > img {
    width: 110px;
    height: 110px;
    margin-bottom: 20px;
  }
  span {
    display: inline-block;
    padding-inline: 2px;
    color: #456eb2;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.saved-replies-above-80-content {
  .MuiTypography-root {
    font-size: 14px;
    h1 {
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
      @include ar {
        display: inline;
      }
    }
  }
  position: relative;
  bottom: 10px;
}
