@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.main-rep-bar {
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  .rep-bar-header {
    min-height: 64px;
    height: 6%;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    border-bottom: 1px solid #e2e8f0;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      color: #757575;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .rep-bar-search {
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    > div {
      width: 100%;
      height: 40px;
    }
    .MuiOutlinedInput-root {
      padding: 0;
      padding-inline-start: 12px;
      padding-inline-end: 4px;

      .fa-magnifying-glass,
      input::placeholder {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        opacity: 1;
      }
    }
  }
  .rep-bar-contant {
    padding: 0 20px 15px;
    flex-grow: 1;
    &.scroll {
      overflow-y: scroll;
    }
    &.no-search {
      padding: 20px;
    }
    .fetching-loader {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      svg {
        color: #1a3d95;
        width: 30px;
        height: 50px;
      }
    }
    .rep-bar-contant-empty {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #64748b;
      gap: 10px;
      font-size: 14px;
      font-weight: 400;
      padding: 0px 28px;
    }
    .rep-bar-contant-empty.saved-empty {
      .empty-title {
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.87);
        text-align: center;
        margin: 0;
      }
    }
    .rep-bar-contant-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 10px 0;
      .rep-bar-contant-card {
        overflow: hidden;
        padding: 15px;
        border: 1px solid #0000001f;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 16px;
        font-weight: 600;
        div {
          overflow: hidden;
        }
        .contant-contant {
          color: #000000de;
          font-size: 14px;
          font-weight: 400;
          .see-more-less-btn {
            color: #456eb2;
            cursor: pointer;
          }
        }
        .contant-footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 15px;
          svg {
            color: #00000099;
            font-size: 16px;
            cursor: pointer;
          }
          .contant-footer-btns {
            display: flex;
            gap: 15px;
          }
          button {
            display: flex;
            gap: 10px;
            color: #456eb2;
            border: 1px solid;
            border-radius: 2px;
            font-size: 13px;
            font-weight: 500;
            height: 26px;
            width: 74px;
            &.inserted-replay-button {
              width: 115px;
              background: transparent;
              border: 1px solid #0000001f;
              color: #00000042;
              svg {
                color: #00000042;
              }
            }
            svg {
              rotate: 45deg;
              height: 18px;
              width: 13px;
              color: #456eb2;
              @include ar {
                rotate: -45deg;
              }
            }
            &:hover {
              background-color: #456eb2;
              color: #fff;

              svg {
                color: #fff;
              }
            }
          }
        }
      }
      .pagination-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        color: #757575;
        font-size: 15px;
        font-weight: 500;
        margin: 20px 0;
      }
    }
    .replay-section-accordion {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      .collection-page-counter {
        display: flex;
        justify-content: center;
      }
      .replaies-page-counter {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
      .empty-loading-reps-list {
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        color: #000;
        svg {
          color: #456eb2;
        }
      }
      .MuiAccordion-root {
        box-shadow: unset;
        transition: unset;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        padding: 10px 16px;
        margin: 0;
        background-color: #fff;
        &:hover {
          background-color: #fafafa;
        }
        &.Mui-expanded {
          padding: 10px 16px 0;
          background-color: #fff;
        }
        &::before {
          display: none;
        }
        .MuiAccordionSummary-root {
          padding: 0;
          min-height: auto;
          .MuiAccordionSummary-expandIconWrapper {
            svg {
              font-size: 20px;
              fill: #00000099;
            }
          }
          .MuiAccordionSummary-content {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-inline-end: 8px;
            .accordion-header {
              font-size: 14px;
              font-weight: 500;
              color: #0f172a;
              display: flex;
              gap: 5px;
              flex-direction: row;
              align-items: center;
              &.number {
                font-size: 12px;
              }
            }
          }
        }
        .MuiAccordionDetails-root {
          margin: 16px 0;
          margin-top: 26px;
          padding: 0;
          .collaps-hr {
            position: absolute;
            top: 42px;
            left: 0;
            width: 100%;
          }
          .rep-bar-contant-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 16px 0;
            .rep-bar-contant-card {
              overflow: hidden;
              padding: 15px;
              border: 1px solid #0000001f;
              border-radius: 4px;
              display: flex;
              flex-direction: column;
              gap: 10px;
              font-size: 16px;
              font-weight: 600;
              div {
                overflow: hidden;
              }
              .contant-contant {
                color: #000000de;
                font-size: 14px;
                font-weight: 400;
              }
              .contant-footer {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 15px;
                svg {
                  color: #00000099;
                  font-size: 16px;
                  cursor: pointer;
                }
                button {
                  display: flex;
                  gap: 10px;
                  color: #456eb2;
                  border: 1px solid;
                  border-radius: 2px;
                  font-size: 13px;
                  font-weight: 500;
                  height: 26px;
                  width: 74px;
                  &.inserted-replay-button {
                    width: 115px;
                    background: transparent;
                    border: 1px solid #0000001f;
                    color: #00000042;
                    svg {
                      color: #00000042;
                    }
                  }
                  svg {
                    rotate: 45deg;
                    height: 18px;
                    width: 13px;
                    color: #456eb2;
                    @include ar {
                      rotate: -45deg;
                    }
                  }
                  &:hover {
                    background-color: #456eb2;
                    color: #fff;

                    svg {
                      color: #fff;
                    }
                  }
                }
              }
            }
            .pagination-loader {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;
              color: #757575;
              font-size: 15px;
              font-weight: 500;
              margin: 20px 0;
            }
          }
        }
      }
    }
  }
  .rep-bar-footer {
    height: 80px;
    min-height: 80px;
    border-top: 1px solid #e2e8f0;
    display: flex;
    align-items: center;
    padding: 0 20px;
    button {
      width: 100%;
      border: 1px solid #456eb2;
      border-radius: 4px;
      color: #456eb2;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      gap: 10px;
      line-height: 26px;
      height: 40px;
      span {
        font-size: 25px;
        margin-bottom: 5px;
      }
    }
  }
}
.main-del-popup {
  width: 460px;
  margin: 0 -4px;
  .del-popup-header {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 500;
    color: #000000de;
  }
  .del-popup-contant {
    font-size: 15px;
    font-weight: 500;
    color: #000000de;
    .about-del {
      display: flex;
      gap: 5px;
      .about-del-title {
        font-weight: 700;
      }
    }
  }
  .del-popup-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    gap: 5px;
    button {
      font-size: 14px;
      font-weight: 500;
      padding: 8px 16px;
      border-radius: 2px;
      &.cancel-rep-btn {
        color: #00000099;
        border: 1px solid #0000008a;
        &:hover {
          background-color: #e8e8e880;
        }
      }
      &.del-rep-btn {
        color: #fff;
        background-color: #f44336;
        &.Mui-disabled {
          display: flex;
          gap: 5px;
          background-color: #0000001f;
          color: #00000042;
        }
      }
    }
  }
}

#end-search-main {
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    cursor: pointer;
    padding: 9px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    &:hover {
      background-color: #f1f5f9;
    }
  }

  hr {
    width: 1px;
    height: 30px;
  }
}
