@import "~@fortawesome/fontawesome-pro/css/all.min.css";

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

@mixin text-styles(
  $font-size: 14px,
  $font-weight: 500,
  $line-height: 22px,
  $color: #000000de
) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  color: $color;
}

#security-section-profile {
  .security-section-container {
    .card-security-section {
      box-shadow: none;
      height: calc(100% - 40px);
      padding: 20px;
      border-radius: 6px;
      border: 1px solid #e2e8f0;

      .card-security-section-header {
        margin-bottom: 16px;
        .card-security-section-tiltle {
          @include text-styles(16px, 500, 22px);
        }
        .card-security-section-description {
          @include text-styles(16px, 400, 22px, #00000099);
        }
      }
    }

    .new-password-form {
      .password-input-security-section {
        padding: 8px 16px;

        label.MuiFormLabel-root.MuiInputLabel-root.input-user-settings-label {
          margin-bottom: 4px;
        }
      }
    }

    .two-factor-authentation-card {
      box-shadow: none;
      .circular-loading {
        top: 50px;
      }
      .two-factor-authentation-method {
        display: flex;
        justify-content: space-between;
        padding: 16px;
      }
    }
  }
}
.tooltip-new-password-container {
  ul {
    margin-bottom: 0;
    list-style: none;
    padding: 0;

    li {
      position: relative;
      padding-inline-start: 20px;
      line-height: 22px;

      &::before {
        content: "\f058";
        font-family: "Font Awesome 6 Pro";
        position: absolute;
        font-size: 12px;
        font-weight: 700;
        left: 0;
        top: 0;
        @include ar {
          right: 0;
        }
      }

      &.checked::before {
        color: #4caf50;
      }
    }
  }
}
