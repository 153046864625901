@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#accounts-management {
  .tabs-container {
    .tab-button {
      font-weight: 500;
      font-size: 16px;
      color: #64748b;
      border-bottom: 1px solid #e2e8f0;
      &.Mui-selected {
        font-weight: 600;
        color: #233759;
        border-bottom: 3px solid #456eb2;
      }
    }
    .MuiTabPanel-root {
      padding-left: 0px;
      padding-right: 0px;
    }
    .tab-header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 20px;
      flex-wrap: wrap;
      &.no-search {
        align-items: center;
      }
      .desc-search-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        .description {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.6);
          text-wrap: wrap;
        }
      }
    }
    .search-add-new-account {
      display: flex;
      justify-content: space-between;
      .search-box {
        width: 322px;
      }
    }
    .account-type-cell {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 3px;
    }
    .dashed-tooltip-item {
      border-bottom: 1px dashed #cbd5e1;
      cursor: pointer;
    }
  }
}

#settings-new-account-dialog {
  width: 500px;
  .dialog-title {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
  }
  .description {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .select-title {
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    margin: 10px 0px;
    .required {
      color: rgba(227, 27, 12, 1);
    }
  }
  .MuiSelect-select .MuiRadio-root {
    display: none;
  }
  .error-msg {
    font-weight: 400;
    font-size: 14px;
    color: #e50035;
    padding: 5px;
  }
  .dialog-actions {
    gap: 10px;
    padding: 20px;
  }
}

.MuiDialogActions-root.unauth-popup-actions-box {
  padding: 20px;
}
.MuiDialogContent-root.unauth-popup-content-box {
  padding-top: 1px;
}
#dashed-tooltip-box {
  width: 170px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.search-box.company-accounts,
.search-box.users-accounts {
  width: 280px;
  .search-field label.MuiFormLabel-filled {
    transform: (11px, -9px) scale(0.75);
  }
  label#company-accounts-search-field-label,
  label#users-accounts-search-field-label {
    @include ar {
      right: 100px;
      top: 2px;
    }
  }
  .search-field label {
    @include ar {
      width: fit-content;
    }
  }
}

.authenticate-page-options-item {
  &.MuiMenuItem-root {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 400;
    img {
      margin-inline-end: 10px;
    }
  }
}

.accounts-action-dropdown {
  .MuiPaper-root {
    @include en {
      margin-top: 5px;
    }
    @include ar {
      margin-top: 45px;
    }
  }
}

.new-account-icons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.genesys-social-icon {
  height: 20px !important;
  margin-inline: -4px;
}
span.time-with-gmt-table{
  font-weight: 400;
  color: #475569;
  font-size: 12px;
}