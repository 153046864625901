.spa-main-container {
  padding: 40px 0px;
}

.spa-main-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 36px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(203, 213, 225, 1);
}

.spa-main-header-title.MuiTypography-root {
  color: #0c192a;
  font-size: 28px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.42px;
  margin-top: 12px;
}

.spa-main-header-action-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-end;
}

.spa-main-header-data-text.MuiTypography-root {
  color: rgba(100, 116, 139, 1);
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}
.spa-overview-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 30px;
}

.spa-overview-text.MuiTypography-root {
  color: rgba(164, 169, 175, 1);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.68px;
  text-transform: uppercase;
}

.spa-tabs {
  .MuiTab-root {
    border-bottom: 3px solid transparent;
    padding: 14px 12px;
    font-size: 16px;
    font-weight: 700;
    color: #64748b;
    margin: 36px 0px;
  }
  .Mui-selected {
    color: #233759;
    border-bottom: 3px solid #456eb2;
    font-size: 14px;
  }
}

.load-more-comments-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}

.missing-data-alert-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 20px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 100%
    ),
    #ed6c02;
  padding: 6px 16px;
  border-radius: 4px;
  border: 1px solid rgba(237, 108, 2, 0.08);
}

.missing-data-alert-description-container {
  display: flex;
  gap: 12px;
  align-items: flex-start;
}

.missing-data-title.MuiTypography-root {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
}
.missing-data-description.MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
}

.missing-data-icon {
  color: rgba(237, 108, 2, 1);
  width: 24px;
  height: 24px;
  margin-top: 7px;
}
.missing-data-close-icon {
  margin: 4px;
  color: #5e2b01;
  cursor: pointer;
}
