@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

@mixin en {
  html[lang="en"] & {
    @content;
  }
}

.details-side-bar-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  padding: 20px;
  .profile-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 0.8px solid #e2e8f0;
  }
  .profile-user-box {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .profile-side-bar-subname {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.8px;
      letter-spacing: 0.15px;
      color: #00000099;
    }
  }
  .profile-side-bar-name {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
  }
  .profile-side-bar-user-name {
    color: rgba(0, 0, 0, 0.38);
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0 0;
  }
  .link-copy {
    position: absolute;
    top: 20px;
    inset-inline-start: 20px;
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 0.7px solid rgba(0, 0, 0, 0.23);
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .Visit-btn {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.content-side-bar-heading {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 10px 0;
  color: rgba(0, 0, 0, 0.87);
}
.content-side-bar-heading.no-margin {
  margin-bottom: 0;
}
.profile-side-bar-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  .content-side-bar-lines {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .content-box {
    flex-grow: 1;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
  }
  .title-box {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
    svg {
      font-size: 16px;
      width: 20px;
    }
  }
  .value-box {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
  }
  .show-total {
    text-decoration: underline;
    color: #456eb2;
    font-weight: 600;
    cursor: pointer;
  }
  .content-wrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    .single-hint {
      border-radius: 16px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 3px 10px;
      .hint-color {
        width: 10px;
        height: 10px;
        border-radius: 2px;
        display: inline-block;
      }
      .hint-text {
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
.profile-social-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 20px;
  .social-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .profile-social-icon {
    width: 29px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    img {
      width: 16px;
      height: 12px;
    }
  }
  .facebook-icon-profile {
    color: #1877f2;
  }
  .twitter-icon-profile {
    color: #1da1f2;
  }
  .instagram-icon-profile {
    color: #e1306c;
  }
  .intercom-icon-profile {
    color: #0057ff;
  }
  .whatsapp-icon-profile {
    color: #25d366;
  }
  .social-text {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
  }
  .social-link {
    color: rgba(0, 0, 0, 0.6);
    font-size: 15px;
    cursor: pointer;
    display: none;
  }
  &:hover {
    background-color: #fafafa;
    .social-link {
      display: block;
    }
  }
}
.profile-side-bar-social {
  padding: 20px 0 10px 0;
  .content-side-bar-heading {
    padding: 0 20px;
  }
}
.profile-side-bar-cdp {
  margin: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .cdp-text {
    text-align: center;
  }
  .star-img {
    margin-bottom: 10px;
  }
  .cdp-heading {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 600;
    max-width: 172px;
    margin: 0 auto 20px;
  }
  .cdp-description {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
  .cdp-content {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 13px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .cdp-box {
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
      font-size: 15px;
      color: #456eb2;
    }
    .box-text {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      font-weight: 400;
      span {
        font-weight: 600;
      }
    }
  }
  .demo-btn {
    width: 100%;
    font-size: 14px;
  }
}
.cdp-snackbar-wrapper {
  .cdp-heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: block;
  }
  .cdp-description {
    font-size: 14px;
    line-height: 20px;
    margin-top: 5px;
    display: block;
    max-width: 282px;
  }
}
#copied-tooltip {
  .MuiTooltip-tooltip {
    background-color: #3b873e;
  }
}
.details-side-bar-content {
  max-height: calc(100vh - 185px);
  overflow: auto;
  &-empty {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-top: -50px;
    .details-side-bar-content-empty-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
      color: #00000099;
      width: 184.73px;
    }
  }
}
.profile-side-bar-loader {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-height: calc(100vh - 225px);
  overflow: auto;
  .profile-side-bar-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  .profile-side-bar-lines {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .MuiDivider-root.MuiDivider-vertical {
    background: rgba(0, 0, 0, 0.12);
    height: 1px;
  }
  .lines-wrap {
    display: flex;
    gap: 10px;
  }
  .MuiSkeleton-pulse.MuiSkeleton-text {
    border-radius: 15px;
  }
}
.profile-side-bar-content-empty {
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.38);
  margin: 0;
}
.social-content-empty {
  padding: 0 20px;
}

.engager-profile-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.engager-profile-box-wrapper {
  padding: 20px;
  border-bottom: 1px solid #e2e8f0;
}

.engager-profile-info-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

.engager-profile-actions-wrapper {
  display: flex;
  align-items: center;
}

.engager-profile-followers-engagement-title.MuiTypography-root {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;

  &.with-border {
    border-bottom: 1px dashed #c4c4c4;
  }
}

.engager-profile-followers-value.MuiTypography-root {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 400;
  padding: 5px 0px;
}

.engager-profile-engagements-value-wrapper.MuiTypography-root {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #456eb2;
  width: fit-content;
  padding: 5px 4px;
  cursor: pointer;
  border-radius: 2px;
  &:hover {
    background-color: rgba(230, 243, 252, 0.5);
  }
}

.engager-show-engagement-tooltip {
  width: 190px;
}

.engager-profile-actions.MuiTypography-root {
  color: #64748b;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.46px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  padding: 5px 4px;
  cursor: pointer;
  width: fit-content;
  &:hover {
    background-color: rgba(100, 116, 139, 0.1);
  }
  text-decoration-line: underline;
}

.copy-engager-profile-link-icon {
  color: #64748b;
  font-size: 12px;
  padding: 5px 4px;
  cursor: pointer;
  &:hover {
    background-color: #f2f6fa;
    border-radius: 2px;
  }
}

.engager-user-info-title.MuiTypography-root {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.35px;
  display: flex;
  align-items: center;
  gap: 6px;
  svg {
    color: rgba(0, 0, 0, 0.38);
    font-size: 14px;
  }
}
.engager-user-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.engager-user-info-item-list-label.MuiTypography-root {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 4px;
  svg {
    color: rgba(0, 0, 0, 0.38);
    font-size: 14px;
  }
}
.engager-user-info-item-list-value.MuiChip-root {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 400;
  margin-inline-end: 4px;
  margin-top: 4px;

  &.social-chip {
    color: #1877f2;
  }

  &.with-color {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 5px;
    @include en {
      &::before,
      ::after {
        content: " ";
        display: inline-block;
      }
    }
  }

  &.engager-field-phone-number {
    direction: ltr;
  }

  .hint-color {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    display: inline-block;
    @include ar {
      margin-inline-start: 6px;
    }
  }
}
.engager-user-info-accordion.MuiAccordion-root {
  box-shadow: none;
  border-bottom: 1px solid #e0e0e0;
  .MuiAccordionSummary-root:not(.Mui-expanded) {
    .engager-user-info-title {
      svg {
        display: none;
      }
    }
  }
  .MuiAccordionSummary-root {
    &:hover {
      background-color: #f2f6fa;
    }
  }
}
.engager-user-info-accordion-disabled {
  all: unset;
}

.engager-user-info-item-list-show-more-less-chip.MuiChip-root {
  color: rgba(69, 110, 178, 1);
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
  &:hover {
    background-color: #e2e8f0;
  }
}

.engager-user-empty-text.MuiTypography-root {
  color: rgba(0, 0, 0, 0.38);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.16px;
  line-height: 18px;
}

.engager-profile-social-icons {
  img {
    width: 16px;
    height: 12px;
  }
}

.engager-profile-facebook-icon-profile {
  color: #1877f2 !important;
}

.engager-profile-intercom-icon-profile {
  color: #0057ff !important;
}
.engager-profile-whatsapp-icon-profile {
  color: #25d366 !important;
}

.engager-user-info-item-list-text-field {
  .MuiInputBase-input {
    padding: 9.5px 14px;
    font-size: 12px;
    line-height: 18px;
  }
  .MuiFormHelperText-root {
    @include en {
      text-align: start;
    }
    @include ar {
      text-align: right;
    }
    margin-inline-end: 0px;
    font-size: 10px;
    letter-spacing: 0.16px;
  }

  &.edit-disabled {
    .MuiOutlinedInput-notchedOutline {
      border: 1px dashed #c4c4c4;
    }
  }

  &.engager-field-phone-number {
    direction: ltr;
  }
}
.engager-user-info-item-list-remove.MuiButtonBase-root {
  padding: 8px;
  font-size: 14px;
}

.engager-profile-form-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.engager-user-info-item-list-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.engager-profile-disabled-item-placeholder {
  width: 33px;
  height: 33px;
}

.engager-user-info-item-list-show-more-less {
  margin: 8px 0px;
}

.engager-social-media-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.edit-engager-profile-footer {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.engager-profile-divider.MuiDivider-root {
  margin: 8px 4px;
  color: #e2e8f0;
}

.engager-divider-placeholder {
  width: 9px;
}

.engager-edit-title-section {
  &.engager-field-enabled {
    color: #64748b !important;
    font-size: 12px;
    padding: 5px 4px;
    cursor: pointer;
    &:hover {
      background-color: #f2f6fa;
      border-radius: 2px;
    }
  }
  &.engager-field-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
    font-size: 12px;
    padding: 5px 4px;
    cursor: not-allowed;
  }
}

.engager-profile-add-new-item.luc-Button {
  width: calc(100% - 33px);
  height: calc(100% - 33px);
}

.engager-delete-item-icon {
  color: #e31b0c;
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #ffeeed;
    border-radius: 2px;
  }
}
