@import "~@fortawesome/fontawesome-pro/css/all.min.css";

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#phone-number-input-two-factor-auth {
  .separate-dial-code {
    width: 100%;
  }
  .react-tel-input {
    margin-inline-start: 90px;
    border: 1px solid #0000003b;
    width: calc(100% - 90px);
    padding-inline-start: 12px;

    &:focus {
      border-color: #456eb2;
    }

    &::placeholder {
      opacity: 30%;
    }

    &.user-phone-error {
      border-color: #f44336;
    }
  }

  .selected-flag {
    width: 75px;
    height: 100%;
    border: 1px solid #0000003b;
    border-radius: 4px;
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    padding-inline: 12px;

    &:focus {
      border-color: #27ace4;
    }

    .iti-flag {
      display: none;
    }

    .arrow {
      border: none;
      position: relative;
      top: 5px;
      &.down,
      &.up {
        transform: rotate(0);
        margin-top: 3px;
        -webkit-transform: rotate(0);
        &:after {
          font-size: 8px;
        }
      }
    }
  }

  .country-list {
    width: 260px;
    .country {
      align-items: center;
    }
  }

  .user-phone-error-msg {
    color: #f44336;
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-inline-start: 100px;
    @include ar {
      margin-inline-start: 10px;
    }
  }
}
