@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.survey-csat-main-widget {
  padding: 24px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background-color: #fff;
  .main-csat-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .agent-feedback-load-more-btn {
      display: flex;
      justify-content: center;
    }
  }
  .csat-responses {
    margin-top: 5px;
    margin-bottom: 20px;
    color: #000000de;
    font-size: 14px;
    font-weight: 400;
  }
  .csat-main-item {
    padding: 20px 18px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    .csat-item-header {
      display: flex;
      justify-content: space-between;
      .csat-header-profile {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        &.whatsapp {
          cursor: default;
        }
        .main-img-vervied {
          margin-top: 3px;
        }
        .main-acc-name {
          font-size: 16px;
          font-weight: 700;
          color: #000000de;
        }
        .main-img {
          position: relative;
          margin-inline-end: 5px;
          width: 34px;
          height: 34px;
          img {
            width: 33px;
            height: 33px;
            border: 1px solid #94a3b8;
            border-radius: 50%;
          }
          .main-icon {
            position: absolute;
            bottom: -4px;
            inset-inline-end: -4px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            img {
              border: none;
              width: 18px;
              height: 18px;
            }
          }
        }
      }
      .csat-header-inbox {
        display: flex;
        align-items: center;
        gap: 20px;
        .header-engag-id {
          display: flex;
          align-items: center;
          gap: 6px;
          color: #00000099;
          font-size: 14px;
          font-weight: 500;
          svg {
            cursor: pointer;
            font-size: 15px;
            color: #64748b;
          }
          .engag-id {
            color: #000000de;
          }
        }
      }
    }
    .csat-item-contant {
      display: flex;
      flex-direction: column;
      gap: 8px;
      border: 1px solid #e2e8f0;
      padding: 12px;
      border-radius: 3px;
      .item-contant-text {
        font-size: 14px;
        font-weight: 400;
        color: #0c192a;
        line-height: 23px;
      }
      .item-contant-footer {
        display: flex;
        gap: 20px;
        .contant-footer-item {
          display: flex;
          align-items: center;
          gap: 10px;
          color: #00000099;
          font-size: 14px;
          font-weight: 500;
          .footer-item-value {
            color: #000000de;
          }
        }
      }
    }
  }
}
