@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

@mixin falcon-env {
  html[env="falcon"] & {
    @content;
  }
}

#report-luci-select-container {
  background-color: #f8fafc;
  border: 1px solid #e8eaf3;
  border-radius: 7px;
  padding: 20px;
  .content-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    .luci-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      background-color: #fff;
      border-radius: 50%;
      padding: 10px;
      box-shadow: 1px 3px 7px 0px #00000010;
      img {
        @include falcon-env {
          width: 42px;
          height: 42px;
        }
      }
    }
    .content-text {
      padding: 5px 0px;
      .title {
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: 600;
        font-size: 16px;
      }
      .info {
        color: #00000060;
        font-weight: 400;
        margin: 5px 0px;
        span {
          color: #000;
        }
      }
      .tokens-container {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        font-weight: 500;
        margin-top: 5px;
        .tokens-num {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;
          span {
            width: 20px !important;
            height: 20px !important;
            text-align: center;
          }
        }
        .tokens-per-report {
          color: #00000060;
        }
      }
    }
  }
}
