@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.edit-tag-container {
  width: 0;
  height: 0;
}
.engagement-tag-container {
  .loading-box {
    display: flex;
    height: 111px;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    svg {
      color: rgba(0, 0, 0, 0.6);
      font-size: 20px;
      animation: spin 1s linear infinite;
    }
  }
  button.engagement-tags {
    color: #64748b;
    font-size: 13px;
    font-weight: 500;
    line-height: 26px;
    max-height: 36px;
    border-radius: 2px;
    text-transform: capitalize;
    min-width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    &:hover {
      background: #f1f5f9;
    }
    > span {
      margin: 0 !important;
    }
    div.engagement_tags-name {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 2px;
    }
    div.engagement_tags-name span {
      margin: 0;
      font-size: 13px;
      min-width: 9px;
    }
  }
  .apply-tag-container {
    padding: 10px 16px;
    margin-bottom: 5px;
    button.apply-tag-button {
      width: 100%;
    }
  }

  div.option-dropdown-tags-container {
    .search-input-tag {
      background: #fff;
      input {
        color: #a8a8a8;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        padding: 11px 16px;
      }
      .MuiInputAdornment-root {
        svg {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
      .MuiInputBase-root.MuiOutlinedInput-root {
        padding-inline-start: 16px;
      }
      .MuiOutlinedInput-notchedOutline {
        border: unset;
        border-bottom: 1px solid #e4e4e4;
      }
    }
  }
  .new-tag-btn-container {
    button {
      width: 100%;
      justify-content: flex-start;
      background: #fff;
      gap: 4px;
      &:hover {
        background-color: #e6f3fc;
      }
      span {
        padding-inline-start: 2px;
      }
    }
  }
  .menu-tag-container {
    max-height: 283px;
    overflow-y: auto;
    .menu-tag-divider {
      margin: 10px 16px;
    }
    .no-tags-found {
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      display: flex;
      height: 86px;
      padding: 6px 16px;
      align-items: center;
      gap: 10px;
      justify-content: flex-start;
    }
  }

  .options-container {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    height: 28px;
    &:hover {
      background-color: #f8fafc;
    }
    cursor: pointer;
    .MuiListItemText-root {
      span {
        margin: 0;
        overflow: hidden;
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        font-weight: 400;
        padding-inline-start: 4px;
        text-transform: capitalize;
      }
    }
    .MuiButtonBase-root.MuiCheckbox-root {
      &:hover,
      &:focus {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
    span.MuiButtonBase-root.MuiCheckbox-root {
      padding: 0;
      margin-inline-end: 10px;
      margin-inline-end: 5px;
    }
    svg.MuiSvgIcon-root {
      width: 22px;
      height: 22px;
      padding: 6.5px;
    }
  }
  .options-container-disabled {
    cursor: no-drop;
  }
  .color-tag {
    width: 10px;
    height: 10px;
    border-radius: 2px;
  }
  .color-picker-container {
    position: absolute;
    z-index: 20;
    top: 30px;
    left: 50px;
  }
  .tags-empty-case {
    padding: 11px 16px;

    > h4,
    > p {
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      font-style: normal;
      line-height: 150%;
    }
    > h4 {
      font-weight: 600;
    }
    > p {
      font-weight: 400;
    }
  }
  .tag-label-box {
    display: flex;
    align-items: center;
    padding: 0px 6px;
    border-radius: 4px;
  }
  .add-tag-bady {
    padding: 16px 16px;
    text-align: start;
    .add-tag-title {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      font-weight: 500;
      line-height: 12px;
      display: inline-block;
      margin-bottom: 12px;
    }
    input {
      height: 40px;
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      padding: 0 12px;
    }
    .MuiFormHelperText-root.Mui-error {
      color: #f44336;
      font-size: 12px;
      font-weight: 400;
      text-align: start;
    }
    > button {
      width: 100%;
    }
  }
}
#menu-tags-list {
  div.MuiPaper-root.MuiPaper-elevation {
    margin-top: 3px;
  }
  .MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
    width: 300px;
    min-width: 300px;
    max-height: 435px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    background: #fff;
    box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  .MuiList-root.MuiList-padding {
    padding: 0;
  }
}
#add-tag-table {
  margin-bottom: 10px;
}
#add-tag-table.Mui-disabled {
  cursor: no-drop;
  pointer-events: unset;
}
#tag-name-color-picker {
  .MuiList-root.MuiList-padding {
    padding: 0;
    margin-inline-start: -2px;
  }
  div.MuiPaper-root.MuiPaper-elevation {
    border-radius: 8px;
    box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.1);
  }
}
.tag-color-picker-container {
  display: flex;
  flex-direction: column;
  .tag-selected-color-box {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 20px;
    width: max-content;
    padding-inline-start: 4px;
    padding-inline-end: 6px;
    border-radius: 4px;
    > p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      color: #000000de;
    }
  }

  .tag-color-preview {
    width: 10px;
    height: 10px;
  }

  .tag-color-options {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 6px 10px;
    width: calc(300px - 32px);
    padding: 20px 0 16px;
  }
  .tag-color-border {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 28px;
    width: 28px;
    border: 1px solid transparent;
    cursor: pointer;
  }
  .add-tag-color-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 4px;
    width: 27px;
    button.add-tag-color-btn {
      max-height: 28.5px;
    }
    width: 100%;
  }
  .add-tag-color-box-selected {
    height: 27.5px;
    width: 27px;
    border-radius: 4px;
    background: rgba(69, 110, 178, 0.1);
    border-color: #456eb2;
    button.add-tag-color-btn {
      max-height: 28.5px;
    }
  }
  .add-tag-color-btn {
    min-width: fit-content;
    padding: 0 !important;
    max-height: 32px;
    width: 100%;

    svg {
      color: #456eb2;
      font-size: 20px;
    }
  }

  .tag-color-circle {
    width: 16.96px;
    height: 16.96px;
    cursor: pointer;
  }

  .teeet {
    display: flex;
  }
  .tag-create-btn {
    margin-top: 16px;
  }
}
#tooltip-tag-limit {
  > div {
    max-width: 111px;
    padding: 13px;
    height: 40px;
  }
}
#tooltip-tag-disabled {
  > div {
    max-width: 165px;
    padding: 13px;
    height: 55px;
  }
}
#custom_color {
  > div {
    padding: 8px 10px;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#tooltip-tag-limit,
#tooltip-tag-disabled {
  .MuiTooltip-tooltip {
    margin-top: 3px;
    margin-inline-end: -6px;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#tooltip-tag {
  .MuiTooltip-tooltip {
    margin-top: 5px;
  }
  > div {
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
