@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.keywords {
  &.MuiTypography-root {
    font-size: 14px;
    color: #0f172a;
    margin-top: 18px;
    &.keyword-dropdown-header {
      margin-bottom: 10px;
    }
  }
  @include ar {
    direction: rtl;
    text-align: right;
  }
}

.keywords-field {
  width: 100% !important;
  input {
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    height: 40px;
    padding: 0px 15px;
    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
      @include ar {
        text-align: right;
      }
    }
  }
}

.list {
  padding-top: 0px !important;
}

.selectedMonitor {
  display: flex;

  span {
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 4px;
  }
}

.keywords button {
  background-color: white !important;
  padding: 0px;
}

.drop-down-field {
  &.MuiFormControl-root {
    width: 100%;
    margin-top: 0px;
  }
  .MuiOutlinedInput-root {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    margin-right: 40px;
    .MuiSelect-select {
      padding: 12px;
    }
    @include ar {
      margin-left: 40px;
      margin-right: 0px;
    }
  }
  .MuiSvgIcon-root {
    @include ar {
      position: absolute;
      right: 93%;
    }
  }
}

#menu-.MuiModal-root.MuiPopover-root .MuiPaper-elevation {
  max-height: 200px;
  margin-top: 3px !important;
}

.keywordsSource {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 18.5px !important;
}

.chip-style {
  &.MuiButtonBase-root {
    color: #456eb2;
    border: 1px solid rgba(33, 150, 243, 0.5);
    background-color: white;
    margin: 0px 3px;
    margin-top: 5px;
    font-size: 13px;
    font-weight: 400;
    max-height: 30px;
    svg {
      color: rgba(33, 150, 243, 0.5);
      font-size: 18px;
      @include ar {
        left: 10px;
        position: relative;
        direction: rtl;
        right: unset;
      }
    }
  }
  &.MuiChip-label {
    color: #456eb2;
    direction: initial;
    font-size: 13px;
    font-weight: 400;
  }
}

.used-chip-style {
  &.MuiButtonBase-root {
    color: rgba(0, 0, 0, 0.87) !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    span {
      color: rgba(0, 0, 0, 0.87) !important;
    }
    svg {
      color: rgba(0, 0, 0, 0.23) !important;
    }
  }
}
.chip-style-crossed {
  color: #f44336 !important;
  border: 1px solid #f44336 !important;
  overflow: hidden;
  width: 94%;
  svg {
    color: #f44336 !important;
  }
  span {
    color: #f44336 !important;
    text-decoration: line-through;
  }
}
.chip-style-arabic {
  &.MuiChip-root {
    color: #456eb2;
    border: 1px solid rgba(33, 150, 243, 0.8);
    background-color: white !important;
    margin-right: 3px !important;
    margin-top: 12px !important;
  }
  svg {
    color: rgba(33, 150, 243, 0.8) !important;
    font-size: 17px !important;

    @include ar {
      left: 10px;
      position: relative;
    }
  }

  span {
    color: #456eb2;
    direction: rtl;
  }
}
.chip-style-crossed-arabic {
  color: #f44336 !important;
  border: 1px solid #f44336 !important;
  svg {
    color: #f44336 !important;
  }
  span {
    color: #f44336 !important;
    text-decoration: line-through;
    direction: rtl;
  }
}
.err-msg {
  color: #f44336;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 15px;
  @include ar {
    text-align: right;
  }
}

.note-limit-msg {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  margin-top: 5px;
  margin-left: 15px;
  @include ar {
    text-align: right;
  }
}

.keywords-counter {
  color: #3b873e;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 0px;
  pointer-events: visible;
  @include ar {
    padding: 0px 15px;
  }
}
.keywords-counterMsgWithError {
  margin-left: 300px;
  @include ar {
    margin-left: 5px;
  }
}

.copy-icon {
  color: #cbd5e1;
  position: relative;
  top: 5px;
  cursor: pointer;
  font-size: 20px !important;

  @include en {
    left: 7px;
  }

  &:hover {
    color: #64748b;
  }
}

.tooltipIcon {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
}

.copy-icons {
  color: #cbd5e1;
  position: relative;
  top: 5px;
  font-size: 20px !important;
  cursor: pointer;
  @include en {
    left: 7px;
  }

  @include ar {
    right: 7px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f9f9f9;
  box-shadow: inset 1px 0px 0px #dedede;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.nb_dropdown_style {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.nb_dropdown_style .MuiListItemText-root span {
  font-size: 14px !important;
  line-height: 150%;
  letter-spacing: 0.15px;
  @include ar {
    padding: 0px 10px;
  }
}

// Test Keywords Dialog
#testKeywords {
  .MuiPaper-root {
    // max-height: 406px !important;
    max-width: 630px !important;
  }

  .test-keyword-dialog {
    height: 406px;
  }

  .testkeywordDialogContent {
    width: 560px;
    min-height: 400px;
    padding: 0px 25px !important;
    &.keyword-popup-content {
      min-height: 250px;
    }
  }
  .testkeyword-dialog-content-with-msg {
    width: 560px;
    min-height: 250px;
    padding: 0px 25px !important;
  }

  .testkeywordDialogTextField {
    width: 552px;
    height: 111px;
  }
  .testkeywordDialogTestBtn {
    font-size: 14px;
    font-weight: 500;
  }

  .testkeywordDialogDivider {
    margin-top: 40px !important;
    width: 552px;
    border: 1px dashed #cbd5e1 !important;
  }

  .test-keyword-dialog-cancel-btn {
    &.MuiButton-root {
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      font-weight: 500;
      border: none;
    }
    &.keyword-popup-done-btn {
      background-color: #456eb2;
      color: #fff;
    }
  }
  .testkeywordsLoader {
    position: inherit;
    margin-bottom: -224px;
    .MuiBox-root {
      margin: 12% 25% 16% 25%;
      position: initial;
    }
  }
  .testkeywordsLoaderBox {
    z-index: 999999;
  }
  .dialogStatusLoading {
    opacity: 0.4;
  }
  .dialogStatusLoadingBtn {
    background-color: #456eb2;
    color: #fff;
    &.try-another-btn {
      color: #456eb2;
      background-color: #fff;
      margin: 0;
      &:hover {
        box-shadow: none;
      }
    }
  }
  .loadingMsg {
    opacity: 1 !important;
    color: #000000 !important;
    margin-top: -8px;
    margin-left: 0;
    font-size: 12px;
    font-weight: 400;
  }
}
html[lang="ar"] {
  .chip-style svg {
    margin-left: 0px !important;
    right: 0;
    left: unset;
  }

  .chip-style .MuiChip-label {
    padding-left: 8px !important;
  }
}

.source-style span {
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 16px;
    margin-top: 5px;
    // margin-left: -25px;
  }
}
.add-account-icon {
  @include ar {
    margin-left: 8px;
  }
}

.alert {
  .MuiAlert-icon {
    color: #f44336 !important;
  }
  .MuiAlert-message {
    color: #5b0000;
  }
  .MuiAlert-action {
    padding-top: 2px;
  }
  button svg {
    fill: #5b0000;
    width: 20px;
  }
}
.icon-link {
  padding-bottom: 3px;
  color: #9e9e9e;
  &:hover {
    color: #28aae1;
  }
}
// show more & show less button
.show-hidden-spam-keywords {
  &.MuiButtonBase-root {
    background: #e2e8f0;
    border-radius: 16px;
    font-size: 13px;
    font-weight: 400;
    border: none;
    margin-bottom: 5px;
  }
  .MuiChip-label {
    font-size: 13px;
    font-weight: 400;
    color: #64748b;
  }
}
.show-less-spam-keywords {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-decoration-line: underline;
  color: #696d72;
  margin: 0px 10px;
}
// show more - show less
.show-active-keywords {
  overflow: hidden;
}
.show-active-spam-keywords {
  overflow: hidden;
}
// show more - show less
.show-active-keywords-fb {
  height: 80px;
  overflow: hidden;
}
.show-more-less-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.spam-keywords-wrapper {
  @extend .show-more-less-wrapper;
  justify-content: flex-start;
  margin-top: 10px;
}
.tooltip-icon-keyword {
  color: #bdbdbd;
  font-size: 18px !important;
  margin: 0px 5px !important;
  position: relative;
}

.list-item-keyword {
  background: #ffffff;
  border: 1px solid #cbd5e1;
  border-radius: 4px 4px 0px 0px;
  &.MuiListItem-root {
    display: block;
  }
  .list-item-keyword-type,
  .list-item-text-type {
    pointer-events: none;
    .MuiTypography-root {
      pointer-events: none;
    }
  }
  .list-item-cliked {
    pointer-events: visible;
  }
  .test-keyword-box {
    pointer-events: visible;
  }
}
.keyword-num-name {
  border-bottom: 1px dashed rgba(100, 116, 139, 0.5);
}
.next-update-wrapper {
  color: #696d72;
  font-size: 13px;
  font-weight: 400;
  padding-top: 12px;
}
.arrow-icon-keyword {
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.monitor-keyword-type {
  width: auto;
  background-color: white;
  padding-top: 50px;
}
.data-source-keyword {
  padding: 0px 20px 20px;
  @include ar {
    text-align: right;
  }
}
.duplicated-articles-container {
  display: flex;
  flex-direction: column;
  .duplicated-articles-title,
  .duplicated-articles-description {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
  }
  .duplicated-articles-description {
    color: rgba(0, 0, 0, 0.6);
  }
  .duplicated-articles-switch {
    margin: 0px 5px;
  }
}
.news-blog-wrapper {
  &.MuiFormControl-root {
    width: 100%;
  }
  #demo-multiple-checkbox {
    &.MuiSelect-select {
      padding: 9px 14px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      @include ar {
        text-align: right;
      }
    }
  }
  .MuiSvgIcon-root {
    @include ar {
      position: absolute;
      right: 93%;
    }
  }
}
.nb-sources-wrapper {
  &.MuiFormControl-root {
    width: 80%;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    @include ar {
      font-family: "Dubai";
    }
  }
  .MuiFormControlLabel-root {
    @include ar {
      margin-right: 0px;
      margin-left: 15px;
    }
  }
  .create-mopnitor-radio-group {
    padding: 0px 10px;
    .ranks-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      @include en {
        padding: 0 0 0 10px;
      }
      @include ar {
        padding: 0 20px 0 0;
      }
      .arrow-icon {
        color: rgba(0, 0, 0, 0.38);
        @include en {
          transform: scaleX(1);
        }
        @include ar {
          transform: scaleX(-1);
        }
      }
    }
    .ranks-form-group {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      text-wrap: nowrap;
      gap: 3px;
      label {
        margin: 0;
      }
    }
  }
}
#rank-tooltip {
  max-width: 190px;
  font-size: 12px;
}
.nb-sources-label {
  margin-bottom: 15px;
  &.MuiFormLabel-root {
    color: #0f172a;
    font-size: 14px;
    font-weight: 400;
  }
}
.show-more-less-keywords {
  display: flex;
  align-items: flex-start;
}
.keyword-head-title {
  &.MuiTypography-root {
    margin-bottom: 10px;
    display: block;
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
  &.duplicated-switch-title {
    display: flex;
    align-items: center;
  }
}
.exclude-sources-title {
  margin-top: 10px;
}
.news-source-inputs {
  .MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
  }
  .MuiGrid-root {
    @include ar {
      text-align: right;
    }
  }
}
.dropdown-keyword-head {
  .optional-head {
    &.MuiBox-root {
      color: #80868c;
      padding: 0px 5px;
      font-size: 13px;
    }
  }
}
.lang-country-hint-keyword {
  &.MuiTypography-root {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 500;
    background: #f8fafc;
    border: 1px solid #f1f5f9;
    border-radius: 4px;
    display: block;
    padding: 10px;
    @include ar {
      text-align: right;
    }
  }
  .add-condition-keyword {
    font-weight: 400;
  }
}

.container-check-list {
  width: "100%";
  background-color: "background.paper";
}
.grid-keywords {
  padding-top: 0px;
}

.keywords-field {
  padding-right: 0 !important;
  .add-btn-with-plus {
    display: none;
    line-height: 2 !important;
  }
  &.Mui-focused {
    .add-btn-with-plus {
      display: block;
    }
  }
}

.title-search-tooltip {
  white-space: pre-line !important;
}

.copy-icon-nb {
  top: 7px !important;
}
.monitor-hashtags-limit-wrapper {
  padding-inline-start: 24px;
  margin-top: 16px;
  flex-grow: 1;
}

.keyword-box-input-fields {
  .keyword-error-msg-form {
    color: #f44336;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-top: 5px;
    padding-inline-start: 12px;
    ul {
      list-style-type: disc;
    }
    @include ar {
      text-align: right;
    }
  }
  .required {
    color: #e31b0c;
    margin-inline-start: 2px;
  }
}
.keyword-switch-box-form {
  margin-block: 28px;
  padding-inline-start: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
  .keyword-switch-box-label {
    font-weight: 400;

    .keyword-switch-label {
      font-size: 14px;
      line-height: 20.02px;
      color: #000000de;
    }
    .keyword-switch-description-label {
      font-size: 12px;
      line-height: 17.16px;
      color: #80868c;
    }
  }
}

.news-sources-input-options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 6px 6px 35px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.news-sources-input-options-paper.MuiPaper-root {
  margin-top: -4px;
  box-shadow: 6px 6px 35px 0px rgba(0, 0, 0, 0.06);
}

.news-relevant-sources-title.MuiTypography-root {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 10px;
}

.news-relevant-sources-loading-container {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.news-relevant-sources-loading-title.MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  text-decoration-skip-ink: none;
  color: rgba(0, 0, 0, 0.6);
}

.no-results-text.MuiTypography-root {
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
}

.submit-request-link.MuiTypography-root {
  font-size: 14px;
  line-height: 24px;
  color: rgba(69, 110, 178, 1);
  text-decoration: underline;
  cursor: pointer;
}

.news-sources-input-chips-container {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.news-sources-search-input {
  .MuiFormHelperText-root.Mui-error {
    text-align: start;
  }
}

.news-sources-input-chip {
  &.MuiButtonBase-root {
    svg {
      @include ar {
        left: 10px;
        position: relative;
        direction: rtl;
        right: unset;
      }
    }
  }
}

.show-all-sources-chip.MuiChip-root {
  background-color: rgba(69, 110, 178, 0.15);
  color: rgba(69, 110, 178, 1);
  border: 1px solid #456eb2;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  border: none;
  cursor: pointer;
}
.news-sources-input-actions-container {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 3px;
}

.news-sources-input-actions-icon.MuiSvgIcon-root {
  color: rgba(148, 163, 184, 1);
  font-size: 16px;
}

.view-all-news-sources-input-chips-container {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 364px;
  overflow-y: auto;
}

.view-all-news-sources-input-chips-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px 24px 24px;
}

.request-source-verification-modal-body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.request-source-verification-modal-body-text.MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  color: rgba(0, 0, 0, 0.6);
}
.request-source-text-area-label.MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 10px;
}

.request-source-verification-text-area {
  // change the placeholder color of MUI text field
  & .MuiInputBase-root.MuiOutlinedInput-root ::placeholder {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.15px;
  }
}

.request-verification-modal-added-classes {
  margin: 0px 14px 14px;
}

.news-sources-search-container {
  width: 100%;
}

.sources-option-chip.MuiChip-root {
  border: 1px solid rgba(203, 213, 225, 1);
  color: rgba(0, 0, 0, 0.87);
}
